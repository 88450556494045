import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

import { readBed } from './bedreader';
import { GenomicRange } from '../../types';

export type FileMergerProps = {
    files: FileList | File[];
    onComplete: (regions: GenomicRange[]) => void;
};

function mergeRegions(regions: GenomicRange[]): GenomicRange[] {
    if (regions.length <= 1) return regions;
    regions = regions
        .slice()
        .sort((a: GenomicRange, b: GenomicRange): number =>
            a.chromosome! < b.chromosome! ? -1 : a.chromosome! > b.chromosome! ? 1 : a.start! - b.start!
        );
    const results: GenomicRange[] = [regions[0]];
    for (let i = 1; i < regions.length; ++i) {
        if (
            regions[i].chromosome! === results[results.length - 1].chromosome! &&
            regions[i].start! <= results[results.length - 1].end!
        ) {
            if (regions[i].end! > results[results.length - 1].end!) results[results.length - 1].end = regions[i].end;
        } else results.push(regions[i]);
    }
    return results;
}

type FileMergerState = {
    regions: GenomicRange[];
    index: number;
};

const FileMerger: React.FC<FileMergerProps> = ({ files, onComplete }) => {
    const [state, setState] = useState<FileMergerState>({
        regions: [],
        index: 0,
    });

    const nextFile = (newRegions: GenomicRange[]): void => {
        setState({
            regions: state.regions.slice().concat(newRegions),
            index: state.index + 1,
        });
    };

    const mergeFiles = () => {
        if (files === null || files === undefined) return;
        if (state.index === files.length) {
            onComplete(mergeRegions(state.regions));
            setState({ regions: state.regions, index: -1 });
        } else if (state.index > -1) readBed(files[state.index], nextFile, () => nextFile([]));
    };
    mergeFiles();

    return files && files.length ? (
        <Modal>
            <Modal.Header>Merging files....</Modal.Header>
            <Modal.Content>{files.length - state.index} files left</Modal.Content>
        </Modal>
    ) : null;
};
export default FileMerger;
