import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card, Container, Divider, Grid, Header, Icon, List, Segment } from 'semantic-ui-react';
import PeakModelPage from './PeakModels';
import { useRouteMatch } from 'react-router';
import PeakMotifModelPage from './PeakMotifModels';

const PartitionedLDRegressionPage: React.FC = () => {
    
    const [ page, setPage ] = useState(-1);
    const match = useRouteMatch<{ model?: string }>(`/partitioned-ldr/:model?`);

    return match?.params.model === "peak-models" ? (
        <PeakModelPage />
    ) : match?.params.model === "peak-motif-models" ? (
        <PeakMotifModelPage />
    ) : (
        <Container style={{ width: "90%", marginTop: "7em" }}>
            <Header as="h1" style={{ marginBottom: "0.1em" }}>Partitioned LD Score Regression</Header>
            <Header as="h3" style={{ marginTop: "0em" }}>Quantify heritability enrichment in TF peaks and motif sites</Header>
            <Divider />
            <Accordion>
                <Accordion.Title onClick={() => setPage(0)}>
                    <Header as="h2">Getting Started {page !== 0 && <Icon name={page === 0 ? "chevron right" : "chevron down"} style={{ fontSize: "0.7em", marginTop: "-0.9em" }} /> }</Header>
                    Quantifying heritability enrichment takes ~5 minutes on a standard laptop. We recommend running this workflow using our provided Docker image.
                    Click for detailed instructions.
                </Accordion.Title>
                <Accordion.Content active={page === 0}>
                    <List ordered>
                        <List.Item><a href="https://docs.docker.com/get-docker/" target="_blank" rel="noopenner noreferrer">Install Docker</a></List.Item>
                        <List.Item>Run the following command to partition heritability for motif sites in ChIP-seq peaks from seven ENCODE cell lines:</List.Item>
                    </List>
                    <Segment style={{ fontSize: "0.8em" }}>
                        <pre>
                            docker run \<br />
                            &nbsp;&nbsp;--volume /path/to/inputs:/input \<br />
                            &nbsp;&nbsp;ghcr.io/weng-lab/ldr/ldr:latest \<br />
                            &nbsp;&nbsp;python3 -m ldr.h2 \<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;--ld-scores http://gcp.wenglab.org/ldr-models/seven-cell-type-motifs.tar.gz \<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;--ld-prefix annotations \<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;--summary-statistics /input/summary-stats.txt &gt; partitioned-heritability.txt
                        </pre>
                    </Segment><br />
                    <p>
                        To quantify heritability for a different subset of peaks or motif sites, simply sub a different URL for the ld-scores parameter.
                        You can find URLs for each model in the <strong>View Models</strong> section below.
                    </p>
                    <p>
                        Output will be located at <pre>/path/to/outputs/partitioned-heritability.txt</pre> when the command finishes.
                    </p>
                </Accordion.Content>
                <Divider />
                <Accordion.Title onClick={() => setPage(1)}>
                    <Header as="h2">View and Download Models {page !== 1 && <Icon name={page === 1 ? "chevron right" : "chevron down"} style={{ fontSize: "0.7em", marginTop: "-0.7em" }} /> }</Header>
                    { page !== 1 && "Click to view details on precompiled models and download them." }
                </Accordion.Title>
                <Accordion.Content active={page === 1}>
                    <Grid style={{ marginTop: '1em' }}>
                        <Grid.Row>
                            <Grid.Column width={1} />
                            <Grid.Column width={15}><Header as="h3">Select an annotation to quantify heritability:</Header></Grid.Column>
                        </Grid.Row>
                        <Grid.Column width={1} />
                        <Grid.Column width={4}>
                            <Card style={{ width: "100%" }}>
                                <Card.Content><Card.Header><Link to="peak-models">TF ChIP-seq Peaks</Link></Card.Header></Card.Content>
                                <Card.Content>
                                    Quantify heritability enrichment within TF peaks identified from ChIP-seq experiments
                                    in one of five well-profiled ENCODE cell lines.
                                </Card.Content>
                                <Card.Content extra>
                                    <Link to="peak-models">View Models (5)</Link>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Card style={{ width: "100%" }}>
                                <Card.Content><Card.Header><Link to="peak-motif-models">Motif Sites in TF ChIP-seq Peaks</Link></Card.Header></Card.Content>
                                <Card.Content>
                                    Quantify heritability enrichment within TF motif sites identified in peaks from ChIP-seq experiments
                                    in one of five well-profiled ENCODE cell lines.
                                </Card.Content>
                                <Card.Content extra>
                                    <Link to="peak-motif-models">View Models (2)</Link>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid>
                </Accordion.Content>
            </Accordion>
        </Container>
    );

};
export default PartitionedLDRegressionPage;
