import gql from 'graphql-tag';

export const MOTIF_SEARCH_QUERY = gql`
    query MemeMotifSearch($assembly: String!, $pwms: [[[Float!]]]!, $limit: Int!, $offset: Int!) {
        meme_motif_search(pwms: $pwms, assembly: $assembly, limit: $limit, offset: $offset) {
            results {
                motif {
                    pwm
                    peaks_accession
                    tomtom_matches {
                        target_id
                        jaspar_name
                        e_value
                    }
                }
                distance
                offset
                reverseComplement
            }
            total
        }
    }
`;

export const DATASET_QUERY = gql`
    query Experiment($peak_accession: String) {
        peakDataset(replicated_peak_accession: $peak_accession) {
            datasets {
                accession
                lab {
                    friendly_name
                }
                target
                biosample
            }
        }
    }
`;
