import React, { useCallback } from 'react';

import SearchRow from './searchrow';
import { factorAutocompleteQuery } from '../queries';
import { formatFactorName } from '../../../utilities/misc';
import { AutocompleteResult, TFSearchProps } from './types';
import { AutocompleteCountQueryResult } from '../types';

const SEQUENCE_SPECIFIC = new Set([ "Known motif", "Inferred motif" ]);

function factorFormatter(species: string, tfA?: any): (result: AutocompleteCountQueryResult) => AutocompleteResult {
    return (result: AutocompleteCountQueryResult): AutocompleteResult => ({
        title: formatFactorName(result.name!, species),
        description:
            (!tfA || !tfA.get(result.name!) ? "" : (tfA.get(result.name!)["TF assessment"] as unknown as string).includes("Likely") ? "Likely sequence-specific TF - " : (SEQUENCE_SPECIFIC.has(tfA.get(result.name!)["TF assessment"]) ? "Sequence-specific TF - " : "Non-sequence-specific factor - ")) +
            `${result.datasets.counts.total} experiment${result.datasets.counts.total !== 1 ? 's' : ''}, ` +
            `${result.datasets.counts.biosamples} cell type${result.datasets.counts.biosamples !== 1 ? 's' : ''}`,
    });
}

const TFSearch: React.FC<TFSearchProps> = props => {
    const f = useCallback(factorFormatter(props.species, props.tfA), [ props.tfA ]);
    return (
        <SearchRow
            {...props}
            label="transcription factors"
            placeholder="Enter a gene name or ID"
            urlBase="tf"
            query={factorAutocompleteQuery}
            resultFormatter={f}
        />
    );
};
export default TFSearch;
