import React, { useContext } from 'react';
import { ApiContext } from 'apicontext';
import { useQuery } from '@apollo/client';
import { MEMEOCCU_QUERY } from './queries';
import { MemeMotifOccurrenceResponse, MemeMotifOccurrence } from '../types';
import { Message } from 'semantic-ui-react';
import { DataTableColumn, DataTable } from 'ts-ztable';

const MEME_OCCU_COLUMNS = (): DataTableColumn<MemeMotifOccurrence>[] => {
    const cols: DataTableColumn<MemeMotifOccurrence>[] = [
        {
            header: 'Chromosome',
            value: row => row.genomic_region.chromosome,
        },
        {
            header: 'Start',
            value: row => row.genomic_region.start,
            render: row => row.genomic_region.start.toLocaleString(),
        },
        {
            header: 'End',
            value: row => row.genomic_region.end,
            render: row => row.genomic_region.end.toLocaleString(),
        },
        {
            header: 'Peaks Accession',
            value: row => row.peaks_accession,
        },
        {
            header: 'Strand',
            value: row => row.strand,
        },
        {
            header: 'Q Value',
            value: row => row.q_value,
        },
        {
            header: 'Consensus Regex',
            value: row => row.consensus_regex,
        },
    ];
    return cols;
};

export type MemeOccuDataTableProps = {
    regions: { chromosome: string; start: number; end: number }[];
    peaks_accession: string;
    consensus_regex: string;
};

const MemeOccuDataTable: React.FC<MemeOccuDataTableProps> = ({ regions, peaks_accession, consensus_regex }) => {
    const client = useContext(ApiContext).client;
    const { data: memeOccuData, loading, error } = useQuery<MemeMotifOccurrenceResponse>(MEMEOCCU_QUERY, {
        client: client,
        variables: {
            peaks_accession: peaks_accession,
            range: regions,
            consensus_regex: consensus_regex,
        },
    });

    return (
        <>
            {loading ? <h1>Loading...</h1> : null}
            {error ? <h1 style={{ color: 'red' }}>Error! Please refresh.</h1> : null}
            {memeOccuData &&
                (memeOccuData.meme_occurrences.length === 1000 ? (
                    <Message warning>
                        Your query returned more than 1,000 matching moif sites. For performance, we only show a
                        preview of 1,000 on this page. Use the buttons below to download the full set.
                    </Message>
                ) : (
                    <h3>
                        {memeOccuData.meme_occurrences.length.toLocaleString()} {peaks_accession} ChIP-seq peak motif sites
                        matched your input:
                    </h3>
                ))}
            {memeOccuData && memeOccuData.meme_occurrences && (
                <DataTable
                    searchable
                    columns={MEME_OCCU_COLUMNS()}
                    rows={memeOccuData.meme_occurrences}
                    itemsPerPage={8}
                    sortColumn={1}
                    sortDescending
                    emptyText="No motif sites found."
                    noOfDefaultColumns={10}
                />
            )}
        </>
    );
};
export default MemeOccuDataTable;
