import gql from 'graphql-tag';

export const datasetCountQuery = gql`
    query Datasets($assembly: String) {
        counts: peakDataset(
            processed_assembly: $assembly
            replicated_peaks: true
            exclude_investigatedas: ["recombinant protein"]
            include_investigatedas: [
                "cofactor"
                "chromatin remodeler"
                "RNA polymerase complex"
                "DNA replication"
                "DNA repair"
                "cohesin"
                "transcription factor"
            ]
        ) {
            counts {
                total
                targets
                biosamples
            }
        }
    }
`;

export const biosampleAutocompleteQuery = gql`
    query Datasets($q: String, $assembly: String, $limit: Int) {
        counts: biosamples(
            biosample_prefix: $q
            processed_assembly: $assembly
            replicated_peaks: true
            exclude_investigatedas: ["recombinant protein"]
            include_investigatedas: [
                "cofactor"
                "chromatin remodeler"
                "RNA polymerase complex"
                "DNA replication"
                "DNA repair"
                "cohesin"
                "transcription factor"
            ]
            limit: $limit
        ) {
            name
            datasets {
                counts {
                    total
                    targets
                }
            }
        }
    }
`;

export const factorAutocompleteQuery = gql`
    query Datasets($q: String, $assembly: String, $limit: Int) {
        counts: targets(
            target_prefix: $q
            processed_assembly: $assembly
            replicated_peaks: true
            exclude_investigatedas: ["recombinant protein"]
            include_investigatedas: [
                "cofactor"
                "chromatin remodeler"
                "RNA polymerase complex"
                "DNA replication"
                "DNA repair"
                "cohesin"
                "transcription factor"
            ]
            limit: $limit
        ) {
            name
            datasets {
                counts {
                    total
                    biosamples
                }
            }
        }
    }
`;
