import React from 'react';
import { GenomicRange } from 'components/types';
import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { ApiContext } from 'apicontext';
import { DEEP_LEARNED_MOTIF_OCCURRENCES_QUERY } from './queries';
import { DeepLearnedMotifOccurrencesResponse, DeepLearnedMotifOccurrence } from './types';
import { DataTable, DataTableColumn } from 'ts-ztable';
export type DlMotifOccurrencesDataTableProps = {
    factor: string;
    regions: GenomicRange[];
};

const DL_MOTIF_OCCU_COLUMNS = (): DataTableColumn<DeepLearnedMotifOccurrence>[] => {
    const cols: DataTableColumn<DeepLearnedMotifOccurrence>[] = [
        {
            header: 'Chromosome',
            value: row => row.genomic_region.chromosome,
        },
        {
            header: 'Start',
            value: row => row.genomic_region.start,
            render: row => row.genomic_region.start.toLocaleString(),
        },
        {
            header: 'End',
            value: row => row.genomic_region.end,
            render: row => row.genomic_region.end.toLocaleString(),
        },
        {
            header: 'Strand',
            value: row => row.strand,
        },
        {
            header: 'Score',
            value: row => row.score,
        },
        {
            header: 'Name',
            value: row => row.name,
        },
        {
            header: 'Rdhs',
            value: row => row.rdhs,
        },
        {
            header: 'Annotation',
            value: row => row.annotation.replaceAll('_', ' '),
        },
        {
            header: 'Class of transposable element',
            value: row => row.class_of_transposable_element,
        },
    ];

    return cols;
};

const DeepLearnedMotifOccurrencesDataTable: React.FC<DlMotifOccurrencesDataTableProps> = ({ factor, regions }) => {
    const client = useContext(ApiContext).client;
    const { data: dlmotifsOccuData, loading, error } = useQuery<DeepLearnedMotifOccurrencesResponse>(
        DEEP_LEARNED_MOTIF_OCCURRENCES_QUERY,
        {
            client: client,
            variables: {
                tf: factor,
                genomic_region: regions.map(r => {
                    return { chromosome: r.chromosome, start: r.start!!, end: r.end!! };
                }),
            },
        }
    );

    return (
        <>
            {loading ? <h1>Loading...</h1> : null}
            {error ? <h1 style={{ color: 'red' }}>Error! Please refresh.</h1> : null}

            {dlmotifsOccuData && (
                <DataTable
                    searchable
                    columns={DL_MOTIF_OCCU_COLUMNS()}
                    rows={dlmotifsOccuData.deep_learned_motif_peak_occurrences}
                    itemsPerPage={8}
                    sortColumn={1}
                    sortDescending
                    emptyText="No deep learned motif occurrences found."
                    noOfDefaultColumns={10}
                />
            )}
        </>
    );
};
export default DeepLearnedMotifOccurrencesDataTable;
