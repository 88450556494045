import React from 'react';
import { Loader } from 'semantic-ui-react';

import { useAggregateMetadata } from './hooks';
import { HistoneAggregatePageProps } from './types';
import { histoneBiosamplePartitions } from './util';

import PlotPage from './plot';

const HistoneAggregatePage: React.FC<HistoneAggregatePageProps> = ({ assembly, target }) => {
    const { loading, data } = useAggregateMetadata(assembly, target);
    if (loading || !data)
        return (
            <Loader style={{ marginTop: '10em' }} active>
                Loading...
            </Loader>
        );
    return <PlotPage metadata={histoneBiosamplePartitions(data)} type="histone" />;
};
export default HistoneAggregatePage;
