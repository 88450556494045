/**
 * GenomeExplorer.tsx: genome explorer for Factorbook biology 2023 paper
 */

import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GraphQLImportanceTrack } from 'bpnet-ui';
import { RawLogo, DNAAlphabet } from "logojs-react";

import CytobandView from 'components/tf/motifenrichment/browser/Cytobands';
import { EmptyTrack, GenomeBrowser, GraphQLTranscriptTrack, UCSCControls, WrappedDenseBigBed, WrappedFullBigWig, WrappedPackTranscriptTrack, WrappedRulerTrack, WrappedSquishTranscriptTrack, WrappedTrack } from 'umms-gb';
import SearchBox from 'components/tf/motifenrichment/browser/searchbox';
import { parseDomain } from 'components/tf/motifenrichment/browser/browserpage';
import { useBigData, useImportanceSelectionEnd, TitledImportanceTrackHighlight, useImportantRegionClick } from './hooks';
import { TRACK_SUBSETS } from './tracks';
import { ShadedWrappedImportanceTrack } from './ShadedWrappedImportanceTrack';

// const POPULATIONS = [{ text: 'European', population: 'EUROPEAN' }];

type GenomeExplorerProps = {
    domain: {
        chromosome: string;
        start: number;
        end: number;
    };
    client: ApolloClient<NormalizedCacheObject>;
    width: number;
};

type TOMTOMMatch = {
    e_value: number;
    jaspar_name?: string | null;
    target_id: string;
};

const IMPORTANCE_SCORE_URLS = [
    "gs://gcp.wenglab.org/projects/chrombpnet/all-regions.neg.bb",
    "gs://gcp.wenglab.org/GRCh38-cCREs.bigBed",
    "gs://gcp.wenglab.org/dnase.hg38.sum.bigWig",
    "gs://gcp.wenglab.org/projects/chrombpnet/HSC-ATAC/imputed-associations.bigBed",
    "gs://gcp.wenglab.org/projects/chrombpnet/HSC-ATAC/all-true-associations.colored.bigBed",
    "gs://gcp.wenglab.org/projects/MAFLD/HSC-ATAC/consensus.pos-stringency-1.bb",
    "gs://gcp.wenglab.org/projects/MAFLD/associated-SNPs/Miao-all.significant.bigBed",
    "gs://gcp.wenglab.org/projects/MAFLD/associated-SNPs/all-EMR-associated.significant.bigBed",
    "gs://gcp.wenglab.org/projects/MAFLD/associated-SNPs/biopsy-or-imaging-confirmed.significant.bigBed"
];

export const reverseComplement = (ppm: number[][]): number[][] =>
  ppm && ppm[0] && ppm[0].length === 4
    ? ppm.map((inner) => inner.slice().reverse()).reverse()
    : ppm
        .map((entry) => [
          entry[3],
          entry[2],
          entry[1],
          entry[0],
          entry[5],
          entry[4],
        ])
        .reverse();

export const logLikelihood = (backgroundFrequencies: any) => (r: any) => {
    let sum = 0.0;
    r.map(
        (x: any, i: any) =>
        (sum +=
            x === 0 ? 0 : x * Math.log2(x / (backgroundFrequencies[i] || 0.01)))
    );
    return r.map((x: any) => {
        const v = x * sum;
        return v <= 0.0 ? 0.0 : v / 2;
    });
};

function best(x: TOMTOMMatch[]): TOMTOMMatch {
    console.log(x);
    return x.sort((a, b) => a.e_value - b.e_value)[0];
}

const GenomeExplorer: React.FC<GenomeExplorerProps>
    = ({ domain, client, width }) => {

        const [ currentDomain, setCurrentDomain ] = React.useState(domain);
        const onDomainChanged = React.useCallback(newDomain => {
            console.log(newDomain);
            setCurrentDomain({ chromosome: currentDomain.chromosome, ...newDomain });
            setHighlights([]);
            setSelectedHighlight(null);
        }, [ currentDomain ]);
        const profileImportanceData = useBigData(IMPORTANCE_SCORE_URLS, currentDomain, width - 150);
        const expandedImportantRegions = React.useMemo( () => (
            profileImportanceData && profileImportanceData[0]
                ? profileImportanceData[0].data.map(x => ({ chromosome: x.chromosome, start: x.start - 1, end: x.end + 1 }))
                : null
        ), [ profileImportanceData ]);

        // const [ anchor, setAnchor ] = React.useState<string | null>(null);

        const [ highlights, setHighlights ] = React.useState<TitledImportanceTrackHighlight[]>([]);
        const [ selectedHighlight, setSelectedHighlight ] = React.useState<number | null>(null);
        const onImportanceTrackSelectionEnd = useImportanceSelectionEnd(highlights, setHighlights);
        const onImportantRegionClick = useImportantRegionClick(currentDomain, onImportanceTrackSelectionEnd);
        const coordinateMap = React.useCallback(
            (coordinate: number) => (coordinate * (width - 150)) / (currentDomain.end - currentDomain.start) + 150,
            [currentDomain]
        );
        const reverseCoordinateMap = React.useCallback(
            (coordinate: number) => Math.round(coordinate / (width - 150) * (currentDomain.end - currentDomain.start) + currentDomain.start),
            [currentDomain]
        );

        return (
            <Container style={{ width: "90%", marginTop: "5em" }}>
                <Grid columns={1} centered>
                    <Grid.Row textAlign="center">
                        <Grid.Column width={16} textAlign='center'>
                            <CytobandView
                                client={client}
                                assembly="GRCh38"
                                chromosome={currentDomain.chromosome}
                                position={domain}
                                innerWidth={width}
                                height={30}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row textAlign="center">
                        <Grid.Column width={16} textAlign='center'>
                            <UCSCControls
                                onDomainChanged={onDomainChanged}
                                domain={currentDomain}
                                withInput={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Column width={10}>
                        <Grid.Row textAlign="center">
                            <SearchBox
                                onSearchSubmit={(domain: string, name?: string, isSnp?: boolean) => {
                                    let d: any = parseDomain(domain);
                                    if (isSnp)
                                        d = {
                                            ...d,
                                            start: d.start - 10000,
                                            end: d.end + 10000,
                                        };
                                    onDomainChanged(d);
                                }}
                                assembly="GRCh38"
                            />
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Row textAlign="center">
                        <Grid.Column textAlign={'center'}>
                            <GenomeBrowser
                                onDomainChanged={onDomainChanged}
                                domain={currentDomain}
                                innerWidth={width}
                                width="100%"
                            >
                                <WrappedRulerTrack
                                    width={width}
                                    height={60}
                                    title="scale"
                                    id="ruler"
                                    domain={currentDomain}
                                    titleSize={12}
                                    trackMargin={12}
                                />
                                <GraphQLTranscriptTrack
                                    id='transcript'
                                    domain={currentDomain}
                                    transform='translate(0,0)'
                                    assembly="GRCh38"
                                    endpoint='https://ga.staging.wenglab.org/graphql'
                                >
                                    { currentDomain.end - currentDomain.start <= 100000 ? (
                                        <WrappedSquishTranscriptTrack
                                            color="#8b0000"
                                            titleSize={15}
                                            trackMargin={12}
                                            title='GENCODE v29 transcripts'
                                            id="transcript"
                                            rowHeight={14}
                                            width={width}
                                            domain={currentDomain}
                                        />
                                    ) : (
                                        <WrappedPackTranscriptTrack
                                            color="#8b0000"
                                            titleSize={15}
                                            trackMargin={12}
                                            title='GENCODE v29 transcripts'
                                            id="transcript"
                                            rowHeight={14}
                                            width={width}
                                            domain={currentDomain}
                                        />
                                    )}
                                </GraphQLTranscriptTrack>
                                { profileImportanceData && profileImportanceData[0] && (
                                    <WrappedDenseBigBed
                                        title='V4 cCREs'
                                        id='cCREs'
                                        domain={currentDomain}
                                        width={width}
                                        height={40}
                                        data={profileImportanceData[1].data}
                                    />
                                )}
                                { profileImportanceData && profileImportanceData[0] && (
                                    <WrappedFullBigWig
                                        title='ENCODE aggregated DNase-seq'
                                        id='DNase'
                                        domain={currentDomain}
                                        width={width}
                                        height={100}
                                        color="#06DA93"
                                        titleSize={15}
                                        data={profileImportanceData[2].data}
                                    />
                                )}
                                { profileImportanceData && profileImportanceData[0] && (
                                    <WrappedDenseBigBed
                                        title='Important Regions Across All ChromBPNet Experiments'
                                        id='important-regions'
                                        domain={currentDomain}
                                        width={width}
                                        height={40}
                                        data={expandedImportantRegions}
                                        onClick={item => onImportantRegionClick([ reverseCoordinateMap(item.start), reverseCoordinateMap(item.end) ])}
                                    />
                                )}
                                { currentDomain.end - currentDomain.start < 30000 && (
                                    <WrappedTrack
                                        width={width}
                                        id="importance-scores"
                                        height={80}
                                    >
                                        <GraphQLImportanceTrack
                                            width={width - 150}
                                            height={80}
                                            endpoint="https://ga.staging.wenglab.org"
                                            signalURL="gs://gcp.wenglab.org/projects/chrombpnet/importance.scaled.bigWig"
                                            sequenceURL="gs://gcp.wenglab.org/hg38.2bit"
                                            coordinates={currentDomain}
                                            key={`${currentDomain.chromosome}:${currentDomain.start}-${currentDomain.end}`}
                                            allowSelection
                                            onSelectionEnd={onImportanceTrackSelectionEnd}
                                        />
                                    </WrappedTrack>
                                )}
                                { currentDomain.end - currentDomain.start < 30000 && (
                                    <WrappedTrack
                                        width={width}
                                        id="conservation-title"
                                        height={40}
                                    >
                                        <EmptyTrack
                                            id="conservation-title-empty"
                                            transform=""
                                            width={width - 150}
                                            height={40}
                                            text="Mammalian Phylo-P 241-way"
                                        />
                                    </WrappedTrack>
                                )}
                                { currentDomain.end - currentDomain.start < 30000 && (
                                    <WrappedTrack
                                        width={width}
                                        id="conservation"
                                        height={80}
                                    >
                                        <GraphQLImportanceTrack
                                            width={width - 150}
                                            height={80}
                                            endpoint="https://ga.staging.wenglab.org"
                                            signalURL="gs://gcp.wenglab.org/241-mammalian-2020v2.bigWig"
                                            sequenceURL="gs://gcp.wenglab.org/hg38.2bit"
                                            coordinates={currentDomain}
                                            key={`${currentDomain.chromosome}:${currentDomain.start}-${currentDomain.end}`}
                                            allowSelection
                                            onSelectionEnd={onImportanceTrackSelectionEnd}
                                        />
                                    </WrappedTrack>
                                )}
                                {TRACK_SUBSETS.map(subset => (
                                    <ShadedWrappedImportanceTrack
                                        width={width}
                                        height={20}
                                        urls={subset[2]}
                                        color={subset[1]}
                                        coordinates={currentDomain}
                                        title={subset[0]}
                                    />
                                ))}
                                { profileImportanceData && profileImportanceData[8] && (
                                    <WrappedDenseBigBed
                                        title='Biopsy- or imaging-confirmed associations'
                                        id='true'
                                        domain={currentDomain}
                                        width={width}
                                        height={40}
                                        data={profileImportanceData[8].data}
                                        onClick={item => onImportantRegionClick([ reverseCoordinateMap(item.start), reverseCoordinateMap(item.end) ])}
                                    />
                                )}
                                { profileImportanceData && profileImportanceData[7] && (
                                    <WrappedDenseBigBed
                                        title='EMR-defined associations'
                                        id='EMR'
                                        domain={currentDomain}
                                        width={width}
                                        height={40}
                                        data={profileImportanceData[7].data}
                                        onClick={item => onImportantRegionClick([ reverseCoordinateMap(item.start), reverseCoordinateMap(item.end) ])}
                                    />
                                )}
                                { profileImportanceData && profileImportanceData[6] && (
                                    <WrappedDenseBigBed
                                        title='modeled associations'
                                        id='NAFLDS'
                                        domain={currentDomain}
                                        width={width}
                                        height={40}
                                        data={profileImportanceData[6].data}
                                        onClick={item => onImportantRegionClick([ reverseCoordinateMap(item.start), reverseCoordinateMap(item.end) ])}
                                    />
                                )}
                                { profileImportanceData && profileImportanceData[5] && (
                                    <WrappedDenseBigBed
                                        title='Hepatic Stellate Cells'
                                        id='important-regions-HSC'
                                        domain={currentDomain}
                                        width={width}
                                        height={40}
                                        data={profileImportanceData[5].data}
                                        onClick={item => onImportantRegionClick([ reverseCoordinateMap(item.start), reverseCoordinateMap(item.end) ])}
                                    />
                                )}
                                { currentDomain.end - currentDomain.start < 30000 && (
                                    <WrappedTrack
                                        width={width}
                                        id="HSC-importance"
                                        height={80}
                                    >
                                        <GraphQLImportanceTrack
                                            width={width - 150}
                                            height={80}
                                            endpoint="https://ga.staging.wenglab.org"
                                            signalURL="gs://gcp.wenglab.org/projects/chrombpnet/HSC-ATAC/final-profile-scores/sum.bigWig"
                                            sequenceURL="gs://gcp.wenglab.org/hg38.2bit"
                                            coordinates={currentDomain}
                                            key={`${currentDomain.chromosome}:${currentDomain.start}-${currentDomain.end}`}
                                            allowSelection
                                            onSelectionEnd={onImportanceTrackSelectionEnd}
                                        />
                                    </WrappedTrack>
                                )}
                                { currentDomain.end - currentDomain.start < 30000 && (
                                    <WrappedTrack
                                        width={width}
                                        id="monocytes-u-title"
                                        height={40}
                                    >
                                        <EmptyTrack
                                            id="monocytes-U"
                                            transform=""
                                            width={width - 150}
                                            height={40}
                                            text="Monocytes (unstimulated)"
                                        />
                                    </WrappedTrack>
                                )}
                                { currentDomain.end - currentDomain.start < 30000 && (
                                    <WrappedTrack
                                        width={width}
                                        id="monocytes-u"
                                        height={80}
                                    >
                                        <GraphQLImportanceTrack
                                            width={width - 150}
                                            height={80}
                                            endpoint="https://ga.staging.wenglab.org"
                                            signalURL="gs://gcp.wenglab.org/projects/chrombpnet/rat-MASLD/profile-bigwigs/m167205.profile_scores.bw"
                                            sequenceURL="https://hgdownload.soe.ucsc.edu/goldenPath/rn7/bigZips/rn7.2bit"
                                            coordinates={currentDomain}
                                            key={`${currentDomain.chromosome}:${currentDomain.start}-${currentDomain.end}`}
                                            allowSelection
                                            onSelectionEnd={onImportanceTrackSelectionEnd}
                                        />
                                    </WrappedTrack>
                                )}
                                { currentDomain.end - currentDomain.start < 30000 && (
                                    <WrappedTrack
                                        width={width}
                                        id="monocytes-s-title"
                                        height={40}
                                    >
                                        <EmptyTrack
                                            id="monocytes-S"
                                            transform=""
                                            width={width - 150}
                                            height={40}
                                            text="Monocytes (stimulated)"
                                        />
                                    </WrappedTrack>
                                )}
                                { currentDomain.end - currentDomain.start < 30000 && (
                                    <WrappedTrack
                                        width={width}
                                        id="monocytes-s"
                                        height={80}
                                    >
                                        <GraphQLImportanceTrack
                                            width={width - 150}
                                            height={80}
                                            endpoint="https://ga.staging.wenglab.org"
                                            signalURL="gs://gcp.wenglab.org/projects/chrombpnet/immune/Monocytes_S.SRX4513783/Monocytes_S.SRX4513783.profile_scores.bw"
                                            sequenceURL="gs://gcp.wenglab.org/hg38.2bit"
                                            coordinates={currentDomain}
                                            key={`${currentDomain.chromosome}:${currentDomain.start}-${currentDomain.end}`}
                                            allowSelection
                                            onSelectionEnd={onImportanceTrackSelectionEnd}
                                        />
                                    </WrappedTrack>
                                )}
                                { highlights.map((highlight, i) => (
                                    <rect
                                        key={`highlight_${i}`}
                                        transform={`translate(${coordinateMap(highlight.coordinates[0])},-210)`}
                                        width={
                                            coordinateMap(highlight.coordinates[1]) -
                                            coordinateMap(highlight.coordinates[0])
                                        }
                                        fill="#ff0000"
                                        fillOpacity={0.1}
                                        height={210}
                                        onMouseOver={() => setSelectedHighlight(i)}
                                        onMouseOut={() => setSelectedHighlight(null)}
                                    />
                                ))}
                                { selectedHighlight !== null && highlights[selectedHighlight] && highlights[selectedHighlight].motif && (
                                    <g transform={`translate(${coordinateMap(highlights[selectedHighlight].coordinates[0]) - 120},-150)`}>
                                        <rect
                                            fill="#ffffff"
                                            stroke="#000000"
                                            strokeWidth={2}
                                            width={300}
                                            height={200}
                                        />
                                        <g transform="translate(10,60)">
                                            <RawLogo
                                                alphabet={DNAAlphabet}
                                                values={(highlights[selectedHighlight].reverseComplement ? reverseComplement(highlights[selectedHighlight].motif.pwm) : highlights[selectedHighlight].motif.pwm).map(logLikelihood([0.25, 0.25, 0.25, 0.25]))}
                                                glyphWidth={10}
                                                stackHeight={50}
                                                x={0}
                                                y={0}
                                            />
                                            { highlights[selectedHighlight].motif.tomtom_matches && highlights[selectedHighlight].motif.tomtom_matches.length > 0 && (
                                                <text x={0} y={80} fontWeight="bold">
                                                    { best(highlights[selectedHighlight].motif.tomtom_matches).target_id.startsWith("MA")
                                                        ? best(highlights[selectedHighlight].motif.tomtom_matches).jaspar_name
                                                        : best(highlights[selectedHighlight].motif.tomtom_matches).target_id }
                                                </text>
                                            )}
                                        </g>
                                    </g>
                                )}
                            </GenomeBrowser>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );

    };

export default GenomeExplorer;

/*
<Searchbox
                                onSearchSubmit={(domain: string, name?: string, isSnp?: boolean) => {
                                    let d: any = parseDomain(domain);
                                    if (isSnp) {
                                        d = {
                                            ...d,
                                            start: d.start - 10000,
                                            end: d.end + 10000,
                                        };
                                    }
                                    onDomainChanged(d);
                                }}
                                assembly={props.assembly}
                            />
                            */