import * as React from 'react';
import { Link, useParams } from 'react-router-dom';

import { Menu, Image, Icon } from 'semantic-ui-react';

import { formatFactorName } from '../utilities/misc';

import factorbooklogo from '../images/factorbooklogo.png';
import humanicon from '../images/humanicon.png';
import mouseicon from '../images/mouseicon.png';

export const Navbar: React.FC = props => {
    const { species, factor, celltype } = useParams<{ species: string; factor: string; celltype: string }>();
    // FIXME: use config (and fix elsewhere where `species === 'human'`)
    const assembly = species === undefined ? undefined : species === 'human' ? 'GRCh38' : 'mm10';
    const img = assembly === 'mm10' ? mouseicon : humanicon;
    return (
        <Menu className="top fixed transparent" style={{ fontSize: '11pt' }}>
            <Menu.Item as={Link} to="/">
                <Image src={factorbooklogo} height={30} />
                &nbsp;Factorbook Home
            </Menu.Item>
            <Menu.Item as={Link} to="/downloads">
                <Icon name="download" />
                &nbsp;Downloads
            </Menu.Item>
            {species && (
                <Menu.Item>
                    <Image src={img} height={30} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{ fontWeight: 'bold' }}>{species.charAt(0).toUpperCase() + species.slice(1)}</span>
                        <div style={{ fontSize: '0.75em' }}>{assembly}</div>
                    </div>
                </Menu.Item>
            )}
            {factor && species && <Menu.Item>{formatFactorName(factor, species)}</Menu.Item>}
            {celltype && <Menu.Item>{celltype}</Menu.Item>}
            {props.children}
        </Menu>
    );
};
