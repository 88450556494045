import React, { useState, useContext, useMemo } from 'react';
import { Container, Message, Button } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { MultiRegionSearch } from 'genomic-file-upload';

import { ApiContext } from 'apicontext';
import { GenomicRange } from 'components/types';
import MemeOccuDataTable from './memeoccudatatable';
import DownloadMemeOccurrences from './../downloadmemeoccurrences';

const SearchMemeOccuByRegion: React.FC<{
    peaks_accession: string;
    consensus_regex: string;
    assembly?: string;
    marginTop?: string;
}> = ({ peaks_accession, consensus_regex, assembly, marginTop }) => {
    const { species } = useParams<{ species: string }>();
    const asm = assembly ? assembly : species === 'human' ? 'GRCh38' : 'mm10';
    const clients = useContext(ApiContext);
    const streamMemeService = clients.restEndpoints.streamMemeService;
    const [regions, setRegions] = useState<GenomicRange[]>([]);
    const [downloading, setDownloading] = useState(false);
    const formattedRegions = useMemo(
        () => regions.map(x => ({ chromosome: x.chromosome!, start: x.start!, end: x.end! })),
        [regions]
    );

    return (
        <div style={{ marginLeft: '3%', marginTop: marginTop || '2em' }}>
            <Container>
                <MultiRegionSearch
                    searchInstructions={`Enter genomic coordinates (${asm}):`}
                    title={`Searching ${peaks_accession} motif sites`}
                    onSelectionChanged={setRegions}
                />
                {regions && regions.length > 0 && regions.length < 5000 ? (
                    <MemeOccuDataTable
                        regions={formattedRegions}
                        peaks_accession={peaks_accession}
                        consensus_regex={consensus_regex}
                    />
                ) : regions && regions.length > 5000 ? (
                    <Message warning>
                        The uploaded file(s) contain more than 5,000 regions, so we cannot show a preview of the results
                        on this page. You can download the full set of results in BED or JSON format.
                    </Message>
                ) : null}
                {regions && regions.length > 0 && (
                    <Button onClick={() => setDownloading(true)}>Download motif sites in BED format</Button>
                )}
                {downloading && (
                    <DownloadMemeOccurrences
                        peaks_accession={peaks_accession}
                        consensus_regex={consensus_regex}
                        genomic_region={regions}
                        onComplete={() => setDownloading(false)}
                        onError={() => {
                            setDownloading(false);
                        }}
                        streamMemeOccurrencesService={streamMemeService}
                    />
                )}
            </Container>
        </div>
    );
};
export default SearchMemeOccuByRegion;
