import React, { useContext, useState, useCallback, useRef, useLayoutEffect, useEffect } from 'react';
import { Input, Menu, Grid } from 'semantic-ui-react';
import { ApiContext } from 'apicontext';
import { useQuery } from '@apollo/client';
import { MOTIF_ENRICHMENT_BIOSAMPLES_QUERY, MOTIF_ENRICHMENT_BIOSAMPLES_QUERY_DATA } from './queries';
import MotifEnrichment from './motifenrichment';
import { useParams, useHistory } from 'react-router';
import { motifEnrichmentRoute } from 'routing';

type CelltypeSelectionProps = {
    data: MOTIF_ENRICHMENT_BIOSAMPLES_QUERY_DATA;
    activeCelltype: string;
    selectCelltype: (celltype: string) => void;
};

const CelltypeSelection: React.FC<CelltypeSelectionProps> = ({ data, activeCelltype, selectCelltype }) => {
    const [search, setSearch] = useState('');
    const lowerSearch = search.toLowerCase();
    const onSearchChange = useCallback((q: any) => setSearch(q.target.value), []);

    const activeCellTypeElement = useRef<HTMLDivElement>();
    useLayoutEffect(() => activeCellTypeElement.current?.scrollIntoView(), []);

    const displayedcts = data.motif_enrichment_biosamples.filter(x => x.toLowerCase().includes(lowerSearch));

    return (
        <>
            {data.motif_enrichment_biosamples.length > 10 && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Input icon="search" placeholder="Search cell types..." onChange={onSearchChange} />
                </div>
            )}
            <Menu
                vertical
                tabular
                fluid
                style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 'calc(100% - 65px)' }}
            >
                {displayedcts.map(ct => (
                    <React.Fragment key={ct}>
                        <div ref={ct === activeCelltype ? (activeCellTypeElement as any) : undefined} />
                        <Menu.Item
                            name={ct}
                            active={ct === activeCelltype}
                            onClick={() => (ct === activeCelltype ? {} : selectCelltype(ct))}
                            as={'span'}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: ct === activeCelltype ? 'lightblue' : undefined,
                            }}
                        >
                            {ct}
                        </Menu.Item>
                    </React.Fragment>
                ))}
            </Menu>
        </>
    );
};

const MotifEnrichmentLanding: React.FC = () => {
    const history = useHistory();
    const { species, celltype: activeCelltype } = useParams<{ species: string; celltype?: string }>();
    const setCelltype = React.useCallback(
        (celltype: string) => {
            history.push(motifEnrichmentRoute(species, celltype));
        },
        [history, species]
    );
    const client = useContext(ApiContext).client;
    const { data, loading, error } = useQuery<MOTIF_ENRICHMENT_BIOSAMPLES_QUERY_DATA>(
        MOTIF_ENRICHMENT_BIOSAMPLES_QUERY,
        {
            client,
        }
    );

    useEffect(() => {
        if (!activeCelltype && data?.motif_enrichment_biosamples) {
            setCelltype(data.motif_enrichment_biosamples[0]);
        }
    }, [data, activeCelltype, setCelltype]);

    if (!data || loading || error) {
        return <span>Loading...</span>;
    }

    return (
        <div style={{ paddingTop: '5em', position: 'relative' }}>
            <Grid style={{ width: '100%' }}>
                <Grid.Column width={3} style={{ maxHeight: 'calc(100vh - 60px)', minHeight: '50vh' }}>
                    <CelltypeSelection data={data} activeCelltype={activeCelltype || ''} selectCelltype={setCelltype} />
                </Grid.Column>
                <Grid.Column
                    width={13}
                    style={{
                        maxHeight: 'calc(100vh - 60px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    {activeCelltype && <MotifEnrichment key={activeCelltype} biosample={activeCelltype} />}
                </Grid.Column>
            </Grid>
        </div>
    );
};

export default MotifEnrichmentLanding;
