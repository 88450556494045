/**
 * ShadedWrappedImportanceTrack: an importance track with a semi-transparent shaded background.
 */

import React, { useEffect } from 'react';
import { EmptyTrack, WrappedTrack } from 'umms-gb';
import { GraphQLImportanceTrack } from 'bpnet-ui';
import { ImportanceTrackDataPoint } from 'bpnet-ui/dist/components/ImportanceTrack/ImportanceTrack';

type GenomicRange = {
    chromosome: string;
    start: number;
    end: number;
};

export type ShadedWrappedImportanceTrackProps = {
    coordinates: GenomicRange;
    width: number;
    maximumWidth?: number;
    color: string;
    height: number;
    onImportanceTrackSelectionEnd?: (coordinates: [number, number], values: ImportanceTrackDataPoint[]) => void;
    onHeightChanged?: (height: number) => void;
    urls: string[];
    title: string;
};

export const ShadedWrappedImportanceTrack: React.FC<ShadedWrappedImportanceTrackProps>
    = ({ maximumWidth, coordinates, width, color, onImportanceTrackSelectionEnd, onHeightChanged, height, urls, title }) => {

        const totalHeight = height * (urls.length + 3);
        console.log(title, urls);

        // determine if the coordinates are too wide for display; update parent on height
        if (maximumWidth === undefined) maximumWidth = 30000;
        const shouldNotDisplay = (coordinates.end! - coordinates.start!) > maximumWidth;
        useEffect(() => {
            onHeightChanged && onHeightChanged(shouldNotDisplay ? 0 : totalHeight)
        }, [onHeightChanged, shouldNotDisplay]);

        // render
        return shouldNotDisplay ? null : (
            <>
                <WrappedTrack
                    width={width}
                    id="conservation"
                    height={totalHeight}
                >
                    <rect
                        width={width}
                        height={totalHeight}
                        fill={color}
                        fillOpacity={0.2}
                    />
                </WrappedTrack>
                <g transform='translate(150,30)'>
                    <EmptyTrack
                        width={width - 150}
                        height={20}
                        text={title}
                        transform=''
                        id=''
                    />
                </g>
                {urls.map((url, i) => (
                    <g transform={`translate(150,${(i + 3) * height})`}>
                        <GraphQLImportanceTrack
                            width={width - 150}
                            height={height}
                            endpoint="https://ga.staging.wenglab.org"
                            signalURL={`https://users.wenglab.org/pratth/profile-bigwigs/${url}`}
                            sequenceURL="gs://gcp.wenglab.org/hg38.2bit"
                            coordinates={coordinates}
                            key={`${coordinates.chromosome}:${coordinates.start}-${coordinates.end}#${url}`}
                            allowSelection
                            onSelectionEnd={onImportanceTrackSelectionEnd}
                        />
                    </g>
                ))}
            </>
        );

    };
