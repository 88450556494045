import React, { useCallback, useMemo } from 'react';
import streamSaver from 'streamsaver';
import parse from 'url-parse';
import { ChunkedJSONSearch } from 'genomic-file-upload';
import { DownloadMemeOccurrencesProps, MemeMotifOccurrence, BatchResponse } from './types';

const url = parse(window.location.href);
streamSaver.mitm = `${url.protocol}//${url.host}/mitm.html`;

function bedLine(d: MemeMotifOccurrence): string {
    return `${d.peaks_accession}\t${d.consensus_regex}\t${d.genomic_region.chromosome}\t${d.genomic_region.start}\t${d.genomic_region.end}\t${d.strand}\t${d.q_value}`;
}

const DownloadMemeOccurrences: React.FC<DownloadMemeOccurrencesProps> = props => {
    const writer = useMemo(
        () =>
            streamSaver
                .createWriteStream(`${props.peaks_accession}-${props.consensus_regex}-memeoccurrences.bed`)
                .getWriter(),
        [props]
    );
    const getReader = useCallback(
        async search => {
            const variables = search[2] ? {
                peaks_accession: search[0],
                consensus_regex: search[1],
                genomic_region: search[2]
            } : {
                peaks_accession: search[0],
                consensus_regex: search[1],
            } ;
            const response = await fetch(props.streamMemeOccurrencesService, {
                method: 'post',
                body: JSON.stringify(variables),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response && response.body && response.body.getReader();
        },
        [props]
    );

    const getResults = useCallback((response: BatchResponse[]) => {
        let results: MemeMotifOccurrence[] = [];
        response.forEach(set => {
            results = results.concat(...set.memeoccurrences);
        });
        return results;
    }, []);

    const getProgress = useCallback((response: BatchResponse[]) => {
        return response.reduce((x, v) => x + v.progress, 0) * 100.0;
    }, []);

    const onBatchComplete = useCallback(
        (results: MemeMotifOccurrence[]) => {
            writer.write(new TextEncoder().encode(results.map(bedLine).join('\n')));
        },
        [writer]
    );

    const onComplete = useCallback(() => {
        writer
            .close()
            .then(props.onComplete)
            .catch(props.onComplete);
    }, [writer, props.onComplete]);

    const onError = useCallback(
        (error: any) => {
            writer
                .close()
                .then(() => props.onError(error))
                .catch(() => props.onError(error));
            props.onError(error);
        },
        [writer, props]
    );
    return (
        <ChunkedJSONSearch
            getProgress={getProgress}
            onBatchComplete={onBatchComplete}
            onComplete={onComplete}
            onError={onError}
            getReader={getReader}
            getResults={getResults}
            delimiter="endofbatch"
            searchTerms={[props.peaks_accession, props.consensus_regex, props.genomic_region]}
        />
    );
};
export default DownloadMemeOccurrences;
