import React, { useContext, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Message, Modal, Placeholder } from 'semantic-ui-react';
import { DNALogo } from 'logojs-react';

import {
    PEAK_QUERY,
    MotifQueryDataOccurrence,
    TomtomMatchQueryData,
    TOMTOM_MATCH_QUERY,
    MotifQueryDataOccurrenceMotif,
} from './queries';
import { Region } from '../types';
import { DataTable, DataTableColumn } from 'ts-ztable';
import { PeakResult, PeakQueryResponse } from './types';
import { ApiContext } from 'apicontext';
import loader from 'common/loader.svg';
import { useMotifsInPeak } from './hooks';

const PEAKS_COLUMNS = (includeFactor: boolean): DataTableColumn<PeakResult>[] => {
    const cols: DataTableColumn<PeakResult>[] = [
        {
            header: 'Chromosome',
            value: row => row.chrom,
        },
        {
            header: 'Start',
            value: row => row.chrom_start,
            render: row => row.chrom_start.toLocaleString(),
        },
        {
            header: 'End',
            value: row => row.chrom_end,
            render: row => row.chrom_end.toLocaleString(),
        },
        {
            header: 'Biosample',
            value: row => row.biosample,
        },
        {
            header: 'File Accession',
            value: row => row.file_accession,
            render: row => (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.encodeproject.org/files/${row.file_accession}/`}
                >
                    {row.file_accession}
                </a>
            ),
        },
        {
            header: 'q value',
            value: row => row.q_value,
        },
    ];
    if (includeFactor) cols.splice(4, 0, { header: 'Factor', value: row => row.target });
    cols.push({
        header: 'Motifs found',
        value: () => '',
        render: (row: PeakResult) => <MotifCell chromosome={row.chrom} start={row.chrom_start} end={row.chrom_end} />,
    } as any);
    return cols;
};

const MotifCell: React.FC<{ chromosome: string; start: number; end: number }> = ({ chromosome, start, end }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { data: occurrences, status, error } = useMotifsInPeak({ chromosome, start, end });
    const loading = status === 'pending';
    return (
        <div>
            {loading && <img style={{ width: '16px', height: '16px' }} src={loader} alt={'Loading'} />}
            {error && <span>Error!</span>}
            {!loading &&
                !error &&
                occurrences &&
                (occurrences.length > 0 ? (
                    <button
                        style={{
                            background: 'none',
                            border: 'none',
                            padding: 0,
                            fontFamily: 'arial, sans-serif',
                            color: '#069',
                            cursor: 'pointer',
                            outline: 'none',
                        }}
                        onClick={() => setModalOpen(true)}
                    >
                        {occurrences.length >= 30 ? '>30' : occurrences.length}
                    </button>
                ) : (
                    0
                ))}
            {!loading && !error && occurrences && modalOpen && (
                <MotifsModal
                    open={modalOpen}
                    setOpen={setModalOpen}
                    chromosome={chromosome}
                    start={start}
                    end={end}
                    occurrences={occurrences}
                />
            )}
        </div>
    );
};

const MOTIFS_COLS: DataTableColumn<MotifQueryDataOccurrence>[] = [
    {
        header: 'Chromosome',
        value: row => row.genomic_region.chromosome,
    },
    {
        header: 'Start',
        value: row => row.genomic_region.start,
        render: row => row.genomic_region.start.toLocaleString(),
    },
    {
        header: 'Peaks file',
        value: row => row.peaks_accession,
    },
    {
        header: 'PWM',
        value: () => '',
        render: row => <PWMCell peaks_accession={row.peaks_accession} motif={row.motif} />,
    },
    {
        header: 'q value',
        value: row => row.q_value,
    },
];

const PWMCell: React.FC<{ peaks_accession: string; motif: MotifQueryDataOccurrenceMotif }> = ({
    peaks_accession,
    motif: { pwm, id },
}) => {
    const client = useContext(ApiContext).client;
    const { data, error, loading } = useQuery<TomtomMatchQueryData>(TOMTOM_MATCH_QUERY, {
        client,
        variables: {
            peaks_accessions: peaks_accession,
            ids: id,
        },
    });
    let matchLine = (
        <Placeholder>
            <Placeholder.Line length="full" />
        </Placeholder>
    );
    if (!loading && !error && data) {
        const match = data.target_motifs
            .slice()
            .filter(x => x.e_value < 1e-5)
            .sort((a, b) => a.e_value - b.e_value)[0];
        if (match === undefined) {
            matchLine = <span>(no external database match)</span>;
        } else {
            const jasparName = match.jaspar_name ? `/${match.jaspar_name}` : '';
            const source = match.target_id.startsWith('MA') ? 'JASPAR' : 'HOCOMOCO';
            matchLine = <span>{`${match.target_id}${jasparName} (${source})`}</span>;
        }
    }
    return (
        <>
            <DNALogo ppm={pwm} height={'50px'} />
            <br />
            {matchLine}
        </>
    );
};

type MotifsModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    chromosome: string;
    start: number;
    end: number;
    occurrences: MotifQueryDataOccurrence[];
};
const MotifsModal: React.FC<MotifsModalProps> = ({
    open,
    setOpen,
    chromosome,
    start: peakStart,
    end: peakEnd,
    occurrences,
}) => {
    const motifs = useMemo(
        () => occurrences.slice().sort((a, b) => a.genomic_region.start - b.genomic_region.start) || [],
        [occurrences]
    );
    const [page, setPage] = useState(0);
    const width = 500;
    const height = 25;
    const start = Math.min(peakStart, motifs[0]?.genomic_region.start || 0);
    const end = Math.max(peakEnd, motifs[motifs.length - 1]?.genomic_region.end || 0);
    const rangeSize = end - start;
    const pageSize = 4;
    const pageStart = Math.min(motifs.length - 1, page * pageSize);
    const pageEnd = Math.min(motifs.length, (page + 1) * pageSize);
    const pageRangeStart = motifs[pageStart]?.genomic_region.start || 0;
    const pageRangeEnd = motifs[pageEnd - 1]?.genomic_region.end || 0;
    const pagePxStart = ((pageRangeStart - start) / rangeSize) * width;
    const pagePxEnd = ((pageRangeEnd - start) / rangeSize) * width;
    const firstY = height * 0.8;
    const secondY = firstY + height * 2;

    const pageMotifs = motifs.slice(pageStart, pageEnd);
    const overlaps = (range: [number, number], instance: MotifQueryDataOccurrence) =>
        instance.genomic_region.start < range[1] && instance.genomic_region.end > range[0];
    let groupRange: [number, number] | undefined = undefined;
    let groups: MotifQueryDataOccurrence[][] = [];
    let group: MotifQueryDataOccurrence[] = [];
    for (let i = 0; i < pageMotifs.length; i++) {
        const instance = pageMotifs[i];
        if (groupRange === undefined) {
            groupRange = [instance.genomic_region.start, instance.genomic_region.end];
        }
        if (overlaps(groupRange, instance)) {
            group.push(instance);
            groupRange[1] = instance.genomic_region.end;
        } else {
            groups.push(group);
            group = [instance];
            groupRange = [instance.genomic_region.start, instance.genomic_region.end];
        }
    }
    groups.push(group);
    const peakView = (
        <svg width={width} height={height * 5}>
            <text textAnchor={'start'} dominantBaseline="hanging" x={0} y={0}>
                {start.toLocaleString()}
            </text>
            <text textAnchor={'end'} dominantBaseline="hanging" x={width} y={0}>
                {end.toLocaleString()}
            </text>
            <rect
                width={width}
                height={height}
                y={firstY}
                rx="5"
                style={{
                    fill: 'grey',
                    strokeWidth: 3,
                    stroke: 'none',
                }}
            />
            <g>
                {motifs.map((instance, i) => {
                    const motifStart = instance.genomic_region.start - start;
                    const motifEnd = instance.genomic_region.end - start;
                    const pxStart = (width * motifStart) / rangeSize;
                    const pxEnd = (width * motifEnd) / rangeSize;
                    return (
                        <rect
                            key={i}
                            width={pxEnd - pxStart}
                            height={height}
                            x={pxStart}
                            y={firstY}
                            style={{ fill: 'red', opacity: '50%' }}
                        />
                    );
                })}
            </g>
            <line
                x1={pagePxStart}
                x2={0}
                y1={firstY + height}
                y2={secondY}
                style={{ stroke: 'black', strokeWidth: '1px' }}
            />
            <line
                x1={pagePxEnd}
                x2={width}
                y1={firstY + height}
                y2={secondY}
                style={{ stroke: 'black', strokeWidth: '1px' }}
            />
            <rect
                width={width}
                height={height}
                y={secondY}
                rx="5"
                style={{
                    fill: 'grey',
                    strokeWidth: 3,
                    stroke: 'none',
                }}
            />
            <g>
                {groups.flatMap((group, groupi) =>
                    group.map((instance, i) => {
                        const rangeSize = pageRangeEnd - pageRangeStart;
                        const start = instance.genomic_region.start - pageRangeStart;
                        const end = instance.genomic_region.end - pageRangeStart;
                        const pxStart = (width * start) / rangeSize;
                        const pxEnd = (width * end) / rangeSize;
                        return (
                            <rect
                                key={groupi * 100 + i}
                                x={pxStart}
                                width={pxEnd - pxStart}
                                y={secondY + i * (height / group.length)}
                                height={height / group.length}
                                rx={5}
                                style={{
                                    fill: 'red',
                                    opacity: '50%',
                                    stroke: 'darkred',
                                    strokeWidth: '1px',
                                }}
                            />
                        );
                    })
                )}
            </g>
            <text textAnchor={'start'} dominantBaseline="baseline" x={0} y={secondY + height * 1.8}>
                {pageRangeStart.toLocaleString()}
            </text>
            <text textAnchor={'end'} dominantBaseline="baseline" x={width} y={secondY + height * 1.8}>
                {pageRangeEnd.toLocaleString()}
            </text>
        </svg>
    );

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Modal.Header>
                Motifs found in {chromosome}:{peakStart.toLocaleString()}-{peakEnd.toLocaleString()}
            </Modal.Header>
            <Modal.Content>
                <div style={{ display: 'flex', justifyContent: 'center' }}>{peakView}</div>
                {motifs && (
                    <DataTable
                        columns={MOTIFS_COLS}
                        rows={motifs}
                        sortColumn={1}
                        sortDescending
                        itemsPerPage={pageSize}
                        page={page}
                        setPage={setPage}
                    />
                )}
            </Modal.Content>
        </Modal>
    );
};

export type PeakDataTableProps = {
    factor?: string;
    assembly: string;
    regions: Region[];
    accession?: string;
};

const PeakDataTable: React.FC<PeakDataTableProps> = ({ factor, assembly, regions, accession }) => {
    const client = useContext(ApiContext).client;
    const { data: peaksData, loading, error } = useQuery<PeakQueryResponse>(PEAK_QUERY, {
        client: client,
        variables: {
            assembly: assembly,
            range: regions,
            target: factor,
            limit: 1000,
            experiment_accession: accession,
        },
    });

    return (
        <>
            {loading ? <h1>Loading...</h1> : null}
            {error ? <h1 style={{ color: 'red' }}>Error! Please refresh.</h1> : null}
            {peaksData &&
                (peaksData.peaksrange.data.length === 1000 ? (
                    <Message warning>
                        Your query returned more than 1,000 matching peaks. For performance, we only show a preview of
                        1,000 on this page. Use the buttons below to download the full set.
                    </Message>
                ) : (
                    <h3>
                        {peaksData.peaksrange.data.length.toLocaleString()} {factor} peaks matched your input:
                    </h3>
                ))}
            {peaksData && peaksData.peaksrange.data && (
                <DataTable
                    searchable
                    columns={PEAKS_COLUMNS(!factor)}
                    rows={peaksData.peaksrange.data}
                    itemsPerPage={8}
                    sortColumn={1}
                    sortDescending
                    emptyText="No peaks found."
                    noOfDefaultColumns={10}
                />
            )}
        </>
    );
};
export default PeakDataTable;
