import gql from 'graphql-tag';
export const MEMEOCCU_QUERY = gql`
    query occurrences($range: [GenomicRegionInput!], $peaks_accession: String, $consensus_regex: String) {
        meme_occurrences(genomic_region: $range, peaks_accession: $peaks_accession, consensus_regex: $consensus_regex) {
            peaks_accession
            strand
            q_value
            consensus_regex
            genomic_region {
                chromosome
                start
                end
            }
        }
    }
`;
