import React from 'react';
import { Message } from 'semantic-ui-react';
import { ParsedMotifFile } from './types';

export type ErrorMessageProps = {
    files: ParsedMotifFile[];
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ files }) =>
    files.length === 0 ? null : (
        <Message error>
            <Message.Header>One or more of your files could not be searched:</Message.Header>
            <Message.List items={files.map(x => x.file.name)} />
            <Message.Content>
                Please check that these files are in valid MEME format. Note that the maximum file size that can be
                handled is 100 MB.
            </Message.Content>
        </Message>
    );

export default ErrorMessage;
