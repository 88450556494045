import { ApiContext } from 'apicontext';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    IntersectionViewProps,
    MotifOccurrenceMatchWithSNP,
    OccurrenceQueryResponse,
    MotifIntersectionMergerProps,
    RdhsOccurrenceQueryResponse,
    MotifOccurrenceQueryResponse,
} from './types';
import { Menu, Message, Modal, Progress, Form, Radio } from 'semantic-ui-react';
import { groupBy } from 'queryz';
import { DataTable } from 'ts-ztable';
import { COMPLETE_MOTIF_TABLE_COLUMNS, MOTIF_TABLE_COLUMNS } from './tables';
import { MOTIF_QUERY, RDHS_OCCU_QUERY } from './queries';
import { GenomicRange } from 'components/types';

function f(coordinates: GenomicRange): { chromosome: string; start: number; end: number } {
    return {
        chromosome: coordinates.chromosome!,
        start: coordinates.start!,
        end: coordinates.end!,
    };
}
const usePrevious = value => {
    const ref = React.useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const MotifIntersectionMerger: React.FC<MotifIntersectionMergerProps> = props => {
    const [progress, setProgress] = useState(0);
    const client = useContext(ApiContext).client;

    const next = useCallback(
        (i: number, results: MotifOccurrenceMatchWithSNP[]) => {
            if (i === props.snps.length) props.onResultsReceived(results);
            else if (!props.snps[i]?.coordinates?.chromosome) next(i + 1, results);
            else
                client
                    .query<OccurrenceQueryResponse>({
                        query: props.rdhs ? RDHS_OCCU_QUERY : MOTIF_QUERY,
                        variables: { range: f(props.snps[i].coordinates) },
                        errorPolicy: 'ignore',
                    })
                    .then(x => {
                        setProgress(i + 1);
                        const d = props.rdhs
                            ? (x.data as RdhsOccurrenceQueryResponse).rdhs_motif_occurrences
                                  .filter(x => x && x.motif !== null)
                                  .map(x => ({ ...x, snp: props.snps[i] }))
                            : (x.data as MotifOccurrenceQueryResponse).meme_occurrences
                                  .filter(x => x && x.motif !== null)
                                  .map(x => ({ ...x, snp: props.snps[i] }));

                        next(i + 1, [...results, ...d]);
                    });
        },
        [client, props]
    );

    useEffect(() => {
        setProgress(0);
        next(0, []);
    }, [props.snps, next]);

    return (
        <Modal open>
            <Modal.Header>Searching for intersecting TF motifs...</Modal.Header>
            <Modal.Content>
                <Progress
                    percent={((progress * 100.0) / props.snps.length).toFixed(2)}
                    progress
                    size="medium"
                    indicating
                />
            </Modal.Content>
        </Modal>
    );
};

const MotifIntersectionView: React.FC<IntersectionViewProps> = props => {
    const [results, setResults] = useState<MotifOccurrenceMatchWithSNP[] | null>(null);
    const [page, setPage] = useState(0);
    const [val, setVal] = useState<String>('meme');
    const groupedSNPs = useMemo(() => {
        const grouped = groupBy(
            results || [],
            x => x.snp.id,
            x => x
        );
        return props.snps.map(snp => ({
            ...snp,
            motifCount:
                grouped.get(snp.id)?.filter(x => x.motif.flank_p_value < 0.05 && x.motif.shuffled_p_value < 0.05)
                    .length || 0,
        }));
    }, [results, props.snps]);
    const filteredResults = useMemo(
        () => (results || []).filter(x => x.motif.flank_p_value < 0.05 && x.motif.shuffled_p_value < 0.05),
        [results]
    );
    const prevVal = usePrevious(val);
    const completeViewTableColumns =
        val === 'rdhs'
            ? [
                  ...COMPLETE_MOTIF_TABLE_COLUMNS,
                  {
                      header: 'occurrence p-value',
                      value: x => x.p_value,
                  },
                  {
                      header: 'rdhs',
                      value: x => x.rdhs!!,
                  },
              ]
            : [
                  ...COMPLETE_MOTIF_TABLE_COLUMNS,
                  {
                      header: 'occurrence q-value',
                      value: x => x.q_value,
                  },
              ];
    return results === null || val !== prevVal ? (
        <MotifIntersectionMerger
            rdhs={val === 'rdhs'}
            snps={props.snps}
            onResultsReceived={setResults}
            assembly={props.assembly}
        />
    ) : (
        <>
            <Form>
                <Form.Field>
                    <Radio
                        label="ChIP-seq peak Motif Sites"
                        name="radioGroup"
                        value="meme"
                        checked={val === 'meme'}
                        onChange={(_, data) => setVal(data.value!! as string)}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label="rDHS Motif Sites Occurrences"
                        name="radioGroup"
                        value="rdhs"
                        checked={val === 'rdhs'}
                        onChange={(_, data) => setVal(data.value!! as string)}
                    />
                </Form.Field>
            </Form>
            <Message info>
                Searched {props.snps.length} SNPs, of which {groupedSNPs.filter(x => x.motifCount > 0).length} intersect
                at least one TF motif.
            </Message>
            <Menu secondary pointing>
                <Menu.Item active={page === 0} onClick={() => setPage(0)}>
                    Summary View
                </Menu.Item>
                <Menu.Item active={page === 1} onClick={() => setPage(1)}>
                    Complete List
                </Menu.Item>
            </Menu>
            {page === 0 ? (
                <DataTable
                    key="summary"
                    columns={MOTIF_TABLE_COLUMNS}
                    rows={groupedSNPs}
                    sortColumn={2}
                    searchable
                    itemsPerPage={7}
                />
            ) : (
                <DataTable
                    key="complete"
                    columns={completeViewTableColumns}
                    rows={filteredResults}
                    sortColumn={3}
                    searchable
                    itemsPerPage={3}
                />
            )}
        </>
    );
};
export default MotifIntersectionView;
