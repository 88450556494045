import React, { useMemo } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import MotifGenomeBrowser from './browser/browserpage';
import { Divider, Header, Loader, Menu } from 'semantic-ui-react';
import SearchMemeOccuByRegion from './searchmemeoccubyregion/searchmemeoccubyregion';
import { useMotifData } from './hooks';

const MemeMotifDetails: React.FC = () => {
    const history = useHistory();
    const { species, factor, accession, consensus_regex, detail, eacc } = useParams<{
        species: string;
        factor: string;
        accession: string;
        consensus_regex: string;
        detail?: string;
        eacc: string;
    }>();
    const { data, loading } = useMotifData(accession);
    const motif = useMemo( () => data && data.meme_motifs.find(x => x.consensus_regex === consensus_regex), [ data, consensus_regex ]);

    if (!detail)
        return <Redirect to={`/${species}/${factor}/mememotif/${eacc}/${accession}/${consensus_regex}/genomebrowser`} />;

    const inner = (() => {
        switch (detail!.toLowerCase()) {
            case 'genomebrowser':
                return (
                    <MotifGenomeBrowser
                        peaks_accession={accession}
                        consensus_regex={consensus_regex}
                        assembly={species === 'human' ? 'GRCh38' : 'mm10'}
                        motif={motif}
                        eacc={eacc}
                    />
                );
            case 'memeoccurrences':
                return (
                    <div>
                        <SearchMemeOccuByRegion peaks_accession={accession} consensus_regex={consensus_regex} />
                    </div>
                );
            default:
                return (
                    <Redirect to={`/tf/${species}/${factor}/mememotif/${accession}/${consensus_regex}/genomebrowser`} />
                );
        }
    })();
    const MenuItem: React.FC<{ accession?: string; page: string; title: string }> = ({
        accession,
        page,
        title,
    }) => (
        <Menu.Item
            active={detail!.toLowerCase() === page}
            onClick={() => history.push(`/${species}/${factor}/mememotif/${eacc}/${accession}/${consensus_regex}/${page}`)}
        >
            {title}
        </Menu.Item>
    );
    return loading ? <Loader active>Loading...</Loader> : (
        <div style={{ paddingTop: '5em', position: 'relative', width: "90%", marginLeft: "5%" }}>
            <span style={{ fontVariant: "small-caps" }}><Header as="h2">Browsing Genomic Instances for {consensus_regex} in {eacc}</Header></span>
            <Divider />
            <Menu attached="top" tabular>
                <MenuItem page="genomebrowser" title="Genome Browser View" accession={accession} />
                <MenuItem page="memeoccurrences" title="ChIP-seq Peak Motif Sites" accession={accession} />
            </Menu>
            <div style={{ marginTop: '1rem' }}>{inner}</div>
        </div>
    );
};

export default MemeMotifDetails;
