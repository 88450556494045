import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Grid, Table, Segment, Card, Placeholder, Image, Label } from 'semantic-ui-react';
import { ApiContext } from 'apicontext';
import { DataTable, DataTableColumn } from 'ts-ztable';

import { modificationsString } from '../../utilities/factormods';
import { formatFactorName } from '../../utilities/misc';
import { GenomicRange, BiosamplePartitionedDatasetCollection } from '../types';
import { Dataset, FunctionPageProps, FactorData, HGNCData, EnsembleData } from './types';
import { FACTOR_DESCRIPTION_QUERY } from './queries';
import CtDetails from 'components/celltype/ctdetails';
import { experimentRoute, cellTypeRoute } from '../../routing';
import { TFLink } from './types';

function looksBiological(value: string): boolean {
    const v = value.toLowerCase();
    return v.includes('gene') || v.includes('protein');
}

const TFDetails = (assembly: string, factor: string): TFLink[] => [
    {
        title: 'ENCODE',
        url: `https://www.encodeproject.org/search/?searchTerm=${factor}&type=Experiment&assembly=${assembly}&assay_title=TF+ChIP-seq&files.output_type=optimal+IDR+thresholded+peaks&files.output_type=pseudoreplicated+IDR+thresholded+peaks&status=released`,
    },
    {
        title: 'Ensembl',
        url: 'http://www.ensembl.org/Human/Search/Results?q=' + factor + ';site=ensembl;facet_species=Human',
    },
    {
        title: 'GO',
        url: 'http://amigo.geneontology.org/amigo/search/bioentity?q=' + factor,
    },
    {
        title: 'GeneCards',
        url: 'http://www.genecards.org/cgi-bin/carddisp.pl?gene=' + factor,
    },
    {
        title: 'HGNC',
        url: 'http://www.genenames.org/cgi-bin/gene_search?search=' + factor + '&submit=Submit',
    },
    {
        title: 'RefSeq',
        url: `http://www.ncbi.nlm.nih.gov/nuccore/?term=${factor}+AND+${
            assembly.toLowerCase() !== 'mm10' ? '"Homo sapiens"[porgn:__txid9606]' : '"Mus musculus"[porgn]'
        }`,
    },
    {
        title: 'UCSC Genome Browser',
        url: `https://genome.ucsc.edu/cgi-bin/hgTracks?clade=mammal&org=Human&db=hg19&position=${factor}&hgt.suggestTrack=knownGene&Submit=submit`,
    },
    {
        title: 'UniProt',
        url: `http://www.uniprot.org/uniprot/?query=${factor}&sort=score`,
    },
    {
        title: 'Wikipedia',
        url: `https://en.wikipedia.org/wiki/${factor}`,
    },
];

const TFDETAILS_COLUMNS: DataTableColumn<TFLink>[] = [
    {
        header: 'Link Title',
        value: row => row.title,
        render: row => (
            <a target="_blank" rel="noopener noreferrer" href={row.url}>
                {row.title}
            </a>
        ),
    },
];

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export function datasetColumns(species: string, withCellType = true): DataTableColumn<Dataset>[] {
    const r = [
        {
            header: 'Experiment Accession',
            value: row => row.accession,
            render: row => <Link to={experimentRoute(row.accession)}>{row.accession}</Link>,
        },
        {
            header: 'Cell Type',
            value: row => row.biosample,
            render: row => <Link to={cellTypeRoute(species, row.biosample)}>{row.biosample}</Link>,
        },
        {
            header: 'Date Released',
            value: row => row.released,
            render: row => {
                const d = new Date(row.released);
                return `${months[d.getMonth()]} ${d.getFullYear()}`;
            },
        },
        {
            header: 'Lab',
            value: row => row.lab.friendly_name,
        },
        {
            header: 'Replicated Peak File Accession',
            value: row => row.replicated_peaks[0].accession,
            render: row => (
                <a
                    href={`https://www.encodeproject.org/files/${row.replicated_peaks[0].accession}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {row.replicated_peaks[0].accession}
                </a>
            ),
        },
    ];
    if (withCellType) return r;
    return [r[0], ...r.slice(2)];
}

function biosampleColumns(species: string): DataTableColumn<BiosamplePartitionedDatasetCollection>[] {
    return [
        {
            header: '',
            value: row => row.biosample.name,
            render: row => <CtDetails hideFactorCounts row={row} species={species} celltype={row.biosample.name} />,
            sort: (a, b) => b.counts.targets - a.counts.targets,
        },
    ];
}

const Function: React.FC<FunctionPageProps> = props => {
    const client = useContext(ApiContext).client;
    let range: GenomicRange = { chromosome: '', start: 0, end: 0 };
    let modifiedFactor = '';
    let ncbi_data = '';
    let uniprot_data = '';
    let factor_wiki: string | undefined;
    let hgnc_data: HGNCData = { hgnc_id: '', location: '', locus_type: '', gene_group: [''], prev_name: [''] };
    let ensemble_data: EnsembleData = { id: '', biotype: '', description: '', ccds_id: [''], hgnc_primary_id: '' };
    const { data: FactorDesc } = useQuery<{ factor: FactorData[] }>(FACTOR_DESCRIPTION_QUERY, {
        client,
        variables: {
            assembly: props.assembly,
            name: [props.factor],
        },
    });
    const species = props.assembly !== 'mm10' ? 'human' : 'mouse';

    const tfRows = TFDetails(props.assembly, props.factor);
    const rawFactorData = FactorDesc && FactorDesc.factor[0];
    const skipModifiedFactorQuery = rawFactorData ? rawFactorData.modifications.modification == null : true;

    const { data: ModifiedTFDesc } = useQuery<{ factor: FactorData[] }>(FACTOR_DESCRIPTION_QUERY, {
        skip: skipModifiedFactorQuery,
        client,
        variables: {
            assembly: props.assembly,
            name: [rawFactorData ? rawFactorData.modifications.symbol : props.factor],
        },
    });

    const factorData = !rawFactorData
        ? false
        : rawFactorData.modifications.modification == null
        ? rawFactorData
        : ModifiedTFDesc && ModifiedTFDesc.factor[0];

    if (factorData) {
        range = factorData.coordinates!!;
        factor_wiki = factorData.factor_wiki;
        modifiedFactor =
            rawFactorData && rawFactorData.modifications.modification
                ? modificationsString(rawFactorData.modifications.modification)
                : '';
        ncbi_data = factorData.ncbi_data;
        uniprot_data = factorData.uniprot_data;
        if (factorData.hgnc_data) {
            hgnc_data = factorData.hgnc_data;
        }
        if (factorData.ensemble_data) {
            ensemble_data = factorData.ensemble_data;
        }
    }

    const PBID =
        factorData &&
        factorData.pdbids &&
        factorData.pdbids
            .split(',')[0]
            .split(':')[0]
            .toLowerCase();
    const RCSB_PDB_URL = !PBID
        ? undefined
        : `http://cdn.rcsb.org/images/structures/${PBID?.substring(1, 3)}/${PBID}/${PBID}_chain-A.jpeg`;

    const wikipediacard = factor_wiki && looksBiological(factor_wiki) && (
        <Segment raised>
            <Label color="blue" ribbon>
                Wikipedia
            </Label>
            <blockquote>{factor_wiki}</blockquote>
        </Segment>
    );
    const ncbicard = ncbi_data !== '' && (
        <Segment raised>
            <Label color="blue" ribbon>
                NCBI
            </Label>
            <blockquote>{ncbi_data}</blockquote>
        </Segment>
    );
    const uniprotcard = (
        <Segment raised>
            <Label color="blue" ribbon>
                Uniprot
            </Label>
            <blockquote>{uniprot_data}</blockquote>
        </Segment>
    );
    const hgnccard = hgnc_data.hgnc_id && (
        <Segment raised>
            <Label color="blue" ribbon>
                HGNC
            </Label>
            <blockquote>
                <Table stackable>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <b>HGNC ID</b>
                            </Table.Cell>
                            <Table.Cell>{ensemble_data.hgnc_primary_id}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>Locus Type</b>
                            </Table.Cell>
                            <Table.Cell>{hgnc_data.locus_type}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>Chromosomal Location</b>
                            </Table.Cell>
                            <Table.Cell>{hgnc_data.location}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>Gene groups</b>
                            </Table.Cell>
                            <Table.Cell>{hgnc_data.gene_group}</Table.Cell>
                        </Table.Row>
                        {hgnc_data.prev_name && (
                            <Table.Row>
                                <Table.Cell>
                                    <b>Previous Names</b>
                                </Table.Cell>
                                <Table.Cell>
                                    This gene is a member of the Human CCDS set:
                                    {' ' + hgnc_data.prev_name}
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </blockquote>
        </Segment>
    );
    const ensemblcard = (
        <Segment raised>
            <Label color="blue" ribbon>
                {'Ensembl'}
            </Label>
            <blockquote>
                <Table stackable>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <b>Gene Type</b>
                            </Table.Cell>
                            <Table.Cell>{ensemble_data.biotype}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>Description</b>
                            </Table.Cell>
                            <Table.Cell>{ensemble_data.description}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>Ensemble version</b>
                            </Table.Cell>
                            <Table.Cell>{ensemble_data.id}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <b>RefSeq</b>
                            </Table.Cell>
                            <Table.Cell>
                                This Ensembl/Gencode gene does not contain any transcripts for which we have
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={'http://useast.ensembl.org/info/genome/genebuild/mane.html'}
                                >
                                    {' selected identical model(s) in RefSeq.'}{' '}
                                </a>
                            </Table.Cell>
                        </Table.Row>
                        {ensemble_data.ccds_id && (
                            <Table.Row>
                                <Table.Cell>
                                    <b>CCDS</b>
                                </Table.Cell>
                                <Table.Cell>
                                    This gene is a member of the Human CCDS set:
                                    {' ' + ensemble_data.ccds_id}
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </blockquote>
        </Segment>
    );
    const experimentscard = !props.datasetsLoading && props.datasets && (
        <Segment raised>
            <Label color="blue" ribbon>
                {props.datasets.peakDataset.datasets.length} experiment
                {props.datasets.peakDataset.datasets.length !== 1 ? 's' : ''} performed
            </Label>
            <DataTable
                columns={datasetColumns(species)}
                rows={props.datasets.peakDataset.datasets}
                searchable
                itemsPerPage={5}
                sortColumn={2}
                sortDescending
            />
        </Segment>
    );
    const biosamplescard = !props.datasetsLoading && props.datasets && (
        <Segment raised>
            <Label color="blue" ribbon>
                {props.datasets.peakDataset.partitionByBiosample.length} biosamples profiled
            </Label>
            <DataTable
                columns={biosampleColumns(species)}
                rows={props.datasets.peakDataset.partitionByBiosample}
                searchable
                itemsPerPage={4}
                sortColumn={0}
            />
        </Segment>
    );
    return (
        <Grid>
            <Grid.Column width={3}>
                <React.Fragment>
                    <Card>
                        {!factorData ? (
                            <Placeholder>
                                <Placeholder.Image square />
                            </Placeholder>
                        ) : (
                            RCSB_PDB_URL && <Image src={RCSB_PDB_URL} wrapped ui={false} />
                        )}
                        <Card.Content>
                            <Card.Header>{formatFactorName(props.factor, species)}</Card.Header>
                            <Card.Meta>
                                { props.label ? <>{props.label.replace(/ -/g, "")}<br /></> : "" }
                                <React.Fragment>
                                    {range.chromosome +
                                        ':' +
                                        range.start!.toLocaleString() +
                                        '-' +
                                        range.end!.toLocaleString()}
                                    <br />
                                    {modifiedFactor}
                                </React.Fragment>
                            </Card.Meta>
                            <Card.Description>
                                <Table celled>
                                    <Table.Body>
                                        {tfRows.map((row, i) => (
                                            <Table.Row key={i}>
                                                {TFDETAILS_COLUMNS.map((column, j) => (
                                                    <Table.Cell key={`${column.header}${i}_${j}`} textAlign="center">
                                                        {column.render ? column.render(row) : column.value(row)}
                                                    </Table.Cell>
                                                ))}
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </React.Fragment>
            </Grid.Column>

            <Grid.Column
                width={10}
                style={{
                    maxHeight: 'calc(100vh - 135px)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div style={{ overflowY: 'auto', paddingLeft: '13px' }}>
                    {ncbicard}
                    {uniprotcard}
                    {wikipediacard}
                    {hgnccard}
                    {ensemblcard}
                    {experimentscard}
                    {biosamplescard}
                </div>
            </Grid.Column>
        </Grid>
    );
};

export default Function;
