import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { ApiContext } from 'apicontext';

import { datasetCountQuery } from '../queries';
import { DatasetCountQueryResult, CountResult } from '../types';

export type SpeciesHeaderProps = {
    assembly: string;
    component: React.SFC<HeaderProps>;
};

export type HeaderProps = {
    counts?: CountResult;
};

const SpeciesHeader: React.FC<React.PropsWithChildren<SpeciesHeaderProps>> = props => {
    const client = useContext(ApiContext).client;
    const result = useQuery<DatasetCountQueryResult>(datasetCountQuery, {
        client,
        variables: {
            assembly: props.assembly,
        },
    });
    const Component = props.component;

    return <Component counts={result && result.data && result.data.counts && result.data.counts.counts} />;
};
export default SpeciesHeader;
