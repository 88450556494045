import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { Button, Checkbox, Container, Divider, Dropdown, Icon, Loader, Menu, Message } from 'semantic-ui-react';
import { useSNPData } from './hooks';
import MotifIntersectionView from './MotifIntersectionView';
import PeakIntersectionView from './PeakIntersectionView';
import SNPSearchBox from './SNPSearchBox';
import { ChainFile, loadChainFile } from "liftover";
import { inflate } from 'pako';

export const POPULATIONS = [
    { text: 'African', value: 'AFRICAN' },
    { text: 'Native American', value: 'AMERICAN' },
    { text: 'East Asian', value: 'EAST_ASIAN' },
    { text: 'European', value: 'EUROPEAN' },
    { text: 'South Asian', value: 'SOUTH_ASIAN' },
];

const NONE = { text: '(none)', value: 'NONE' };

export const SUBPOPULATIONS: Map<string, { text: string; value: string }[]> = new Map([
    [
        'AFRICAN',
        [
            NONE,
            { text: 'Gambian', value: 'GAMBIAN' },
            { text: 'Mende', value: 'MENDE' },
            { text: 'Easn', value: 'ESAN' },
            { text: 'African American', value: 'AFRICAN_AMERICAN' },
            { text: 'African Caribbean', value: 'AFRICAN_CARIBBEAN' },
        ],
    ],
    [
        'AMERICAN',
        [
            NONE,
            { text: 'Mexican', value: 'MEXICAN' },
            { text: 'Puerto Rican', value: 'PUERTO_RICAN' },
            { text: 'Colombian', value: 'COLOMBIAN' },
            { text: 'Peruvian', value: 'PERUVIAN' },
            { text: 'Southern Han Chinese', value: 'SOUTHERN_HAN_CHINESE' },
        ],
    ],
    [
        'EAST_ASIAN',
        [
            NONE,
            { text: 'Han Chinese from Beijing', value: 'HAN_CHINESE_BEIJING' },
            { text: 'Japanese', value: 'JAPANESE' },
            { text: 'Dai', value: 'DAI' },
            { text: 'Kinh', value: 'KINH' },
            { text: 'Southern Han Chinese', value: 'SOUTHERN_HAN_CHINESE' },
        ],
    ],
    [
        'EUROPEAN',
        [
            NONE,
            { text: 'Iberian', value: 'IBERIAN' },
            { text: 'British', value: 'BRITISH' },
            { text: 'Finnish', value: 'FINNISH' },
            { text: 'Toscani', value: 'TOSCANI' },
            { text: 'Utah resident northwest European', value: 'UTAH_RESIDENT_NW_EUROPEAN' },
        ],
    ],
    [
        'SOUTH_ASIAN',
        [
            NONE,
            { text: 'Gujarati', value: 'GUJARATI' },
            { text: 'Punjabi', value: 'PUNJABI' },
            { text: 'Bengali', value: 'BENGALI' },
            { text: 'Sri Lankan Tamil', value: 'SRI_LANKAN_TAMIL' },
            { text: 'Indian Telugu', value: 'INDIAN_TELUGU' },
        ],
    ],
]);

const POPULATION_MAP = new Map(POPULATIONS.map(x => [x.value, x.text]));
const SUBPOPULATION_MAP = new Map(
    ['AFRICAN', 'AMERICAN', 'EAST_ASIAN', 'EUROPEAN', 'SOUTH_ASIAN'].map(x => [
        x,
        new Map(SUBPOPULATIONS.get(x)!.map(xx => [xx.value, xx.text])),
    ])
);

export const SNPAnnotationPageRoot: React.FC = () => {

    const [ loadingChains, setLoadingChains ] = useState(false);
    const [ chainFile, setChainFile ] = useState<ChainFile | null>(null);
    
    /* load the chain file to lift coordinates up to hg38 or mm10 if necessary */
    useEffect( () => {
        setLoadingChains(true);
        fetch("https://hgdownload.cse.ucsc.edu/goldenpath/hg38/liftOver/hg38ToHg19.over.chain.gz")
            .then(x => x.blob())
            .then(x => x.arrayBuffer())
            .then(x => new TextDecoder().decode(inflate(new Uint8Array(x))))
            .then(x => {
                setChainFile(loadChainFile(x));
                setLoadingChains(false);
            });
    }, []);

    return loadingChains || !chainFile ? <Loader active>Loading...</Loader> : <SNPAnnotationPage chainFile={chainFile} />;

}

const SNPAnnotationPage: React.FC<{ chainFile: ChainFile }> = props => {
    const { assembly } = useParams<{ assembly: string }>();

    const match = useRouteMatch<{ i?: string, p?: string, s?: string, r?: string }>(`/snpannotation/hg38/:i?/:p?/:r?/:s?`);
    
    const [ page, setPage ] = useState(-1);
    const [id, setid] = useState(match?.params.i);
    const [ld, setLD] = useState(match?.params.p !== undefined);
    const [population, setPopulation] = useState(match?.params.p || "AFRICAN");
    const [subpopulation, setSubpopulation] = useState(match?.params.s || "NONE");
    const [rSquaredThreshold, setRSquaredThreshold] = useState(+(match?.params.r || 0.7));

    const { data, loading, mafResults } = useSNPData(id || "", assembly, population, subpopulation, props.chainFile);
    const snps = useMemo(
        () =>
            data === undefined || data.snpQuery[0] === undefined
                ? []
                : ld
                ? [
                      { ...data.snpQuery[0], rSquared: 1.0, minorAlleleFrequency: mafResults?.get(data.snpQuery[0]?.id)?.minorAlleles || [], refAllele: mafResults?.get(data.snpQuery[0].id)?.refAllele || "", refFrequency: mafResults?.get(data.snpQuery[0].id)?.refFrequency || 0 },
                      ...data.snpQuery[0].linkageDisequilibrium
                          .filter(x => x.rSquared > rSquaredThreshold)
                          .map(x => ({ ...x.snp, rSquared: x.rSquared, minorAlleleFrequency: mafResults?.get(data.snpQuery[0]?.id)?.minorAlleles || [], refAllele: mafResults?.get(x.snp.id)?.refAllele || "", refFrequency: mafResults?.get(x.snp.id)?.refFrequency || 0 })),
                  ]
                : [{ ...data.snpQuery[0], rSquared: 1.0, refAllele: mafResults?.get(data.snpQuery[0].id)?.refAllele || "", minorAlleleFrequency: mafResults?.get(data.snpQuery[0]?.id)?.minorAlleles || [], refFrequency: mafResults?.get(data.snpQuery[0].id)?.refFrequency || 0}],
        [data, rSquaredThreshold, ld, mafResults]
    );

    return (
        <Container style={{ marginTop: '7em' }}>
            {id === '' || id === undefined ? (
                <>
                    <h2>SNP Annotation</h2>
                    <p style={{ width: '80%', fontSize: '1.1em' }}>
                        This page allows you to annotate SNPs with candidate function based on their overlap with ENCODE
                        TF ChIP-seq peaks and consensus motifs. To get started, enter a SNP's rsID:
                    </p>
                    <SNPSearchBox assembly={assembly} onSearchEnter={setid} />
                    <Divider style={{ marginTop: '5em', marginBottom: '3em' }} />
                    <h3>
                        <Icon name="settings" /> Linkage Disequilibrium settings
                    </h3>
                    <Checkbox
                        onClick={() => setLD(!ld)}
                        checked={ld}
                        label="Include SNPs in linkage disequilibrium with the query"
                        style={{ marginBottom: '1.1em' }}
                    />
                    <br />
                    {ld && (
                        <>
                            <strong style={{ fontSize: '1.1em', marginRight: '1.1em' }}>Select a Population:</strong>
                            <Dropdown
                                options={POPULATIONS}
                                defaultValue={population}
                                onChange={(_, { value }) => {
                                    setPopulation(value as string);
                                    setSubpopulation('NONE');
                                }}
                            />
                            <br />
                            {SUBPOPULATIONS.get(population) && (
                                <>
                                    <strong style={{ fontSize: '1.1em', marginRight: '1.1em' }}>
                                        Select a Subpopulation:
                                    </strong>
                                    <Dropdown
                                        key={population}
                                        options={SUBPOPULATIONS.get(population)}
                                        defaultValue={subpopulation}
                                        onChange={(_, { value }) => setSubpopulation(value as string)}
                                    />
                                    <br />
                                </>
                            )}
                            <strong style={{ fontSize: '1.1em', marginRight: '1.1em' }}>
                                r<sup>2</sup> threshold:
                            </strong>
                            <input
                                onChange={e => setRSquaredThreshold(+e.target.value)}
                                defaultValue={rSquaredThreshold}
                            />
                            <br />
                            <br />
                            <em>
                                LD data is derived from the{' '}
                                <a href="https://www.internationalgenome.org/">1,000 Genomes Project</a>.
                            </em>
                        </>
                    )}
                </>
            ) : loading ? (
                <Loader active>Loading...</Loader>
            ) : (
                <>
                    <h2>ENCODE TF annotations for {id}</h2>
                    {ld && (
                        <em>
                            Including SNPs in LD in the {POPULATION_MAP.get(population)} population,
                            {subpopulation !== 'NONE' && (
                                <>&nbsp;{SUBPOPULATION_MAP.get(population)!.get(subpopulation)} subpopulation,</>
                            )}
                            &nbsp;r<sup>2</sup> &gt;
                            {rSquaredThreshold}
                        </em>
                    )}
                    <br />
                    <Button
                        onClick={() => {
                            setid('');
                            setPage(-1);
                        }}
                        icon="left arrow"
                        labelPosition="left"
                        size="small"
                        content="Perform a New Search"
                    />
                    <Divider style={{ marginBottom: '2em' }} />
                    {page === -1 ? <Message info>{snps.length} SNPs matched your query.</Message> : ''}
                    <Menu secondary>
                        <Menu.Item>
                            <strong>Select an annotation:</strong>
                        </Menu.Item>
                        <Menu.Item onClick={() => setPage(0)} active={page === 0}>
                            Peak Intersection
                        </Menu.Item>
                        <Menu.Item onClick={() => setPage(1)} active={page === 1}>
                            Motif Intersection
                        </Menu.Item>
                    </Menu>
                    {page === 0 ? (
                        <PeakIntersectionView snps={snps} assembly={assembly === 'hg38' ? 'GRCh38' : assembly} />
                    ) : page === 1 ? (
                        <MotifIntersectionView snps={snps} assembly={assembly} />
                    ) : null}
                </>
            )}
        </Container>
    );
};
export default SNPAnnotationPage;
