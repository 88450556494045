import React, { useEffect, useMemo, useCallback } from 'react';
import { Grid, Icon, Message } from 'semantic-ui-react';
import ExperimentSelection from '../../../common/experimentselection';
import Motifs from './motifs';
import { MotifEnrichmentProps } from './types';
import { useRouteMatch, useHistory } from 'react-router';

const MotifEnrichment: React.FC<MotifEnrichmentProps> = ({ data, factor, methyl, sequenceSpecific }) => {
    const sortedData = useMemo(() => data.slice().sort((a, b) => (a.biosample.name > b.biosample.name ? 1 : -1)), [
        data,
    ]);

    const history = useHistory();
    const match = useRouteMatch<{ species: string; dataset?: string }>('/tf/:species/:factor/motif/:dataset?');

    const selectExpAccession = useCallback(
        (accesion: string) => history.push(`/tf/${match?.params.species}/${factor}/motif/${accesion}`),
        [match, factor, history]
    );

    useEffect(() => {
        if (!match?.params.dataset) selectExpAccession(sortedData[0].datasets![0].accession);
    }, [match, selectExpAccession, sortedData]);

    const [selectedCelltype, exp] = useMemo(() => {
        if (!match?.params.dataset) return [sortedData[0].biosample.name, sortedData[0].datasets![0]];
        for (let collection of data)
            for (let dataset of collection.datasets!)
                if (dataset.accession === match.params.dataset) return [collection.biosample.name, dataset];
        return [sortedData[0].biosample.name, sortedData[0].datasets![0]];
    }, [data, match, sortedData]);

    const selectCelltype = useCallback(
        (ct: string) => {
            const collection = data.find(d => d.biosample.name === ct)!;
            selectExpAccession(collection.datasets![0].accession);
        },
        [data, selectExpAccession]
    );

    return (
        <>
            <Grid style={{ width: '100%' }}>
                <Grid.Column width={3} style={{ maxHeight: 'calc(100vh - 135px)', minHeight: '50vh' }}>
                    <ExperimentSelection
                        data={sortedData}
                        activeCelltype={selectedCelltype}
                        activeExp={exp.accession}
                        selectCelltype={selectCelltype}
                        selectExp={selectExpAccession}
                    />
                </Grid.Column>
                <Grid.Column
                    width={13}
                >
                    <h1>
                        {methyl ? 'Methyl ' : ''}
                        <em>De novo</em> motif discovery in {selectedCelltype} ({exp.accession}) by MEME
                    </h1>
                    { sequenceSpecific === false && (
                        <Message warning>
                            <Icon name="warning" /><strong>{factor} has been annotated by external resources as a factor without sequence specficity.</strong>
                            &nbsp;Motifs presented on this page are enriched within peaks for {factor} but may not be recognized by {factor} itself. Instead,
                            they are likely to be recognized by co-factors that co-occupy {factor} binding sites.
                        </Message>
                    )}
                    <div
                        style={{
                            maxHeight: 'calc(100vh - 255px)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                        }}
                    >
                        <Motifs peaks_accession={exp.replicated_peaks[0].accession} eacc={match?.params.dataset} methyl={methyl} ct={selectedCelltype} />
                        <br />
                    </div>
                </Grid.Column>
            </Grid>
        </>
    );
};
export default MotifEnrichment;
