import React, { useState, useEffect } from 'react';

import ProgressModal from './progress';
import { UploadSegment } from '../../searchbyregions/upload';
import { memeTxtToMotifs } from '../utilities';
import { FileUploaderProps } from './types';
import { ParsedMotifFile, ParsedMotif } from '../types';

const FileUploader: React.FC<FileUploaderProps> = ({ onMotifsParsed }) => {
    const [parsedFiles, setParsedFiles] = useState<ParsedMotifFile[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<FileList | null>(null);
    const [parsingPointer, setParsingPointer] = useState(0);

    const setNewUpload = (files: FileList | null) => {
        setParsedFiles([]);
        setUploadedFiles(files);
        setParsingPointer(0);
    };

    const setResult = (motifs: ParsedMotif[]) => {
        setParsedFiles([
            ...parsedFiles,
            {
                file: uploadedFiles![parsingPointer],
                motifs,
            },
        ]);
        setParsingPointer(parsingPointer + 1);
    };

    const setError = (error: any) => {
        setParsedFiles([
            ...parsedFiles,
            {
                file: uploadedFiles![parsingPointer],
                error,
            },
        ]);
        setParsingPointer(parsingPointer + 1);
    };

    useEffect(() => {
        if (uploadedFiles === null) return;
        if (parsingPointer === uploadedFiles.length) {
            setNewUpload(null);
            return onMotifsParsed(parsedFiles);
        }
        if (uploadedFiles[parsingPointer].size > 100000000) return setError('File too large; limit is 100 MB');

        const reader = new FileReader();
        reader.onload = () => {
            try {
                setResult(memeTxtToMotifs(reader.result));
            } catch (e) {
                setError(e);
            }
        };
        reader.readAsText(uploadedFiles[parsingPointer]);
    });

    return !uploadedFiles || parsingPointer === uploadedFiles.length ? (
        <UploadSegment fileType="MEME" onFileReceived={setNewUpload} />
    ) : (
        <ProgressModal index={parsingPointer} total={uploadedFiles.length} />
    );
};
export default FileUploader;
