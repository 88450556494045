import React from 'react';
import { Message } from 'semantic-ui-react';
import { TOMTOMMessageProps } from './types';

export const TOMTOMMessage: React.FC<TOMTOMMessageProps> = ({ tomtomMatch }) => (
    <Message info style={{ marginTop: '0px' }}>
        {tomtomMatch ? (
            <>
                <strong>Best external database match:</strong>
                <br />
                {tomtomMatch.jaspar_name ? (
                    <a
                        href={`http://jaspar2020.genereg.net/matrix/${tomtomMatch.target_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {tomtomMatch.target_id}/{tomtomMatch.jaspar_name} (JASPAR)
                    </a>
                ) : (
                    <a
                        href={`https://hocomoco11.autosome.ru/motif/${tomtomMatch.target_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {tomtomMatch.target_id} (HOCOMOCO)
                    </a>
                )}
            </>
        ) : (
            '(no external database match)'
        )}
    </Message>
);
