import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Image } from 'semantic-ui-react';

import { useQuery } from '@apollo/client';

import { ApiContext } from 'apicontext';
import { FACTOR_DESCRIPTION_QUERY } from './queries';
import { formatFactorName } from '../../utilities/misc';
import { tfRoute } from '../../routing';
import { TFDetailsProps, FactorData } from './types';

const TfDetails: React.FC<TFDetailsProps> = props => {
    const assembly = props.species === 'human' ? 'GRCh38' : 'mm10';
    const client = useContext(ApiContext).client;
    const { data: FactorDesc } = useQuery<{ factor: FactorData[] }>(FACTOR_DESCRIPTION_QUERY, {
        client,
        variables: {
            assembly,
            name: [props.factor],
        },
    });
    const rawFactorData = FactorDesc && FactorDesc.factor[0];
    const skipModifiedFactorQuery = rawFactorData ? rawFactorData.modifications.modification == null : true;

    const { data: ModifiedTFDesc } = useQuery<{ factor: FactorData[] }>(FACTOR_DESCRIPTION_QUERY, {
        skip: skipModifiedFactorQuery,
        client,
        variables: {
            assembly: assembly,
            name: [rawFactorData ? rawFactorData.modifications.symbol : props.factor],
        },
    });
    const factorData = !rawFactorData
        ? false
        : rawFactorData.modifications.modification == null
        ? rawFactorData
        : ModifiedTFDesc && ModifiedTFDesc.factor[0];
    const PBID =
        factorData &&
        factorData.pdbids &&
        factorData.pdbids
            .split(',')[0]
            .split(':')[0]
            .toLowerCase();
    const RCSB_PDB_URL =
        PBID === false || PBID === ''
            ? undefined
            : `http://cdn.rcsb.org/images/structures/${PBID?.substring(1, 3)}/${PBID}/${PBID}_chain-A.jpeg`;

    return (
        <Grid>
            <Grid.Row columns={3}>
                <Grid.Column width={5}>
                    {' '}
                    <Link to={tfRoute(props.species, props.row.target.name)}>
                        {' '}
                        <h3>{formatFactorName(props.row.target.name, props.species)}</h3>
                    </Link>
                    <br />
                    {props.label ? <>{props.label.replace(/ -/g, "")}<br /></> : ""}
                    {props.row.counts.total} Experiments
                    {!props.hideCellTypeCounts && (
                        <>
                            <br />
                            {props.row.counts.biosamples} Cell Types
                        </>
                    )}
                </Grid.Column>
                <Grid.Column width={2}>
                    {factorData && RCSB_PDB_URL && <Image width={85} src={RCSB_PDB_URL} ui={false} />}
                </Grid.Column>
                {factorData && factorData.factor_wiki && (
                    <Grid.Column width={9}>{factorData.factor_wiki.split('.')[0]}.</Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    );
};
export default TfDetails;
