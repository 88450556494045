import React, { useState } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { Pagination, Icon } from 'semantic-ui-react';

import { RegexSearchResultProps } from './types';
import { MotifSearchResultSet } from './motifresults';
import { regexToPWM } from '../utilities';
import { motifSearchRoute } from 'routing';

const MOTIFS_PER_PAGE = 3;

const RegexSearchResults: React.FC<RegexSearchResultProps> = ({ assembly }) => {
    const history = useHistory();
    const { species, regex, page } = useParams<{ species: string; regex: string; page?: string }>();
    const [total, setTotal] = useState(0);

    if (page === undefined || +page < 1) return <Redirect to={motifSearchRoute(species, 'regex', regex, '1')} />;

    return (
        <>
            <MotifSearchResultSet
                species={species}
                assembly={assembly}
                pwm={regexToPWM(regex)}
                offset={+(page || '1') - 1}
                onResultsLoaded={setTotal}
            />
            {total > 0 && (
                <Pagination
                    activePage={+(page || '1')}
                    onPageChange={(_: any, v: any) => {
                        history.push(motifSearchRoute(species, 'regex', regex, v.activePage));
                    }}
                    totalPages={Math.ceil(total / MOTIFS_PER_PAGE)}
                    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
            )}
        </>
    );
};
export default RegexSearchResults;
