import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Grid, Header, Divider, Icon, Loader, Button } from 'semantic-ui-react';
import { MultiXLineChart, LegendEntry } from 'jubilant-carnival';
import JSZip from 'jszip';

import { useAggregateData, useHistoneMetadata } from './hooks';
import { GraphProps, GraphSetProps, CollapsibleGraphsetProps } from './types';
import { MARK_COLORS, MARK_TYPES, MARK_TYPE_ORDER } from './marks';
import { associateBy, groupBy } from 'queryz';
import { shadeHexColor } from 'utilities/misc';
import { downloadBlob, svgDataE } from '../tf/geneexpression/utils';

export const Graph: React.FC<GraphProps> = ({
    proximal_values,
    distal_values,
    dataset,
    is_forward_reverse,
    title,
    limit,
    xlabel,
    ylabel,
    height,
    yMax,
    children,
    padBottom,
    hideTitle,
    sref,
    lref,
    yMin,
    is_stranded_motif,
    semiTransparent
}) => {
    const max = useMemo(() => Math.max(...proximal_values, ...distal_values, yMax || 5), [
        distal_values,
        proximal_values,
        yMax,
    ]);
    const min = useMemo(() => yMin || Math.min(...proximal_values, ...distal_values, 0), [distal_values, proximal_values]);
    const range = max - min;
    const color = MARK_COLORS[dataset.target] || '#000000';
    const darkColor = useMemo(() => shadeHexColor(color, -0.4), [color]);

    return (
        <>
            {!hideTitle && (
                <Header
                    as="h4"
                    style={{
                        textAlign: 'center',
                        marginBottom: dataset && dataset.target === 'PhyloP 100-way' ? '0em' : '-1em',
                    }}
                >
                    {title || dataset.target}
                </Header>
            )}
            <MultiXLineChart
                xDomain={{ start: -(limit || 2000), end: limit || 2000 }}
                yDomain={{ start: padBottom ? min - range / 5 : min, end: max + range / 5 }}
                lineProps={{ strokeWidth: 4, strokeOpacity: semiTransparent ? 0.6 : 1.0 }}
                data={[
                    { data: proximal_values, label: 'proximal', color },
                    { data: distal_values, label: 'distal', color: darkColor },
                ]}
                innerSize={{ width: 400, height: height || 280 }}
                xAxisProps={{ fontSize: 15, title: xlabel || 'distance from summit (bp)' }}
                yAxisProps={{ fontSize: 12, title: ylabel || 'fold change signal' }}
                plotAreaProps={{ withGuideLines: true, guideLineProps: { hideHorizontal: true } }}
                legendProps={{
                    size: { width: 135, height: 55 },
                    headerProps: { numberFormat: (x: number) => Math.round(x).toString() },
                }}
                ref={sref}
            >
                {children}
            </MultiXLineChart>
            <svg viewBox="0 0 400 40" style={{ marginTop: '-0.4em' }} ref={lref}>
                <g transform="translate(95,0)">
                    <LegendEntry
                        label={{ color, label: is_forward_reverse ? 'motif strand' : is_stranded_motif ? '(+) strand motif' : 'TSS-proximal', value: '' }}
                        simple
                        size={{ width: 150, height: 25 }}
                        rectProps={{ height: 7, y: 9 }}
                    />
                </g>
                <g transform="translate(210,0)">
                    <LegendEntry
                        label={{
                            color: darkColor,
                            label: is_forward_reverse ? 'complement strand' : is_stranded_motif ? '(-) strand motif' : 'TSS-distal',
                            value: '',
                        }}
                        simple
                        size={{ width: 150, height: 25 }}
                        rectProps={{ height: 7, y: 9 }}
                    />
                </g>
            </svg>
        </>
    );
};

const CollapsibleGraphSet: React.FC<CollapsibleGraphsetProps> = ({ title, graphs }) => {
    const [collapsed, setCollapsed] = useState(title !== 'Activating histone marks');
    const refs = useRef<(SVGSVGElement | null)[]>(graphs.map(() => null));
    const lrefs = useRef<(SVGSVGElement | null)[]>(graphs.map(() => null));
    const download = useCallback( () => {
        const z = new JSZip();
        console.log(refs.current);
        graphs.forEach( (g, i) => {
            z.file(`${g.dataset.target}.svg`, (refs.current && refs.current[i] && svgDataE([ refs.current[i] as SVGSVGElement, lrefs.current[i] as SVGSVGElement ], [ [ 0, g.height || 0 ] ])) || "<svg></svg>");
        });
        z.generateAsync({type: "blob"}).then(c => downloadBlob(c, "histone-aggregate-plots.zip"));
    }, [ graphs, refs ]);

    return (
        <>
            <Header
                as="h3"
                onClick={() => {
                    setCollapsed(!collapsed);
                }}
            >
                {title}&nbsp;
                <Icon name={collapsed ? 'angle right' : 'angle down'} />
            </Header>
            <Divider />
            <Grid>
                {!collapsed &&
                    graphs.map((graph, i) => (
                        <Grid.Column width={4} key={i}>
                            <Graph lref={e => lrefs.current[i] = e} sref={e => refs.current[i] = e} {...graph} />
                        </Grid.Column>
                    ))}
            </Grid>
            {!collapsed && (
                <Button onClick={download}>Export Plots as SVG</Button>
            )}
        </>
    );
};

const GraphSet: React.FC<GraphSetProps> = ({ tfAccession }) => {
    const { data, loading } = useAggregateData(tfAccession);
    const metadata = useHistoneMetadata(
        data ? data.histone_aggregate_values.map((x: any) => x.histone_dataset_accession) : [],
        loading
    );
    if (loading || !data || metadata.loading || !metadata.data) return <Loader active>Loading...</Loader>;

    const values = associateBy(
        data.histone_aggregate_values,
        (x: any) => x.histone_dataset_accession,
        x => x
    );
    const marks = associateBy(
        metadata.data.peakDataset.datasets,
        (x: any) => x.target,
        x => x
    );
    const typeGroups = groupBy(
        [...marks.keys()],
        x => MARK_TYPES[x],
        x => ({
            dataset: marks.get(x)!,
            proximal_values: values.get(marks.get(x)!.accession)!.proximal_values,
            distal_values: values.get(marks.get(x)!.accession)!.distal_values,
        })
    );

    return (
        <div style={{ marginTop: '2em' }}>
            {MARK_TYPE_ORDER.filter(type => typeGroups.get(type)).map(type => (
                <CollapsibleGraphSet title={type} graphs={typeGroups.get(type)!} />
            ))}
        </div>
    );
};
export default GraphSet;
