import React, { useState, useRef, useCallback, useMemo, useContext } from 'react';
import {
    Grid,
    Icon,
    Menu,
    Divider,
    Container,
    Modal,
    Header,
    Button,
    Input,
    Table,
    Popup,
    Loader,
} from 'semantic-ui-react';
import { downloadSVG } from 'utilities/svgdata';
import { Logo, DNAAlphabet } from 'logojs-react';
import { MultiRegionSearch } from 'genomic-file-upload';
import { GenomicRange } from 'components/types';
import DeepLearnedMotifOccurrencesDataTable from './deeplearnedmotifoccurrencedatatable';
import CopyToClipboardButton from 'components/copytoclipboardbutton';
import { ApiContext } from 'apicontext';
import { DEEP_LEARNED_MOTIFS_NONSELEX_QUERY } from './queries';
import { DeepLearnedMotifsQueryResponse } from './types';
import { useQuery } from '@apollo/client';

DNAAlphabet[0].color = "#228b22";
DNAAlphabet[3].color = "red";

export const reverseComplement = (ppm: number[][]): number[][] =>
    ppm && ppm[0] && ppm[0].length === 4
        ? ppm.map(inner => inner.slice().reverse()).reverse()
        : ppm.map(entry => [entry[3], entry[2], entry[1], entry[0], entry[5], entry[4]]).reverse();

function logLikelihood(backgroundFrequencies: number[]): (r: number[]) => number[] {
    return (r: number[]): number[] => {
        let sum = 0.0;
        r.map((x, i) => (sum += x === 0 ? 0 : x * Math.log2(x / (backgroundFrequencies[i] || 0.01))));
        return r.map(x => x * sum);
    };
}

const DeepLearnedNonSELEXMotifs: React.FC<{
    factor: string;
    species: string;
}> = ({ factor, species }) => {
    const client = useContext(ApiContext).client;

    const { data, loading } = useQuery<DeepLearnedMotifsQueryResponse>(DEEP_LEARNED_MOTIFS_NONSELEX_QUERY, {
        client,
        variables: {
            tf: factor,
            accession: factor,
            species,
        },
    });
    if (loading || !data)
        return (
            <Loader style={{ marginTop: '10em' }} active>
                Loading...
            </Loader>
        );

    return <DeepLearnedNonSELEXMotif factor={factor} data={data.deep_learned_motifs} />;
};

const DeepLearnedNonSELEXMotif: React.FC<{
    factor: string;
    data: {
        tf: string;
        ppm: number[][];
        consensus_regex?: string;
        total_number_of_datasets_instance_found_in?: number;
        total_number_of_celltypes_instance_found_in?: number;
    }[];
}> = ({ factor, data }) => {
    const [regions, setRegions] = useState<GenomicRange[]>([]);
    const [isReverseComplement, setReverseComplement] = useState(false);
    const [isConsensusSeqModalOpen, setConsensusSeqModalOpen] = useState(false);
    const svg = useRef<any>(null);
    const toggleReverseComplement = useCallback(() => setReverseComplement(!isReverseComplement), [
        isReverseComplement,
    ]);
    const ppm = isReverseComplement ? reverseComplement(data[0].ppm) : data[0].ppm;
    const logoWidth = (ppm.length * 95) / 30;
    const backgroundFrequencies = ppm[0].map(_ => 1.0 / ppm[0].length);
    const ll = useMemo(() => logLikelihood(backgroundFrequencies), [backgroundFrequencies]);
    const pwm = useMemo(() => ppm.map(ll), [ppm, ll]);
    const yMax = Math.max(...pwm.map(xx => xx.reduce((x, v) => x + v, 0.0)));
    return (
        <>
            <Grid style={{ overflowX: 'hidden' }}>
                <Grid.Row style={{ paddingBottom: '0px', marginTop: '0.5em' }} textAlign="center">
                    <Grid.Column width={3} />
                    <Grid.Column width={6} textAlign="center">
                        <div>
                            <Logo
                                yAxisMax={yMax}
                                backgroundFrequencies={backgroundFrequencies}
                                alphabet={DNAAlphabet}
                                ppm={ppm}
                                ref={svg}
                                width={`${logoWidth}%`}
                            />
                            <br />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={2} style={{ marginLeft: '1em' }} verticalAlign="middle">
                        <Table>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell
                                        style={{
                                            width: '5px',
                                            padding: '0.5em',
                                            paddingRight: '0px',
                                            paddingLeft: '0.5em',
                                        }}
                                    >
                                        <Popup trigger={<Icon name="help circle" />}>
                                            <p>Number of datasets deep learned motif found in</p>
                                        </Popup>
                                    </Table.Cell>
                                    <Table.Cell style={{ padding: '0.5em' }}>
                                        Identified by {data[0].total_number_of_datasets_instance_found_in} input
                                        datasets
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell
                                        style={{
                                            width: '5px',
                                            padding: '0.5em',
                                            paddingRight: '0px',
                                            paddingLeft: '0.5em',
                                        }}
                                    >
                                        <Popup trigger={<Icon name="help circle" />}>
                                            <p>Number of celltypes deep learned motif found in</p>
                                        </Popup>
                                    </Table.Cell>
                                    <Table.Cell style={{ padding: '0.5em' }}>
                                        Identified by {data[0].total_number_of_celltypes_instance_found_in} input
                                        celltypes
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingTop: '0px' }}>
                    <Grid.Column width={5} />
                    <Grid.Column width={4} textAlign="center">
                        <Menu secondary>
                            <Menu.Item link onClick={() => downloadSVG(svg, 'logo.svg')}>
                                <Icon name="download" /> export logo
                            </Menu.Item>
                            <Menu.Item link onClick={toggleReverseComplement}>
                                <Icon name="exchange" /> reverse complement
                            </Menu.Item>
                            <Menu.Item link onClick={() => setConsensusSeqModalOpen(true)}>
                                <Icon name="search" /> show consensus sequence
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>

                    <Modal open={isConsensusSeqModalOpen} onClose={() => setConsensusSeqModalOpen(false)} size="small">
                        <Header>Consensus sequence</Header>
                        <Modal.Content>
                            <Input value={data[0].consensus_regex} spellCheck={false} style={{ width: '80%' }} />
                            &nbsp;
                            <CopyToClipboardButton text={data[0].consensus_regex} />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color="green" onClick={() => setConsensusSeqModalOpen(false)}>
                                Done
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Grid.Row>
            </Grid>
            <Divider />
            <div style={{ marginLeft: '3%', marginTop: '2em' }}>
                <Container>
                    <MultiRegionSearch
                        searchInstructions={`Enter genomic coordinates:`}
                        title={`Searching Deep Learned Motif Occurrences for ${factor}`}
                        onSelectionChanged={setRegions}
                    />
                    {regions && regions.length > 0 && (
                        <>
                            <Header>Deep Learned Motif Occurrences for {factor}</Header>
                            <DeepLearnedMotifOccurrencesDataTable factor={factor} regions={regions} />
                        </>
                    )}
                </Container>
            </div>
        </>
    );
};
export default DeepLearnedNonSELEXMotifs;
