export const aminoAcidCodes: { [code: string]: string } = {
    A: 'alanine',
    C: 'cysteine',
    D: 'aspartic acid',
    E: 'glutamic acid',
    F: 'phenylalanine',
    G: 'glycine',
    H: 'histidine',
    I: 'isoleucine',
    K: 'lysine',
    L: 'leucine',
    M: 'methionine',
    N: 'asparagine',
    P: 'proline',
    Q: 'glutamine',
    R: 'arginine',
    S: 'serine',
    T: 'threonine',
    V: 'valine',
    W: 'tryptophan',
    Y: 'tyrosine',
};

export function ordinal(i: number): string {
    const j: number = i % 10;
    const k: number = i % 100;
    if (j === 1 && k !== 11) return i + 'st';
    if (j === 2 && k !== 12) return i + 'nd';
    if (j === 3 && k !== 13) return i + 'rd';
    return i + 'th';
}

export type FactorModification = {
    position: number;
    modification: string;
    amino_acid_code: string;
};

export function modificationString(modification: FactorModification): string {
    return `${modification.modification.toLowerCase()} at ${ordinal(modification.position)} ${
        aminoAcidCodes[modification.amino_acid_code]
    }`;
}

export function modificationsString(modifications: FactorModification[]): string {
    return 'modified with ' + modifications.map(modificationString).join(', ');
}
