import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ungzip } from 'pako';
import { Button, Grid, Icon, Loader, Message, Popup } from 'semantic-ui-react';
import { Chart, Scatter } from 'jubilant-carnival';
import { range } from '../../utilities/misc';
import { DataTable, DataTableColumn } from 'ts-ztable';
import { DNALogo } from 'logots-react';
import { downloadSVG } from 'components/tf/geneexpression/utils';
import { Link } from 'react-router-dom';
import { downloadData } from 'utilities/svgdata';

export type MMotif = {
    pwm: { A: number, C: number, G: number, T: number }[] | number[][];
    dbd: string;
    color: string;
    factor: string;
    accession: string;
    coordinates: [ number, number ];
    e?: number;
    sites?: number;
};

function rc(x: number[][]): number[][] {
    const m = [ ...x.map(xx => [ ...xx ]) ];
    return m.map(xx => xx.reverse()).reverse();
}

const MotifRow: React.FC<MMotif> = x => {
    console.log(x)
    const r = useRef<SVGSVGElement>(null);
    const [ rrc, setRC ] = useState(false);
    return (
        <>
            <Grid>
                <Grid.Column width={2}>
                    <Icon name="download" onClick={() => downloadSVG(r, "logo.svg")} />
                    <Popup content="reverse complement" trigger={<Icon name="exchange" onClick={() => setRC(!rrc)} />} />
                </Grid.Column>
                <Grid.Column width={13}>
                    <DNALogo
                        ppm={rrc ? rc(pwmArray(x.pwm)) : pwmArray(x.pwm)}
                        height={50}
                        svgRef={r}
                    />
                </Grid.Column>
            </Grid>
        </>
    );
}

const COLUMNS: DataTableColumn<MMotif>[] = [{
    header: "Motif",
    value: x => (x.pwm as { A: number, C: number, G: number, T: number }[])[0].A === undefined ? x.pwm[0][0] : (x.pwm as { A: number, C: number, G: number, T: number }[])[0].A,
    functionalRender: MotifRow
}, {
    header: "Assayed TF",
    value: x => x.factor,
    render: x => <Link to={`/tf/human/${x.factor}`}>{x.factor}</Link>
}, {
    header: "Experiment",
    value: x => x.accession,
    render: x => <a href={`https://www.encodeproject.org/experiments/${x.accession}`} target="_blank" rel="noopener noreferrer">{x.accession}</a>
}];

export function pwmArray(pwm: { A: number, C: number, G: number, T: number }[] | number[][]): number[][] {
    if ((pwm as { A: number, C: number, G: number, T: number }[])[0].A === undefined) return pwm as number[][];
    return (pwm as { A: number, C: number, G: number, T: number }[]).map(x => [ x.A, x.C, x.G, x.T ]);
}

function lower5(x: number): number {
    return Math.floor(x / 5) * 5;
}

function upper5(x: number): number {
    return Math.ceil(x / 5) * 5;
}

export function meme(motifs: MMotif[]): string {
    return `MEME version 4.5
ALPHABET= ACGT

${motifs.map(x => `MOTIF ${x.accession.split(" ")[0]}_${x.factor}\nletter-probability matrix: alength= 4 w= ${x.pwm.length} nsites= ${x.sites || 0} E= ${x.e?.toExponential(3) || 0}
${pwmArray(x.pwm).map(xx => xx.map(xxx => xxx.toFixed(4)).join(" ")).join("\n")}`).join("\n\n")}\n`;
}

const MotifUMAP: React.FC<{ url: string, title: string }> = props => {

    const [ data, setData ] = useState<MMotif[]>([]);
    const [ selection, setSelection ] = useState<MMotif[]>([]);

    useEffect( () => {
        fetch(props.url)
            .then(x => x.blob())
            .then(x => x.arrayBuffer())
            .then(x => ungzip(x))
            .then(x => JSON.parse(new TextDecoder().decode(x)))
            .then(x => setData(x));
    }, []);

    const points = useMemo( () => data.map(x => ({ x: x.coordinates[0], y: x.coordinates[1], svgProps: { fill: x.color } })), [ data ]);
    const domain = useMemo( () => points.length === 0 ? { x: { start: 0, end: 1 }, y: { start: 0, end: 1 } } : ({
        x: { start: lower5(Math.min(...points.map(x => x.x)) * 1.1), end: upper5(Math.max(...points.map(x => x.x)) * 1.1) },
        y: { start: lower5(Math.min(...points.map(x => x.y)) * 1.1), end: upper5(Math.max(...points.map(x => x.y)) * 1.1) }
    }), [ points ]);

    const uref = useRef<SVGSVGElement>(null);

    return data.length === 0 ? <Loader active>Loading motif UMAP...</Loader> : (
        <>
            <Message info>
                <Icon name="info circle" />&nbsp;
                <strong>This view displays a UMAP projection of {points.length.toLocaleString()} motifs</strong> discovered by&nbsp;
                {props.title === "meme" ? "MEME on ChIP-seq datasets" : "the ZMotif neural netowrk on HT-SELEX datasets"}. Hold shift,
                click, and drag to view information about motif clusters in this view or to export them for downstream analysis.
            </Message>
            <Grid>
                <Grid.Column width={8}>
                    <Chart
                        marginFraction={0.12}
                        innerSize={{ width: 1100, height: 1000 }}
                        domain={domain}
                        xAxisProps={{ ticks: range(domain.x.start, domain.x.end, 5), title: 'UMAP-1' }}
                        yAxisProps={{ ticks: range(domain.y.start, domain.y.end, 5), title: 'UMAP-2' }}
                        scatterData={[ points ]}
                        plotAreaProps={{ freeformSelection: true, onFreeformSelectionEnd: (_, i) => setSelection(i[0].map(x => data[x])) }}
                        ref={uref}
                    >
                        <Scatter
                            data={points}
                        />
                    </Chart>
                    <Button onClick={() => downloadSVG(uref, "umap.svg")}>Export Plot as SVG</Button>
                </Grid.Column>
                <Grid.Column width={8}>
                    <DataTable
                        columns={COLUMNS}
                        rows={selection}
                        emptyText="Shift, click, and drag on the UMAP to make a selection"
                        itemsPerPage={4}
                    />
                    { selection.length > 0 && <Button onClick={() => downloadData(meme(selection), "motif-collection.meme")}><Icon size="small" name="download" /> Download these motifs</Button> }
                </Grid.Column>
            </Grid>
        </>
    );

}
export default MotifUMAP;
