import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';

import { inflate } from 'pako';
import { associateBy } from 'queryz';
import Function from './function';
import MotifEnrichment from './motifenrichment/motifenrichment';
import { ApiContext } from 'apicontext';
import { includeTargetTypes, excludeTargetTypes } from '../../consts';
import SearchByRegion from '../searchbyregions/searchbyregions';
import { DATASETS_QUERY, DEEP_LEARNED_MOTIFS_COUNTS_QUERY } from './queries';
import { DatasetQueryResponse } from './types';

import { formatFactorName } from '../../utilities/misc';
import { BiosamplePartitionedDatasetCollection } from 'components/types';
import { Redirect } from 'react-router';
import { HistoneAggregatePage } from '../aggregate';
import { GeneExpressionPage } from './geneexpression';

import DeepLearnedSelexMotifs from './motifenrichment/deeplearnedselexmotifs';
import DeepLearnedNonSELEXMotifs from './motifenrichment/deeplearnednonselexmotifs';

const SEQUENCE_SPECIFIC = new Set([ "Known motif", "Inferred motif" ]);

const FactorPage = () => {
    const history = useHistory();
    let { species, factor, factordetails } = useParams<{ species: string; factor: string; factordetails?: string }>();
    const assembly = species === 'human' ? 'GRCh38' : 'mm10';
    const client = useContext(ApiContext).client;
    const [ lloading, setLoading ] = useState(false);
    const [ tfA, setTFA ] = React.useState<any | null>(null);

    React.useEffect( () => {
        if (!lloading) {
            fetch("/tf-assignments.json.gz")
                .then(x => x.blob())
                .then(x => x.arrayBuffer())
                .then(x => inflate(x, { to: 'string' }))
                .then(x => setTFA(associateBy(JSON.parse(x), x => (x as any)["HGNC symbol"], x => x)));
            setLoading(true);
        }
    }, [ lloading ]);

    const { data, error, loading } = useQuery<DatasetQueryResponse>(DATASETS_QUERY, {
        client,
        variables: {
            processed_assembly: assembly,
            target: factor,
            replicated_peaks: true,
            include_investigatedas: includeTargetTypes,
            exclude_investigatedas: excludeTargetTypes,
        },
    });

    const { data: dlMotifs, error: dlError, loading: dlLoading } = useQuery<{
        deep_learned_motifs_counts: { nonselexdlmotifs: number; selexdlmotifs: number };
    }>(DEEP_LEARNED_MOTIFS_COUNTS_QUERY, {
        client,
        variables: {
            tf: factor.includes('eGFP') ? factor.split('-')[1].toUpperCase() : factor.toUpperCase(),
            species,
        },
    });

    if (!factordetails) return <Redirect to={`/tf/${species}/${factor}/function`} />;

    if (data && data.peakDataset && data.peakDataset.counts && data.peakDataset.counts.total === 0 && false)
        return <Redirect to="/" />;

    const datasetsByBiosample: BiosamplePartitionedDatasetCollection[] = [];

    const inner = (() => {
        switch (factordetails!.toLowerCase()) {
            case 'function':
                return <Function assembly={assembly} factor={factor} datasets={data!} datasetsLoading={loading} label={(!tfA || !tfA.get(factor)) ? "" : (tfA.get(factor)["TF assessment"] as unknown as string).includes("Likely") ? "Likely sequence-specific TF - " : (SEQUENCE_SPECIFIC.has(tfA.get(factor)["TF assessment"]) ? "Sequence-specific TF - " : "Non-sequence-specific factor - ")} />;
            case 'motif':
                return (
                    !(error || loading || !data) &&
                    data.peakDataset.partitionByBiosample.length > 0 && (
                        <MotifEnrichment factor={factor} data={data.peakDataset.partitionByBiosample} sequenceSpecific={!tfA || !tfA.get(factor) ? undefined : (tfA.get(factor)["TF assessment"] as unknown as string).includes("Likely") || SEQUENCE_SPECIFIC.has(tfA.get(factor)["TF assessment"])} />
                    )
                );
            case 'methyl_motif':
                // FIXME: methyl motifs for mouse?
                if (species === 'human') {
                    return (
                        !(error || loading || !data) &&
                        datasetsByBiosample.length > 0 && (
                            <MotifEnrichment factor={factor} data={datasetsByBiosample} methyl={true} />
                        )
                    );
                } else {
                    return <Redirect to={`/tf/${species}/${factor}/function`} />;
                }
            case 'regions':
                return <SearchByRegion factor={factor} assembly={assembly} />;
            case 'histone':
                return (
                    !(error || loading || !data) &&
                    data.peakDataset.partitionByBiosample.length > 0 && (
                        <HistoneAggregatePage key={'histone'} assembly={assembly} target={factor} />
                    )
                );
            case 'geneexpression':
                return <GeneExpressionPage assembly={assembly} gene_name={factor} />;
            case 'deeplearnedselexmotif':
                return (
                    !(dlLoading || dlError || !dlMotifs) && (
                        <DeepLearnedSelexMotifs
                            factor={factor.includes('eGFP') ? factor.split('-')[1].toUpperCase() : factor.toUpperCase()}
                            species={species}
                        />
                    )
                );
            case 'deeplearnedmotifs':
                return (
                    !(dlLoading || dlError || !dlMotifs) && (
                        <DeepLearnedNonSELEXMotifs
                            species={species}
                            factor={factor.includes('eGFP') ? factor.split('-')[1].toUpperCase() : factor.toUpperCase()}
                        />
                    )
                );
            default:
                return <Redirect to={`/tf/${species}/${factor}/function`} />;
        }
    })();
    const HoverA: React.FC<{ onHover: () => void }> = props => {
        const passProps = { ...props };
        delete passProps.children;
        return (
            <a onMouseEnter={props.onHover} {...passProps}>
                {props.children}
            </a>
        );
    };
    const MenuItem: React.FC<{ page: string; title: string; onHover?: () => void }> = ({ page, title, onHover }) => (
        <Menu.Item
            active={factordetails!.toLowerCase() === page}
            onClick={() => history.push(`/tf/${species}/${factor}/${page}`)}
            as={HoverA}
            onHover={onHover}
        >
            {title}
        </Menu.Item>
    );
    // FIXME: methyl motifs for mouse?
    return (
        <div style={{ paddingTop: '5em', position: 'relative' }}>
            <Menu attached="top" tabular>
                <MenuItem page="function" title="Function" />
                <MenuItem page="geneexpression" title="Expression (RNA-seq)" />
                <MenuItem page="motif" title="Motif Enrichment (MEME, ChIP-seq)" />
                {dlMotifs && dlMotifs.deep_learned_motifs_counts.selexdlmotifs > 0 && (
                    <MenuItem page="deeplearnedselexmotif" title="Motif Enrichment (SELEX)" />
                )}
                {false && species === 'human' && (
                    <MenuItem page="methyl_motif" title="Methyl Motif Enrichment (MEME)" />
                )}
                <MenuItem page="histone" title="Epigenetic Profile" />
                <MenuItem page="regions" title={`Search ${formatFactorName(factor, species)} peaks by region`} />
                {dlMotifs && dlMotifs.deep_learned_motifs_counts.nonselexdlmotifs > 0 && false && (
                    <MenuItem page="deeplearnedmotifs" title="Deep Learned Motifs" />
                )}
            </Menu>
            <div style={{ marginTop: '1rem' }}>{inner}</div>
        </div>
    );
};

export default FactorPage;
