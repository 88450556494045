import React, { useContext } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Menu, Grid, Table, Card, Message, Button, Icon, Divider } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router';

import Motifs from '../tf/motifenrichment/motifs';
import { ApiContext } from 'apicontext';
import SearchByRegion from '../searchbyregions/searchbyregions';
import { EXPERIMENT_QUERY } from './queries';
import { ExperimentQueryResponse, ExperimentQueryDataset } from './types';
import { formatFactorName } from '../../utilities/misc';
import { tfRoute, cellTypeRoute } from '../../routing';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const FIELDS = {
    Released: (row: ExperimentQueryDataset) => {
        const d = new Date(row.released);
        return `${months[d.getMonth()]} ${d.getFullYear()}`;
    },
    Factor: (row: ExperimentQueryDataset) => (
        <Link to={tfRoute(row.species === 'Homo sapiens' ? 'human' : 'mouse', row.target)}>
            {formatFactorName(row.target, row.species)}
        </Link>
    ),
    Species: (row: ExperimentQueryDataset) => row.species,
    'Cell Type': (row: ExperimentQueryDataset) => (
        <Link to={cellTypeRoute(row.species === 'Homo sapiens' ? 'human' : 'mouse', row.biosample)}>
            {row.biosample}
        </Link>
    ),
    Lab: (row: ExperimentQueryDataset) => row.lab.friendly_name,
};

const ExperimentPage = () => {
    const history = useHistory();
    let { accession, details } = useParams<{ accession: string; details: string }>();
    const client = useContext(ApiContext).client;
    const { data, error, loading } = useQuery<ExperimentQueryResponse>(EXPERIMENT_QUERY, {
        client,
        variables: {
            accession,
        },
    });
    if (!details) return <Redirect to={`/experiment/${accession}/motif`} />;
    if (!data || !data.peakDataset || !data.peakDataset.datasets || !data.peakDataset.datasets[0]) return null;
    const assembly = data.peakDataset.datasets[0].species === 'Homo sapiens' ? 'GRCh38' : 'mm10';
    const files = data.peakDataset.datasets[0]!.files.filter(x => x.assembly.name === assembly);

    const inner = (() => {
        switch (details!.toLowerCase()) {
            case 'motif':
                return (
                    !(error || loading || !data) && (
                        <>
                            <h1>
                                <em>De novo</em> motif discovery for {data.peakDataset.datasets[0]!.target} in{' '}
                                {data.peakDataset.datasets[0]!.biosample} ({accession}) by MEME
                            </h1>
                            <br />
                            <Grid>
                                <Grid.Column
                                    style={{
                                        maxHeight: 'calc(90vh - 135px)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                        width: '100%',
                                    }}
                                    width={16}
                                >
                                    <Motifs peaks_accession={files && files![0] && files[0]!.accession} />
                                </Grid.Column>
                            </Grid>
                        </>
                    )
                );
            case 'regions':
                return (
                    <>
                        <div style={{ textAlign: 'center', paddingTop: '1rem', paddingBottom: '1rem' }}>
                            <Button
                                size="massive"
                                href={`https://www.encodeproject.org/files/${data.peakDataset.datasets[0].files[0].accession}/@@download/${data.peakDataset.datasets[0].files[0].accession}.bed.gz`}
                            >
                                <Icon name="download" />
                                Download Peaks
                            </Button>
                        </div>
                        <Divider style={{ paddingTop: '1rem', paddingBottom: '1rem' }} horizontal>
                            Or
                        </Divider>
                        <SearchByRegion accession={accession} assembly={assembly} />
                    </>
                );
            case 'histone':
                return !(error || loading || !data) && null;
            case 'dnase':
                return !(error || loading || !data) && null;
            case 'conservation':
                return !(error || loading || !data) && null;
            default:
                return <Redirect to={`/experiment/${accession}/overview`} />;
        }
    })();
    const HoverA: React.FC<{ onHover: () => void }> = props => {
        const passProps = { ...props };
        delete passProps.onHover;
        delete passProps.children;
        return (
            <a onMouseEnter={props.onHover} {...passProps}>
                {props.children}
            </a>
        );
    };
    const MenuItem: React.FC<{ page: string; title: string; onHover?: () => void }> = ({ page, title, onHover }) => (
        <Menu.Item
            active={details!.toLowerCase() === page}
            onClick={() => history.push(`/experiment/${accession}/${page}`)}
            as={HoverA}
            onHover={onHover}
        >
            {title}
        </Menu.Item>
    );
    // FIXME: methyl motifs for mouse?
    return (
        <div style={{ paddingTop: '5em', position: 'relative' }}>
            <Grid>
                <Grid.Column width={3}>
                    <Card>
                        <Card.Content>
                            <Card.Header>{accession}</Card.Header>
                            <Card.Meta>
                                <a
                                    style={{ color: '#1a9ced' }}
                                    href={`https://www.encodeproject.org/experiments/${accession}`}
                                    rel="noreferrer noopener"
                                    target="_blank"
                                >
                                    view on ENCODE Portal &gt;&gt;
                                </a>
                            </Card.Meta>
                            <Card.Description>
                                <Table celled>
                                    <Table.Body>
                                        {Object.keys(FIELDS).map(field => (
                                            <Table.Row key={field}>
                                                <Table.Cell>
                                                    <strong>{field}</strong>
                                                </Table.Cell>
                                                <Table.Cell>{FIELDS[field](data.peakDataset.datasets[0])}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </Grid.Column>
                <Grid.Column
                    width={10}
                    style={{
                        maxHeight: 'calc(100vh - 135px)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {!data.peakDataset.datasets[0]!.files || !data.peakDataset.datasets[0].files[0]! ? (
                        <Message negative>
                            <Message.Header>
                                This experiment has not been processed with the Factorbook pipeline yet.
                            </Message.Header>
                            As soon as results are available, they will appear here. To see results for other
                            experiments on the same facor or in the same cell type, use the links at left.
                        </Message>
                    ) : (
                        <>
                            <Menu attached="top" tabular>
                                <MenuItem page="motif" title="Motif Enrichment (MEME)" />
                                <MenuItem page="regions" title="Search or Download Peaks" />
                            </Menu>
                            <div style={{ marginTop: '1rem' }}>{inner}</div>
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </div>
    );
};
export default ExperimentPage;
