import React from 'react';
import { Tab } from 'semantic-ui-react';

import MotifSearchResultsPane from './motifresults';
import { FileMenuProps } from './types';

const FileMenu: React.FC<FileMenuProps> = ({ assembly, species, motifs }) => (
    <Tab
        menu={{ pointing: true, secondary: true }}
        panes={motifs.map((motif, i) => ({
            menuItem: motif.name.length > 30 ? motif.name.slice(0, 26) + ' ...' : motif.name,
            render: () => <MotifSearchResultsPane key={i} species={species} assembly={assembly} pwm={motif.pwm} />,
        }))}
    />
);
export default FileMenu;
