import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { ApiContext } from 'apicontext';

import { inflate } from 'pako';
import { associateBy } from 'queryz';

import { DataTable, DataTableColumn } from 'ts-ztable';
import { includeTargetTypes, excludeTargetTypes } from '../../consts';
import { TF_INFO_QUERY } from './queries';
import { DatasetQueryResponse } from './types';
import { formatFactorName } from '../../utilities/misc';
import { tfRoute } from '../../routing';
import { FACTOR_DESCRIPTION_QUERY } from './queries';

const SEQUENCE_SPECIFIC = new Set([ "Known motif", "Inferred motif" ]);
interface FactorRow {
    image?: string;
    label?: string;
    name: string;
    experiments: number;
    cellTypes: number;
    description?: string;
}

function tfTablecolumns(species: string): DataTableColumn<FactorRow>[] { 
    return [
    
    {
      header: "Factor",
      render: (row: FactorRow) => (
       
          <>
             {' '}
                <Link to={tfRoute(species, row.name)}>
                        {' '}
                        <h3>{formatFactorName(row.name, species)}</h3>
                </Link>
                <br />
            
            
              {row.label ? (
                <>
                  {row.label.replace(/ -/g, "")}
                  <br />
                </>
              ) : (
                ""
              )}
            
            {row.experiments} Experiments        
            <br/>    
            {row.cellTypes} Cell Types
        
          </>
        
      ),
      value: (row: FactorRow) =>
        `${row.name}, ${row.label || ""}, ${row.experiments} Experiments, ${
          row.cellTypes
        } Cell Types`,
        sort: (a: FactorRow, b: FactorRow) => b.cellTypes - a.cellTypes,
    
    },
    {
        header: "Image",
        headerRender: () => <></>,
        render: (row: FactorRow) =>
          row.image ? (
           
              <img
                src={row.image}
                alt={row.name}
                style={{
                  display: "block",
                  minWidth: "250px",
                  height: "250px",
                  padding: "10px",
                }}
              />
            
          ) : (
            ""
          ),
        value: (row: FactorRow) => row.image || "",
        sort: (a: FactorRow, b: FactorRow) => b.cellTypes - a.cellTypes,
      },
    {
      header: "Description",
      render: (row: FactorRow) => (       
          <>
            {row.description}            
          </>
        
      ),
      value: (row: FactorRow) => row.description || "",
    },
  ]};

/*function tfColumns(species: string, tfA?: any): DataTableColumn<TargetPartitionedDatasetCollection>[] {
    return [
        {
            header: '',
            value: row => row.target.name,
            render: row => <TfDetails row={row} species={species} factor={row.target.name} label={
                (tfA === undefined || tfA.get(row.target.name.split("phospho")[0]) === undefined ? 
                "" 
                : (
                    tfA.get(row.target.name.split("phospho")[0])["TF assessment"] as unknown as string).includes("Likely") ? 
                    "Likely sequence-specific TF - " : 
                    
                    (SEQUENCE_SPECIFIC.has(tfA.get(row.target.name.split("phospho")[0])["TF assessment"]) 
                    
                    ? 
                "Sequence-specific TF - " : "Non-sequence-specific factor - "))} />,
            sort: (a, b) => b.counts.biosamples - a.counts.biosamples,
        },
    ];
}*/
export const getRCSBImageUrl = (
    pdbids: string | null | undefined
  ): string | undefined => {
    if (!pdbids) return undefined;
    const PBID = pdbids.split(",")[0].split(":")[0].toLowerCase();
    return PBID
      ? `http://cdn.rcsb.org/images/structures/${PBID.substring(
          1,
          3
        )}/${PBID}/${PBID}_chain-A.jpeg`
      : undefined;
  };
  
const Tf: React.FC = () => {
    const { species } = useParams<{ species: string }>();
    const [rows, setRows] = useState<FactorRow[]>([]);
    const assembly = species === 'human' ? 'GRCh38' : 'mm10';
    const client = useContext(ApiContext).client;
    const { data, error, loading } = useQuery<DatasetQueryResponse>(TF_INFO_QUERY, {
        client,
        variables: {
            processed_assembly: assembly,
            replicated_peaks: true,
            include_investigatedas: includeTargetTypes,
            exclude_investigatedas: excludeTargetTypes,
        },
    });

    const [ lloading, setLoading ] = React.useState(false);
    const [ tfA, setTFA ] = React.useState<any | null>(null);

    useEffect( () => {
        if (!lloading) {
            fetch("/tf-assignments.json.gz")
                .then(x => x.blob())
                .then(x => x.arrayBuffer())
                .then(x => inflate(x, { to: 'string' }))
                .then(x => setTFA(associateBy(JSON.parse(x), x => (x as any)["HGNC symbol"], x => x)));
            setLoading(true);
        }
    }, [ lloading ]);


    const {
        data: factorData,
        loading: factorLoading,
        error: factorError,
      } = useQuery(FACTOR_DESCRIPTION_QUERY, {
        client,
        variables: {
          assembly,
          name: data
            ? data.peakDataset.partitionByTarget.map(
                (target) => target.target.name.split("phospho")[0] 
              )
            : [],
        },
        skip: !data,
      });
    console.log(factorData,factorLoading,factorError)

    useEffect(() => {
        if (data && factorData && tfA) {
          const factorDescriptions: FactorRow[] =
            data.peakDataset.partitionByTarget.map((target) => {
              const factor = factorData.factor.find(
                (factor) => factor.name === target.target.name.split("phospho")[0] 
              );
              const image = getRCSBImageUrl(factor?.pdbids);
    
              const tfAssignment = tfA.get(target.target.name.split("phospho")[0]);
              const description =
                factor?.factor_wiki?.split(".")[0] || "Description not available.";
    
              return {
                image: image,
                label:
                  tfAssignment === undefined
                    ? ""
                    : (tfAssignment["TF assessment"] as string).includes("Likely")
                    ? "Likely sequence-specific TF - "
                    : SEQUENCE_SPECIFIC.has(tfAssignment["TF assessment"])
                    ? "Sequence-specific TF - "
                    : "Non-sequence-specific factor - ",
                name: target.target.name,
                experiments: target.counts.total,
                cellTypes: target.counts.biosamples,
                description: description + ".",
              };
            });
    
          setRows(factorDescriptions);
        }
      }, [data, factorData, tfA]);
    
    return (
        <>
            <div style={{ height: '5em' }} />
            {loading && factorLoading && <Loader active />}
            {data && factorData && !factorError && !error && (
                <Container>
                    <h2>
                        Browsing {data.peakDataset.partitionByTarget.length} factors with data available in {species}:
                    </h2>
                    <DataTable
                        columns={tfTablecolumns(species)}
                        rows={rows}
                        searchable
                        itemsPerPage={4}
                        sortColumn={0}
                        sortDescending
                    />
                </Container>
            )}
        </>
    );
};

export default Tf;
