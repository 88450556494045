import React, { useState } from 'react';
import { Button, Container, Divider, Header, Icon, List, Accordion, Segment, Message } from 'semantic-ui-react';
import { DataTable, DataTableColumn } from 'ts-ztable';

type ModelDetails = {
    title: string;
    partitions: {
        title: string;
        footprint: number;
    }[];
    url: string;
};

const MODEL_COLUMNS: DataTableColumn<ModelDetails & { peaks: number, motifs: number, motifSource: string }>[] = [{
    header: "Model Title",
    value: x => x.title,
    render: x => <a href="#">{x.title}</a>
}, {
    header: "Motif Identification Method",
    value: x => x.motifSource
}, {
    header: "Total Motif Sites (Overlaps Merged)",
    value: x => x.motifs.toLocaleString()
}, {
    header: "Number of (merged) Peak Regions Included",
    value: x => x.peaks.toLocaleString()
}, {
    header: "Genomic Footprint of Motifs",
    value: x => `${x.partitions[0].footprint.toFixed(2)}%`
}, {
    header: "URL",
    value: x => x.url,
    render: x => <a href={x.url} download>{x.url}</a>
}];

const MODELS: (ModelDetails & { peaks: number, motifs: number, motifSource: string })[] = [{
    title: "any ENCODE cell type",
    partitions: [{
        title: "Motif Sites",
        footprint: 1.1
    }],
    url: "http://gcp.wenglab.org/ldr-models/all-peak-motif-sites.tar.gz",
    peaks: 627669,
    motifs: 1047362,
    motifSource: "ChIP-seq, MEME/FIMO"
}, {
    title: "seven ENCODE cell lines",
    partitions: [{
        title: "motif sites in K562 peaks",
        footprint: 0.73
    }, {
        title: "K562 peak sequences outside motifs",
        footprint: 19.6
    }, {
        title: "motif sites in A549 peaks",
        footprint: 0.59
    }, {
        title: "A549 peak sequences outside motifs",
        footprint: 30.9
    }, {
        title: "motif sites in GM12878 peaks",
        footprint: 0.37
    }, {
        title: "GM12878 peak sequences outside motifs",
        footprint: 10.5
    }, {
        title: "motif sites in H1-hESC peaks",
        footprint: 0.41
    }, {
        title: "H1-hESC peak sequences outside motifs",
        footprint: 18.5
    }, {
        title: "motif sites in HepG2 peaks",
        footprint: 0.56
    }, {
        title: "HepG2 peak sequences outside motifs",
        footprint: 22.7
    }, {
        title: "motif sites in MCF-7 peaks",
        footprint: 0.46
    }, {
        title: "MCF-7 peak sequences outside motifs",
        footprint: 16.7
    }, {
        title: "motif sites in HEK293 peaks",
        footprint: 0.72
    }, {
        title: "HEK293 peak sequences outside motifs",
        footprint: 14.2
    }],
    url: "http://gcp.wenglab.org/ldr-models/seven-cell-type-motifs.tar.gz",
    peaks: 1181695,
    motifs: 1024475,
    motifSource: "ChIP-seq, MEME/FIMO"
}].sort( (a, b) => a.title.localeCompare(b.title) );

const PeakMotifModelPage: React.FC = () => {

    const [ model, setModel ] = useState<(ModelDetails & { peaks: number, motifs: number, motifSource: string }) | null>(null);
    const [ expanded, setExpanded ] = useState(false);

    return model === null ? (
        <Container style={{ width: "90%", marginTop: "7em" }}>
            <Header as="h1">
                Quantify heritability in TF motif sites within DNase Hypersensitive Sites
            </Header>
            <p>
                These models quantify heritability within TF motif sites in ChIP-seq peaks. One model includes seven partitions of ChIP-seq
                peaks identified in seven distinct cell lines. The other includes a single partition of all motif sites identified across all
                ChIP-seq-profiled ENCODE cell types.
            </p>
            <DataTable
                columns={MODEL_COLUMNS}
                rows={MODELS}
                onRowClick={row => setModel(row)}
                sortColumn={0}
                sortDescending
            />
        </Container>
    ) : (
        <Container style={{ width: "90%", marginTop: "7em" }}>
            <Header as="h1">
                Quantify heritability in motifs within ChIP-seq peaks from {model.title}
            </Header>
            <Divider />
            <div style={{ textAlign: "center" }}>
                <Button
                    as="a"
                    href={model.url}
                    size="huge"
                >
                    <Icon name="download" /> Download this model <br />
                    <span style={{ fontSize: '0.7em' }}>
                        click below for usage instructions
                    </span>
                </Button>
            </div>
            <Divider />
            <Accordion>
                <Accordion.Title onClick={() => setExpanded(!expanded)}>
                    <Header as="h2">How to use this model</Header>
                    Click to { expanded ? "collapse" : "expand" } instructions.
                </Accordion.Title>
                <Accordion.Content active={expanded}>
                    <List ordered>
                        <List.Item><a href="https://docs.docker.com/get-docker/" target="_blank" rel="noopenner noreferrer">Install Docker</a></List.Item>
                        <List.Item>Create a folder to store inputs and outputs. In this example we use a folder called "ldr" in the current user's home directory.</List.Item>
                        <List.Item><a href={model.url} download>Download</a> the model into the input directory. Download or copy summary statistics into the input directory.</List.Item>
                        <List.Item>Run the following command to unpack the model and partition heritability for a set of summary statistics:</List.Item>
                    </List>
                    <Segment style={{ fontSize: "0.8em" }}>
                        <pre>
                            tar xfvz {model.url.split("/")[model.url.split("/").length - 1]} &amp;&amp; \<br />
                            \ <br />
                            docker run \<br />
                            &nbsp;&nbsp;--volume ~/ldr:/input \<br />
                            &nbsp;&nbsp;ghcr.io/weng-lab/ldr/ldr:latest \<br />
                            &nbsp;&nbsp;python3 -m ldr.h2 \<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;--ld-scores /input/{model.url.split("/")[model.url.split("/").length - 1].split(".")[0]} \<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;--ld-prefix annotations \<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;--summary-statistics /input/summary-stats.txt.gz &gt; partitioned-heritability.txt
                        </pre>
                    </Segment><br />
                    <Message warning>
                        <Icon name="warning" />The summary statistics you use must be compressed with gzip. The command will attempt to format them for input to LDSC. If your
                        summary statistics are already formatted for LDSC, you can append the <strong>--skip-munge</strong> option to the python3 command. See <a href="https://www.github.com/weng-lab/ldr" target="_blank" rel="noopener noreferrer">GitHub repo</a>
                        &nbsp;for the Docker image for more information.
                    </Message>
                    <p>
                        Output will be located at <strong>partitioned-heritability.txt</strong> when the command finishes. The final lines in this
                        file will include heritability enrichment for the partitions in the model.
                    </p>
                </Accordion.Content>
            </Accordion>
            <Divider />
            <Header as="h2">Model overview</Header>
            <strong>Total motif sites</strong>: {model.motifs.toLocaleString()}<br />
            <strong>Motif identification method</strong>: {model.motifSource}<br />
            <strong>Total (merged) peak regions represented</strong>: {model.peaks.toLocaleString()}<br />
            <Header as="h2">Genomic Partitions in this Model</Header>
            <List ordered>
                {model.partitions.map(x => (
                    <List.Item key={x.title}><strong>{x.title}</strong>: {x.footprint.toFixed(1)}% of human genome</List.Item>
                ))}
            </List>
            <Header as="h2">Limitations of this model</Header>
            <List ordered>
                <List.Item>
                    <strong>Cell type representation</strong>: This model includes data from several distinct cell types, some of which are more
                    deeply covered by ENCODE ChIP-seq data than others.
                </List.Item>
                <List.Item>
                    <strong>Factor representation</strong>: This model includes data from several distinct TFs, some of which are more broadly profiled
                    across cell types than others.
                </List.Item>
            </List>
        </Container>
    );

};
export default PeakMotifModelPage;
