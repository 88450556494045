import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import { Input, Menu, Label } from 'semantic-ui-react';
import { BiosamplePartitionedDatasetCollection } from 'components/types';

type ExperimentSelectionProps = {
    data: BiosamplePartitionedDatasetCollection[];
    activeCelltype: string;
    activeExp: string;
    selectCelltype: (celltype: string) => void;
    selectExp: (exp: string) => void;
};

const ExperimentSelection: React.FC<ExperimentSelectionProps> = ({
    data,
    activeCelltype,
    activeExp,
    selectCelltype,
    selectExp,
}) => {
    const [search, setSearch] = useState('');
    const lowerSearch = search.toLowerCase();
    const onSearchChange = useCallback((q: any) => setSearch(q.target.value), []);
    const displayedcts = data.filter(x => x.biosample.name.toLowerCase().includes(lowerSearch));

    const activeCellTypeElement = useRef<HTMLDivElement>();
    useLayoutEffect(() => activeCellTypeElement.current?.scrollIntoView(), []);

    return (
        <>
            {data.length > 10 && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Input icon="search" placeholder="Search cell types..." onChange={onSearchChange} />
                </div>
            )}
            <Menu
                vertical
                tabular
                fluid
                style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 'calc(100% - 65px)' }}
            >
                {displayedcts.map(ct => (
                    <React.Fragment key={ct.biosample.name}>
                        <div ref={ct.biosample.name === activeCelltype ? (activeCellTypeElement as any) : undefined} />
                        <Menu.Item
                            name={ct.biosample.name}
                            active={ct.biosample.name === activeCelltype}
                            onClick={() =>
                                ct.biosample.name === activeCelltype ? {} : selectCelltype(ct.biosample.name)
                            }
                            as={'span'}
                            style={{ cursor: 'pointer' }}
                        >
                            <Label color="teal">{ct.counts.total + ' exp' + (ct.counts.total !== 1 ? 's' : '')}</Label>
                            {ct.biosample.name}
                            {ct.biosample.name === activeCelltype && (
                                <Menu.Menu>
                                    {ct.datasets!.map(dataset => {
                                        let name = dataset.lab.friendly_name + ' (' + dataset.accession + ')';
                                        return (
                                            <Menu.Item
                                                key={name}
                                                name={name}
                                                active={dataset.accession === activeExp}
                                                onClick={() => selectExp(dataset.accession)}
                                                style={
                                                    dataset.accession === activeExp
                                                        ? { backgroundColor: 'lightblue' }
                                                        : {}
                                                }
                                            >
                                                {name}
                                            </Menu.Item>
                                        );
                                    })}
                                </Menu.Menu>
                            )}
                        </Menu.Item>
                    </React.Fragment>
                ))}
            </Menu>
        </>
    );
};
export default ExperimentSelection;
