import React, { useCallback, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { Grid, Header } from 'semantic-ui-react';

import ExperimentSelection from '../../common/experimentselection';
import { PlotPageProps } from './types';
import GraphSet from './graphs';

export const PlotPage: React.FC<PlotPageProps> = ({ type, metadata }) => {
    const history = useHistory();
    const match = useRouteMatch<{ species: string; factor: string; dataset?: string }>(
        `/tf/:species/:factor/${type}/:dataset?`
    );

    const selectExperiment = useCallback(
        (accesion: string) => history.push(`/tf/${match?.params.species}/${match?.params.factor}/${type}/${accesion}`),
        [match, history, type]
    );
    const selectBiosample = useCallback(
        (biosample: string) =>
            history.push(
                `/tf/${match?.params.species}/${match?.params.factor}/${type}/${metadata.accessionMap[biosample]}`
            ),
        [match, history, type, metadata]
    );

    useEffect(() => {
        if (!match?.params.dataset) selectExperiment(metadata.list[0].datasets![0].accession);
    }, [match, selectExperiment, metadata]);

    if (!match?.params.dataset) return <div />;
    return (
        <Grid style={{ width: '100%' }}>
            <Grid.Column width={3} style={{ maxHeight: 'calc(100vh - 135px)', minHeight: '50vh' }}>
                <ExperimentSelection
                    data={metadata.list}
                    selectCelltype={selectBiosample}
                    selectExp={selectExperiment}
                    activeCelltype={metadata.biosampleMap[match.params.dataset]}
                    activeExp={match.params.dataset}
                />
            </Grid.Column>
            <Grid.Column width={13}>
                <Header as="h2" style={{ textAlign: 'center' }}>
                    Histone modification profiles around {match.params.factor} peaks in{' '}
                    {metadata.biosampleMap[match.params.dataset]}
                </Header>
                <GraphSet tfAccession={match.params.dataset} />
            </Grid.Column>
        </Grid>
    );
};
export default PlotPage;
