import { useState, useEffect } from 'react';

export function useGetDownloadFileUrl(primaryurl: string, secondaryurl: string) {
    const [url, setUrl] = useState(primaryurl);
    useEffect(()=> {
        fetch(primaryurl, { method: "HEAD" }).then((res) => {
        if (res.status!==200) {
            setUrl(secondaryurl)
        }
        }).catch((e)=>{      
            setUrl(secondaryurl)
        })
    },
    [primaryurl,secondaryurl]);
    return url
}


export function useFetch<Response = any, Error = any>(url: string) {
    const [response, setResponse] = useState<Response | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [isFetching, setFetching] = useState(false);

    if (!response && !error && !isFetching) {
        setFetching(true);
        fetch(url)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return Error('Invalid status.');
                }
            })
            .then(setResponse)
            .catch(setError);
    }
    return { response, error };
}

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeoutHandler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(timeoutHandler);
        };
    }, [value, delay]);

    return debouncedValue;
}
export function useDelay(delay: number = 200) {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        let timer: any = null;
        (async () => {
            await new Promise(resolve => {
                timer = setTimeout(resolve, delay);
            });
            setLoaded(true);
        })();
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [delay]);
    return loaded;
}
