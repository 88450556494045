import gql from 'graphql-tag';

export const PEAK_COUNT_QUERY = gql`
    query PeakCounts($assembly: String) {
        peakCount(assay: "chip-seq", assembly: $assembly) {
            count
        }
    }
`;

export const MOTIF_COUNT_QUERY = gql`
    query MemeMotifs($assembly: String) {
        meme_motif_count(assembly: $assembly, flank_p_value_threshold: 0.05, shuffled_p_value_threshold: 0.05) {
            count
        }
    }
`;
