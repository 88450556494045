import { ApiContext } from 'apicontext';
import { useContext } from 'react';
import { useQuery } from '@apollo/client';

import { MOTIF_QUERY } from './queries';
import { MotifQueryResponse } from './types';

export function useMotifData(peaks_accession: string) {
    const client = useContext(ApiContext).client;
    return useQuery<MotifQueryResponse>(MOTIF_QUERY, {
        client,
        variables: {
            peaks_accession,
        },
    });
}
