import React, { useState, useRef } from 'react';
import { Segment, Icon, Header, Button } from 'semantic-ui-react';

export type UploadSegmentProps = {
    onFileReceived?: (e: FileList) => void;
    style?: any;
    fileType?: string;
};

const UploadSegment: React.FC<UploadSegmentProps> = ({ onFileReceived, style, fileType }) => {
    const fileInput: any = useRef();
    const [dimmed, setDimmed] = useState(false);

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        setDimmed(false);
        if (onFileReceived) {
            e.preventDefault();
            onFileReceived(e && e.dataTransfer && e.dataTransfer.files);
        }
    };
    const onFileChange = e => {
        onFileReceived && onFileReceived(e.target.files);
    };

    return (
        <Segment
            placeholder
            onDragEnter={() => setDimmed(true)}
            onDragLeave={() => setDimmed(false)}
            onDragOver={(e: React.DragEvent<HTMLDivElement>) => {
                e.preventDefault();
            }}
            onDrop={handleDrop}
            className={dimmed ? 'dimmed' : ''}
            style={style}
        >
            <Header icon>
                <Icon name="file outline" style={{ fontSize: '36pt' }} />
                Drag and drop {fileType || 'BED'} files here.
            </Header>
            <Button primary onClick={() => fileInput && fileInput.current && fileInput.current.click()}>
                Browse Computer
            </Button>
            <input type="file" name="file" hidden ref={fileInput} onChange={onFileChange} multiple />
        </Segment>
    );
};
export default UploadSegment;
