import { Header, Grid, Loader, Dropdown, Icon, Button } from 'semantic-ui-react';
import React, { useRef, useMemo, useContext, useState } from 'react';
import { ApiContext } from 'apicontext';
import { Chart, Range2D, Line, Point2D, LegendEntry } from 'jubilant-carnival';

import { Logo, DNAAlphabet, LogoProps } from 'logojs-react';
import { range } from 'utilities/misc';
import { linearTransform } from '../geneexpression/utils';
import { DEEP_LEARNED_MOTIFS_SELEX_QUERY, DEEP_LEARNED_MOTIFS_SELEX_METADATA_QUERY } from './queries';
import { useQuery } from '@apollo/client';
import { DeepLearnedSELEXMotifsQueryResponse, DeepLearnedSELEXMotifsMetadataQueryResponse } from './types';
import { downloadData, downloadSVG } from '../../../utilities/svgdata';
import { meme, MMotif } from 'components/motifsearch/umap';

export const reverseComplement = (ppm: number[][]): number[][] =>
    ppm && ppm[0] && ppm[0].length === 4
        ? ppm.map(inner => inner.slice().reverse()).reverse()
        : ppm.map(entry => [entry[3], entry[2], entry[1], entry[0], entry[5], entry[4]]).reverse();

const colors = {
    1: '#FFA500',
    2: '#FF0000',
    3: '#008000',
    4: '#0000FF',
    5: '#A52A2A',
    6: '#FFD700',
    7: '#90EE90',
};

const DeepLearnedSelexMotifs: React.FC<{
    factor: string;
    species: string;
}> = ({ factor, species }) => {
    const client = useContext(ApiContext).client;
    const [motif, setMotif] = React.useState<string>();
    const { data, loading } = useQuery<DeepLearnedSELEXMotifsMetadataQueryResponse>(
        DEEP_LEARNED_MOTIFS_SELEX_METADATA_QUERY,
        {
            client,
            variables: {
                tf: factor,
                species,
                selex_round: [1, 2, 3, 4, 5, 6, 7],
            },
        }
    );

    const studies = useMemo(() => [...new Set(data && data.deep_learned_motifs.map(d => d.source))], [data]);

    const assays = useMemo(() => [...new Set(data && data.deep_learned_motifs.map(d => d.assay))], [data]);

    const selexMotifs = useMemo(
        () =>
            data &&
            assays!!
                .map(a => {
                    return studies!!.map(s => {
                        const selexMotifs = data.deep_learned_motifs.filter(m => m.source === s && m.assay === a);
                        const proteinTypes = selexMotifs.map(s => s.protein_type!!);
                        return [...new Set(proteinTypes)].map(pt => {
                            return {
                                protein_type: pt,
                                study: s,
                                assay: a,
                            };
                        });
                    });
                })
                .flat()
                .flat(),
        [data, assays, studies]
    );

    React.useEffect(() => {
        selexMotifs && setMotif(selexMotifs[0].protein_type + ':' + selexMotifs[0].study + ':' + selexMotifs[0].assay);
    }, [selexMotifs]);

    if (loading || !data)
        return (
            <Loader style={{ marginTop: '10em' }} active>
                Loading...
            </Loader>
        );

    let dropDownOptions =
        selexMotifs &&
        selexMotifs.map(s => {
            return {
                key: s.protein_type + s.study + s.assay,
                value: s.protein_type + ':' + s.study + ':' + s.assay,
                text: (
                    <span>
                        &nbsp;&nbsp;<b>Study:</b>
                        {s.study}&nbsp;&nbsp;<b>Assay:</b>
                        {s.assay} &nbsp;&nbsp;<b>Protein Type:</b>
                        {s.protein_type}
                    </span>
                ),
            };
        });
    return (
        <>
            <div style={{ paddingBottom: '0px', marginTop: '1em', marginLeft: '2em' }}>
                {dropDownOptions && (
                    <Dropdown
                        selection
                        options={dropDownOptions}
                        value={motif}
                        onChange={(e, data) => {
                            setMotif(data.value as string);
                        }}
                    />
                )}
            </div>
            {motif && (
                <SelexMotifsForAssayStudyAndProteinType
                    protein_type={motif.split(':')[0]}
                    study={motif.split(':')[1]}
                    assay={motif.split(':')[2]}
                    tf={factor}
                    species={species}
                />
            )}
        </>
    );
};

const SelexMotifsForAssayStudyAndProteinType: React.FC<{
    protein_type: string;
    study: string;
    assay: string;
    tf: string;
    species: string;
}> = ({ protein_type, study, assay, tf, species }) => {
    const client = useContext(ApiContext).client;
    const { data, loading } = useQuery<DeepLearnedSELEXMotifsQueryResponse>(DEEP_LEARNED_MOTIFS_SELEX_QUERY, {
        client,
        variables: {
            tf,
            species,
            selex_round: [1, 2, 3, 4, 5, 6, 7],
            protein_type,
            assay,
            source: study,
        },
    });
    if (loading || !data)
        return (
            <Loader style={{ marginTop: '10em' }} active>
                Loading...
            </Loader>
        );
    return (
        <>
            {data && data.deep_learned_motifs && (
                <DeepLearnedSelexMotif
                    protein_type={protein_type}
                    study={study}
                    assay={assay}
                    data={data.deep_learned_motifs.slice().sort((a, b) => a.selex_round - b.selex_round)}
                />
            )}
        </>
    );
};

function rc(x: number[][]): number[][] {
    const m = [ ...x.map(xx => [ ...xx ]) ];
    return m.map(xx => xx.reverse()).reverse();
}

const DownloadableMotif: React.FC<LogoProps & { name: string }> = props => {
    const svg = useRef<SVGSVGElement>(null);
    const [ rrc, setRC ] = useState(false);
    const ppm = rrc ? rc(props.ppm) : props.ppm;
    return (
        <>
            <Logo
                {...props}
                ppm={ppm}
                ref={svg}
            />
            <Button onClick={() => downloadData(meme([ { accession: props.name, pwm: props.ppm, factor: "" } as any as MMotif ]), `${props.name}.meme`)}>
                <Icon name="download" /> export motif (MEME)
            </Button>
            <Button onClick={() => downloadSVG(svg, "logo.svg")}><Icon name="download" /> Export Logo</Button>
            <Button onClick={() => setRC(!rrc)}><Icon name="exchange" /> Reverse Complement</Button>
        </>
    );
};

const DeepLearnedSelexMotif: React.FC<{
    study: string;
    assay: string;
    protein_type: string;
    data: { selex_round: number; ppm: number[][]; fractional_enrichment: number; roc_curve: number[][] }[];
}> = ({ study, assay, protein_type, data }) => {

    const lineref = useRef<SVGSVGElement>(null);
    const llegendref = useRef<SVGSVGElement>(null);
    const barref = useRef<SVGSVGElement>(null);
    const blegendref = useRef<SVGSVGElement>(null);
    const points = useMemo(
        () =>
            data[0].roc_curve.map(r => {
                return { x: r[0], y: r[1] };
            }),
        [data]
    );

    const domain: Range2D = useMemo(() => {
        return {
            x: {
                start: Math.floor(Math.min(...points.map(point => point.x)) / 0.1) * 0.1,
                end: Math.ceil(Math.max(...points.map(point => point.x)) / 0.1) * 0.1,
            },
            y: {
                start: Math.floor(Math.min(...points.map(point => point.y)) / 1) * 1,
                end: Math.ceil(Math.max(...points.map(point => point.y)) / 1) * 1,
            },
        };
    }, [points]);

    const barplotDomain: Range2D = useMemo(() => {
        return {
            x: {
                start: Math.min(...data.map(x => x.selex_round)) - 1,
                end: Math.max(...data.map(x => x.selex_round)) + 1,
            },
            y: {
                start: 0.0,
                end: Math.max(...data.map(x => x.fractional_enrichment).map(point => point)) * 1 + 0.1,
            },
        };
    }, [data]);

    const height = 30,
        width = 30;

    const rx = useMemo(() => linearTransform([domain.x.start, domain.x.end], [0, width]), [domain]);
    const ry = useMemo(() => linearTransform([domain.y.start, domain.y.end], [0, height]), [domain]);

    const tr = (pt: Point2D) => {
        return {
            x: rx(pt.x),
            y: ry(pt.y),
            svgProps: pt.svgProps,
        };
    };
    return (
        <Grid style={{ paddingBottom: '0px', marginTop: '1em' }}>
            <Grid.Row style={{ paddingBottom: '0px', marginTop: '0.5em', marginLeft: '0.5em' }}>
                <Grid.Column width={6}>
                    <Header textAlign="center">
                        {assay.replaceAll('-', ' ')} motifs for{' '}
                        {protein_type === 'full' ? ' full length protein' : ' DNA binding domain protein'} found in{' '}
                        {study.replace('_', ' ')} study
                    </Header>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ paddingBottom: '0px', marginTop: '0.5em' }}>
                <Grid.Column width={5} textAlign="center">
                    {data.map((d, i) => {
                        return (
                            <React.Fragment key={`logo${i}`}>
                                <Header textAlign="center" color="brown">
                                    &nbsp;Cycle {d.selex_round}
                                </Header>
                                <br />
                                <DownloadableMotif
                                    yAxisMax={2}
                                    height={100}
                                    alphabet={DNAAlphabet}
                                    ppm={d.ppm}
                                    width="100%"
                                    name={study}
                                />
                            </React.Fragment>
                        );
                    })}
                    <br />
                </Grid.Column>

                <Grid.Column width={3}>
                    <>
                        <Chart
                            marginFraction={0.25}
                            innerSize={{ width, height }}
                            domain={domain}
                            xAxisProps={{
                                ticks: [...range(domain.x.start, domain.x.end, 0.5), 1],
                                title: 'TPR',
                                fontSize: 1.5,
                            }}
                            yAxisProps={{
                                ticks: [...range(domain.y.start, domain.y.end, 0.5), 1],
                                title: 'FPR',
                                fontSize: 1.5,
                            }}
                        >
                            {data.map((d, i) => {
                                const f = d.roc_curve.map(r => {
                                    return { x: r[0], y: r[1] };
                                });
                                return (
                                    <Line
                                        key={i}
                                        data={f}
                                        transform={tr}
                                        stroke={colors[d.selex_round]}
                                        strokeWidth="0.2"
                                    />
                                );
                            })}
                        </Chart>
                        <svg viewBox="0 0 400 40" style={{ marginTop: '-0.4em' }} ref={llegendref}>
                            <g transform="translate(130,15)">
                                <text x={0} y={0} fill="black">
                                    Cycle
                                </text>
                            </g>
                            {data.map((d, i) => {
                                return (
                                    <g transform={`translate(${170 + i * 30},0)`} key={i}>
                                        <LegendEntry
                                            label={{
                                                color: colors[d.selex_round],
                                                label: d.selex_round.toString(),
                                                value: '',
                                            }}
                                            simple
                                            size={{ width: 150, height: 25 }}
                                            rectProps={{ height: 2, y: 9 }}
                                        />
                                    </g>
                                );
                            })}
                        </svg>
                        <Button onClick={() => { downloadSVG(lineref, "lineplot.svg"); downloadSVG(llegendref, "lineplot.legend.svg"); }}>
                            Download
                        </Button>
                        <br />
                        <Chart
                            marginFraction={0.3}
                            innerSize={{ width, height }}
                            domain={barplotDomain}
                            xAxisProps={{ ticks: data.map(b => b.selex_round), title: 'Cycle', fontSize: 1.5 }}
                            yAxisProps={{
                                ticks: range(barplotDomain.y.start, barplotDomain.y.end, 0.1),
                                title: 'Fractional Enrichment',
                                fontSize: 1.5,
                            }}
                            ref={barref}
                        >
                            {data.map((b, i) => {
                                return (
                                    <Line
                                        key={i}
                                        data={[
                                            { x: b.selex_round, y: 0 },
                                            { x: b.selex_round, y: b.fractional_enrichment },
                                        ]}
                                        transform={tr}
                                        stroke={colors[b.selex_round]}
                                        strokeWidth={data.length / 3}
                                    />
                                );
                            })}
                        </Chart>

                        <svg viewBox="0 0 400 40" style={{ marginTop: '-0.4em' }} ref={blegendref}>
                            <g transform="translate(130,15)">
                                <text x={0} y={0} fill="black">
                                    Cycle
                                </text>
                            </g>
                            {data.map((b, i) => {
                                return (
                                    <g transform={`translate(${170 + i * 30},0)`} key={i}>
                                        <LegendEntry
                                            label={{
                                                color: colors[b.selex_round],
                                                label: b.selex_round.toString(),
                                                value: '',
                                            }}
                                            simple
                                            size={{ width: 150, height: 25 }}
                                            rectProps={{ height: 7, y: 9 }}
                                        />
                                    </g>
                                );
                            })}
                        </svg>
                        <Button onClick={() => { downloadSVG(barref, "lineplot.svg"); downloadSVG(blegendref, "lineplot.legend.svg"); }}>
                            Download
                        </Button>
                    </>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

/*
const SelexMotifLogo: React.FC<{
    ppm: number[][];
    selex_round: number;
}> = ({ ppm, selex_round }) => {
    const svg = useRef<SVGSVGElement>(null);
    const [isReverseComplement, setReverseComplement] = useState(false);
    const toggleReverseComplement = useCallback(() => setReverseComplement(!isReverseComplement), [
        isReverseComplement,
    ]);
    const motifppm = isReverseComplement ? reverseComplement(ppm) : ppm;
    return (
            <Grid centered>
                <Grid.Row>
                    <Header textAlign="center" color="brown">
                        {' '}
                        Cycle {selex_round}
                    </Header>
                    <br />
                    <Logo yAxisMax={2} height={100} alphabet={DNAAlphabet} ppm={motifppm} ref={svg} width={`100%`} />
                </Grid.Row>
                <Grid.Row>
                    <Menu secondary>
                        <Menu.Item link onClick={toggleReverseComplement}>
                            <Icon name="exchange" /> reverse complement
                        </Menu.Item>
                    </Menu>
                </Grid.Row>
            </Grid>
    );
};
*/

export default DeepLearnedSelexMotifs;
