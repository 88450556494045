import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, Loader } from 'semantic-ui-react';
import { RawLogo, DNAAlphabet } from 'logojs-react';
import { associateBy } from 'queryz';
import { GraphDataset } from './types';
import { Graph } from 'components/aggregate/graphs';
import { downloadSVG } from 'components/tf/geneexpression/utils';

DNAAlphabet[0].color = "#228b22";
DNAAlphabet[3].color = "red";

const MNasePlot: React.FC<{ name: string; celltype: string; pwm: number[][] }> = ({ name, celltype, pwm }) => {

    const [ pData, setPData ] = useState<any | null>(null);
    const [ dData, setDData ] = useState<any | null>(null);
    const [ loading, setLoading ] = useState(true);
    useEffect( () => {
        setLoading(true);
        setPData(null);
        setDData(null);
        fetch(`https://screen-beta-api.wenglab.org/motif_conservation/mnase/all/${name}-proximal.plus.sum.${celltype}.npy`)
            .then(x => x.json())
            .then(x => { setPData(x.slice(16).map((x: number) => x * 2)); setLoading(false) });
        fetch(`https://screen-beta-api.wenglab.org/motif_conservation/mnase/all/${name}-proximal.minus.sum.${celltype}.npy`)
            .then(x => x.json())
            .then(x => { setDData(x.slice(16).map((x: number) => x * 2)); setLoading(false) });
    }, [ celltype, name ]);

    const [limit, setLimit] = useState(1000);

    const ref = useRef<SVGSVGElement>(null);
    const setLimitS = useCallback((limit: number) => {
        if (limit < 10) limit = 10;
        if (limit > 2000) limit = 2000;
        setLimit(limit);
    }, []);

    const datasets = useMemo(() => {
        if (!pData || !dData) return;
        const r: GraphDataset[] = [];
        r.push({
            reverse_values: pData,
            forward_values: dData,
            title: 'MNase-seq',
            color: '#00aa00',
        });
        return r;
    }, [pData, dData]);

    const options = useMemo(
        () =>
            datasets?.map(dataset => ({
                key: dataset.title,
                text: 'Nucleosome positioning: ' + dataset.title,
                value: dataset.title,
                content: dataset.title,
            })),
        [datasets]
    );
    useEffect(() => setDataset(datasets && datasets[0]), [datasets]);

    const datasetMap = useMemo(
        () =>
            associateBy(
                datasets || [],
                x => x.title,
                x => x
            ),
        [datasets]
    );
    const [dataset, setDataset] = useState(datasets && datasets[0]);

    const max = useMemo(
        () =>
            Math.max(
                ...(dataset?.forward_values?.slice(2000 - limit, 2000 + limit) || []),
                ...(dataset?.reverse_values?.slice(2000 - limit, 2000 + limit) || [])
            ),
        [dataset, limit]
    );

    if (loading || !pData || !dData || !datasets) return <Loader active>Loading...</Loader>;
    if (!dataset) return null;

    return (
        <>
            {options && (
                <Dropdown
                    as="h4"
                    style={{ textAlign: 'center', marginBottom: '-1em' }}
                    options={options}
                    onChange={(_, v) => {
                        if (!v.value) return;
                        setDataset(datasetMap.get(v.value as string)!);
                    }}
                    value={dataset.title}
                />
            )}
            <Graph
                is_stranded_motif
                proximal_values={dataset.forward_values.slice(2000 - limit, 2000 + limit)}
                distal_values={(dataset.reverse_values || dataset.forward_values).slice(2000 - limit, 2000 + limit)}
                limit={limit}
                xlabel="distance from motif (bp)"
                ylabel="read count"
                height={220}
                padBottom
                dataset={{ target: 'MNase', accession: celltype }}
                yMax={max * 1.05}
                hideTitle
                sref={ref}
                yMin={0.6}
                semiTransparent
            >
                <g x={-Math.floor(pwm.length / 2)} y={-max / 6} transform={`scale(1,${max / 3 / 200})`}>
                    <RawLogo values={pwm} alphabet={DNAAlphabet} x={0} y={0} glyphWidth={1} stackHeight={100} />
                </g>
            </Graph>
            <Button
                onClick={() => downloadSVG(ref, "footprint.svg")}
            >
                Export SVG
            </Button>
            <Button onClick={() => setLimitS(Math.floor(limit / 2))}>+</Button>
            <Button onClick={() => setLimitS(Math.floor(limit * 2))}>-</Button>
        </>
    );
};
export default MNasePlot;
