import { Graph } from 'components/aggregate/graphs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, Loader } from 'semantic-ui-react';
import { RawLogo, DNAAlphabet } from 'logojs-react';
import { GraphDataset } from './types';
import { associateBy } from 'queryz';
import { downloadSVG } from 'components/tf/geneexpression/utils';

DNAAlphabet[0].color = "#228b22";
DNAAlphabet[3].color = "red";

const ConservationPlot: React.FC<{ name: string; accession: string; pwm: number[][] }> = ({ name, accession, pwm }) => {

    const [ data, setData ] = useState<number[] | null>(null);
    const [ loading, setLoading ] = useState(true);
    useEffect( () => {
        setLoading(true);
        setData(null);
        fetch(`https://screen-beta-api.wenglab.org/motif_conservation/conservation-aggregate/all/${accession}-${name}.sum.npy`)
            .then(x => x.json())
            .then(x => { setData(x.slice(16)); setLoading(false) });
    }, [ accession, name ]);

    const [limit, setLimit] = useState(500);

    const ref = useRef<SVGSVGElement>(null);

    const setLimitS = useCallback((limit: number) => {
        if (limit < 10) limit = 10;
        if (limit > 500) limit = 500;
        setLimit(limit);
    }, []);

    const datasets = useMemo<GraphDataset[]>(() => {
        if (!data) return [];
        return [{
            forward_values: data,
            title: "phyloP 100-way",
            color: '#444444',
        }];
    }, [data]);
    const datasetMap = useMemo(
        () =>
            associateBy(
                datasets || [],
                x => x.title,
                x => x
            ),
        [datasets]
    );
    const [dataset, setDataset] = useState(datasets && datasets[0]);

    const options = useMemo(
        () =>
            datasets?.map(dataset => ({
                key: dataset.title,
                text: 'Evolutionary conservation: ' + dataset.title,
                value: dataset.title,
                content: dataset.title,
            })),
        [datasets]
    );
    useEffect(() => setDataset(datasets && datasets[0]), [datasets]);

    const max = useMemo(() => Math.max(...(dataset?.forward_values.slice(500 - limit, 500 + limit) || [])), [
        dataset,
        limit,
    ]);
    const min = useMemo(() => Math.min(...(dataset?.forward_values.slice(500 - limit, 500 + limit) || []), 0), [
        dataset,
        limit,
    ]);

    if (loading || !data) return <Loader active>Loading...</Loader>;
    if (!dataset || (data.length < 1 && data.length < 1)) return null;

    return (
        <>
            {options && (
                <Dropdown
                    as="h4"
                    style={{ textAlign: 'center', marginBottom: '-1em' }}
                    options={options}
                    onChange={(_, v) => {
                        if (!v.value) return;
                        setDataset(datasetMap.get(v.value as string)!);
                    }}
                    value={dataset.title}
                />
            )}
            <Graph
                dataset={{ target: 'conservation', accession }}
                proximal_values={dataset?.forward_values.slice(500 - limit, 500 + limit)}
                distal_values={dataset?.forward_values.slice(500 - limit, 500 + limit)}
                is_forward_reverse
                limit={limit}
                xlabel="distance from motif (bp)"
                ylabel="conservation"
                height={220}
                yMax={max * 1.2}
                padBottom
                hideTitle
                sref={ref}
            >
                <g
                    x={-Math.floor(pwm.length / 2)}
                    y={-(max - min) / 6 + min}
                    transform={`scale(1,${(max - min) / 3 / 200})`}
                >
                    <RawLogo values={pwm} alphabet={DNAAlphabet} x={0} y={0} glyphWidth={1} stackHeight={100} />
                </g>
            </Graph>
            <Button
                onClick={() => downloadSVG(ref, "conservation.svg")}
            >
                Export SVG
            </Button>
            <Button onClick={() => setLimitS(Math.floor(limit / 2))}>+</Button>
            <Button onClick={() => setLimitS(Math.floor(limit * 2))}>-</Button>
        </>
    );
};
export default ConservationPlot;
