import { useGetDownloadFileUrl } from 'hooks';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Divider, Grid, Header, Icon, Message, Accordion } from 'semantic-ui-react';


const MotifSiteCatalogPage: React.FC = () => {
    const [ expanded, setExpanded ] = useState(-1);
//http://downloads.wenglab.org/cCRE_decoration.matrix.1.gz
    return (
        <Container style={{ width: "90%", marginTop: "7em" }}>
            <Header as="h1" style={{ fontVariant: "small-caps", marginBottom: "0.1em" }}>Factorbook Human Motif Site Catalog</Header>
            <Header as="h3" style={{ marginTop: "0em" }}>Genomic Motif Sites in ChIP-seq Peaks and Candidate Regulatory Elements</Header>
            <Message info>
                <Icon name="info circle" /><strong>This page offers downloads of the complete motif site catalog.</strong>&nbsp;
                To download genomic sites for an individual motif, you can use the buttons available through the TF search or motif search on the home page.
            </Message>
            <Divider />
            <Accordion>
                <Accordion.Title onClick={() => setExpanded(0)}>
                    <Header as="h2">ChIP-seq Peak Motif Site Catalog {expanded !== 0 && <Icon name={expanded === 0 ? "chevron right" : "chevron down"} style={{ fontSize: "0.7em", marginTop: "-0.7em" }} /> }</Header>
                </Accordion.Title>
                <Accordion.Content active={expanded === 0}>
                    <Message warning>
                        <Icon name="warning" />
                        <strong>This catalog contains sites of MEME motifs from ChIP-seq datasets identified within ChIP-seq peaks using FIMO.</strong>&nbsp;
                        <Link to="/ct/human">See here</Link> for a list of cell types in which these ChIP-seq peaks were identified. <strong><em>Regulatory motif
                        sites in cell types biologically distinct from well-profiled cell types might not be contained in this catalog!</em></strong>
                    </Message>
                    <Grid>
                        <Grid.Column width={8}>
                            <Header as="h3">Download merged motif sites</Header>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-peak-occurrences.4.merged.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-peak-occurrences.4.merged.bed.gz")} download>                 
                            <Button size="large">
                                <Icon name="download" />Lenient set<br />                                
                                <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-4</sup> (46 MB)</span>
                            </Button>           
                            </a>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-peak-occurrences.5.merged.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-peak-occurrences.5.merged.bed.gz")} download>                 
                                <Button size="large">
                                    <Icon name="download" />Moderate set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-5</sup> (45 MB)</span>
                                </Button>     
                            </a>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-peak-occurrences.6.merged.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-peak-occurrences.6.merged.bed.gz")} download>
                                <Button size="large">
                                    <Icon name="download" />Stringent set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-6</sup> (44 MB)</span>
                                </Button>  
                            </a>                                                 
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Header as="h3">Download all motif sites</Header>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-peak-occurrences.4.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-peak-occurrences.4.bed.gz")} download>
                                <Button size="large">
                                    <Icon name="download" />Lenient set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-4</sup> (758 MB)</span>
                                </Button>
                            </a>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-peak-occurrences.5.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-peak-occurrences.5.bed.gz")} download>
                                <Button size="large">
                                    <Icon name="download" />Moderate set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-5</sup> (684 MB)</span>
                                </Button>
                            </a>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-peak-occurrences.6.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-peak-occurrences.6.bed.gz")} download>
                                <Button size="large">
                                    <Icon name="download" />Stringent set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-6</sup> (653 MB)</span>
                                </Button>
                            </a>
                            
                        </Grid.Column>
                    </Grid>
                </Accordion.Content>
            </Accordion>
            <Divider />
            <Accordion>
                <Accordion.Title onClick={() => setExpanded(1)}>
                    <Header as="h2">Representative DNase Hypersensitive Site (rDHS) Motif Site Catalog {expanded !== 1 && <Icon name={expanded === 1 ? "chevron right" : "chevron down"} style={{ fontSize: "0.7em", marginTop: "-0.7em" }} /> }</Header>
                </Accordion.Title>
                <Accordion.Content active={expanded === 1}>
                    <Message warning>
                        <Icon name="warning" />
                        <strong>This catalog contains sites of MEME motifs and HT-SELEX motifs identified within rDHSs from the ENCODE Registry of cCREs using FIMO.</strong>&nbsp;
                        <a href="https://screen.encodeproject.org/" target="_blank" rel="noreferrer noopener">Click here</a> for more information on the Registry of cCREs.
                    </Message>
                    <Grid>
                        <Grid.Column width={8}>
                            <Header as="h3">Download merged motif sites</Header>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-rDHS-instances.6.merged.bed.gz", "https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-rDHS-instances.6.merged.bed.gz")} download>                        
                                <Button size="large">                                
                                    <Icon name="download" />MEME: Lenient set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-6</sup> (47 Mb)</span>
                                </Button>
                            </a>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-rDHS-instances.7.merged.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-rDHS-instances.7.merged.bed.gz")} download>
                                <Button size="large">
                                    <Icon name="download" />MEME: Moderate set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-7</sup> (19 Mb)</span>
                                </Button>
                            </a>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-rDHS-instances.8.merged.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-rDHS-instances.8.merged.bed.gz")} download>
                            <Button size="large">
                                <Icon name="download" />MEME: Stringent set<br />
                                <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-8</sup> (11 Mb)</span>
                            </Button>
                            </a>
                            <br /><br />                            
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/HT-SELEX-rDHS.1e-5.merged.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/HT-SELEX-rDHS.1e-5.merged.bed.gz")} download>                                               
                                <Button size="large"> 
                                    <Icon name="download" />HT-SELEX: Lenient set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-5</sup> (57 Mb)</span>
                                </Button>
                            </a>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/HT-SELEX-rDHS.1e-6.merged.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/HT-SELEX-rDHS.1e-6.merged.bed.gz")} download>                                               
                                <Button size="large">
                                    <Icon name="download" />HT-SELEX: Moderate set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-6</sup> (12 Mb)</span>
                                </Button>         
                            </a>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/HT-SELEX-rDHS.1e-7.merged.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/HT-SELEX-rDHS.1e-7.merged.bed.gz")} download>                                               
                                <Button size="large">
                                    <Icon name="download" />HT-SELEX: Stringent set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-7</sup> (2 Mb)</span>
                                </Button>
                            </a>
                            
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Header as="h3">Download all motif sites</Header>                            
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-rDHS-instances.6.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-rDHS-instances.6.bed.gz")} download>
                                <Button size="large">                            
                                    <Icon name="download" />Lenient set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-6</sup> (2.9 Gb)</span>
                                </Button>
                            </a>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-rDHS-instances.7.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-rDHS-instances.7.bed.gz")} download>
                                <Button size="large">
                                    <Icon name="download" />Moderate set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-7</sup> (1.5 Gb)</span>
                                </Button>
                            </a>
                            <a href={useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-rDHS-instances.8.bed.gz","https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-rDHS-instances.8.bed.gz")} download>
                                <Button size="large">
                                    <Icon name="download" />Stringent set<br />
                                    <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-8</sup> (885 Mb)</span>
                                </Button>                            
                            </a>
                            <br /><br />
                            <Button size="large">
                                <Icon name="download" />HT-SELEX: Lenient set<br />
                                <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-5</sup></span>
                            </Button>
                            <Button size="large">
                                <Icon name="download" />HT-SELEX: Moderate set<br />
                                <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-6</sup></span>
                            </Button>
                            <Button size="large">
                                <Icon name="download" />HT-SELEX: Stringent set<br />
                                <span style={{ fontSize: "0.8em" }}>FIMO p-value &lt;10<sup>-7</sup></span>
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Accordion.Content>
            </Accordion>
        </Container>
    );

};
export default MotifSiteCatalogPage;
