import React, { useContext, useState } from 'react';
import { ApiContext } from 'apicontext';
import { useQuery } from '@apollo/client';
import { Chart, Scatter, Range2D, Point2D } from 'jubilant-carnival';
import { Loader, Grid, Table } from 'semantic-ui-react';
import { Logo, DNAAlphabet } from 'logojs-react';
import { useHistory } from 'react-router';
import { experimentRoute } from 'routing';
import { range } from 'utilities/misc';
import {
    MOTIF_ENRICHMENT_BINS_QUERY_DATA,
    MOTIF_ENRICHMENT_QUERY_DATA,
    MOTIF_ENRICHMENT_QUERY,
    MOTIF_ENRICHMENT_BINS_QUERY,
    MOTIF_ENRICHMENT_MOTIF_QUERY_DATA,
    MOTIF_ENRICHMENT_MOTIF_QUERY,
} from './queries';

DNAAlphabet[0].color = "#228b22";
DNAAlphabet[3].color = "red";

interface MotifEnrichmentPlotProps {
    points: Point2D[];
    pointsLoading: boolean;
    binData: MOTIF_ENRICHMENT_BINS_QUERY_DATA | undefined;
    binLoading: boolean;
    binError: any;
    selectedPoint: number | undefined;
    setSelectedPoint: (point: number | undefined) => void;
    setHoverPoint: (point: [number, number, number] | undefined) => void;
}

const MotifEnrichmentPlot: React.FC<MotifEnrichmentPlotProps> = ({
    points,
    pointsLoading,
    binData,
    binLoading,
    binError,
    selectedPoint,
    setSelectedPoint,
    setHoverPoint,
}) => {
    const history = useHistory();
    const domain: Range2D = {
        x: {
            start: Math.floor(Math.min(...points.map(point => point.x)) / 0.5) * 0.5,
            end: Math.ceil(Math.max(...points.map(point => point.x)) / 0.5) * 0.5,
        },
        y: {
            start: Math.floor(Math.min(...points.map(point => point.y)) / 5) * 5,
            end: Math.ceil(Math.max(...points.map(point => point.y)) / 5) * 5,
        },
    };

    const binPoints =
        binData?.motif_enrichment[0].bins?.map((val, idx) => ({
            x: (idx / binData?.motif_enrichment[0].bins.length) * 2157387,
            y: val,
        })) || [];
    const binDomain: Range2D = binPoints
        ? {
              x: {
                  start: Math.floor(Math.min(...binPoints.map(point => point.x))),
                  end: Math.ceil(Math.max(...binPoints.map(point => point.x))),
              },
              y: {
                  start: Math.floor(Math.min(...binPoints.map(point => point.y))),
                  end: Math.ceil(Math.max(...binPoints.map(point => point.y))),
              },
          }
        : { x: { start: 0, end: 0 }, y: { start: 0, end: 0 } };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={7} style={{ textAlign: 'center', paddingLeft: '0px', paddingRight: '0px' }}>
                    {pointsLoading && (
                        <Loader style={{ marginTop: '7em' }} active>
                            Loading...
                        </Loader>
                    )}
                    {!pointsLoading && (
                        <Chart
                            marginFraction={0.12}
                            innerSize={{ width: 1100, height: 1000 }}
                            domain={domain}
                            xAxisProps={{ ticks: range(domain.x.start, domain.x.end, 1), title: 'Fold enrichment' }}
                            yAxisProps={{ ticks: range(domain.y.start, domain.y.end, 5), title: 'Z-score' }}
                        >
                            <Scatter
                                data={points}
                                onPointClick={i =>
                                    selectedPoint === i ? setSelectedPoint(undefined) : setSelectedPoint(i)
                                }
                                onPointMouseOver={(i, event) => setHoverPoint([i, event.pageX, event.pageY])}
                                onPointMouseOut={() => setHoverPoint(undefined)}
                            />
                        </Chart>
                    )}
                </Grid.Column>
                <Grid.Column
                    width={2}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    }}
                >
                    {!binLoading && !binError && binData && (
                        <Table stackable style={{ textAlign: 'center' }}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            history.push(
                                                experimentRoute(
                                                    binData.motif_enrichment[0].motif.peaks_file.dataset.accession
                                                )
                                            )
                                        }
                                    >
                                        <b>Logo</b>
                                        <Logo alphabet={DNAAlphabet} ppm={binData.motif_enrichment[0].motif.pwm} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <b>Peaks accession</b>
                                        <br />
                                        {binData.motif_enrichment[0].motif.peaks_accession}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <b>Biosample</b>
                                        <br />
                                        {binData.motif_enrichment[0].motif.peaks_file.dataset.biosample}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <b>Target</b>
                                        <br />
                                        {binData.motif_enrichment[0].motif.peaks_file.dataset.target}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <b>P-value</b>
                                        <br />
                                        {binData.motif_enrichment[0].p_value.toPrecision(2)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <b>Z-score</b>
                                        <br />
                                        {binData.motif_enrichment[0].top_enrichment_z_score.toFixed(2)}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        <b>Enrichment</b>
                                        <br />
                                        {binData.motif_enrichment[0].top_enrichment_value.toFixed(2)}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    )}
                    <div />
                </Grid.Column>
                <Grid.Column width={7} style={{ textAlign: 'center', paddingLeft: '0px', paddingRight: '0px' }}>
                    {binLoading && (
                        <Loader style={{ marginTop: '7em' }} active>
                            Loading...
                        </Loader>
                    )}
                    {binError && <span>Error loading bins</span>}
                    {!binLoading && !binError && binData && (
                        <Chart
                            marginFraction={0.18}
                            innerSize={{ width: 1100, height: 1000 }}
                            domain={binDomain}
                            xAxisProps={{
                                ticks: range(0, 2157387, 500_000),
                                formatTick: num => num / 1000 + (num === 0 ? '' : 'k'),
                                title: 'rDHS specificity rank',
                            }}
                            yAxisProps={{
                                ticks: range(binDomain.y.start, binDomain.y.end, 2),
                                title: 'motif fold enrichment',
                            }}
                        >
                            <Scatter data={binPoints} />
                        </Chart>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const MotifEnrichmentPlotMemo = React.memo(MotifEnrichmentPlot);

const MotifEnrichment: React.FC<{ biosample: string }> = ({ biosample }) => {
    const [hoverPoint, setHoverPoint] = useState<[number, number, number] | undefined>(undefined);
    const [selectedPoint, setSelectedPoint] = useState<number | undefined>(undefined);
    const client = useContext(ApiContext).client;
    const { data, loading, error } = useQuery<MOTIF_ENRICHMENT_QUERY_DATA>(MOTIF_ENRICHMENT_QUERY, {
        client,
        variables: {
            biosample,
        },
    });
    const selectedMotifId = selectedPoint && data?.motif_enrichment[selectedPoint].motif_id;
    const { data: binData, loading: binLoading, error: binError } = useQuery<MOTIF_ENRICHMENT_BINS_QUERY_DATA>(
        MOTIF_ENRICHMENT_BINS_QUERY,
        {
            client,
            variables: {
                biosample,
                motif_id: selectedMotifId,
            },
            skip: !selectedMotifId,
        }
    );
    const hoverId = hoverPoint && data?.motif_enrichment[hoverPoint[0]].motif_id;
    const { data: hoverData, loading: hoverLoading, error: hoverError } = useQuery<MOTIF_ENRICHMENT_MOTIF_QUERY_DATA>(
        MOTIF_ENRICHMENT_MOTIF_QUERY,
        {
            client,
            variables: {
                biosample,
                motif_id: hoverId,
            },
            skip: !hoverPoint,
        }
    );

    const points: Point2D[] = React.useMemo(() => {
        const points =
            data?.motif_enrichment.map((d, i) => ({
                x: d.top_enrichment_value,
                y: Math.abs(d.top_enrichment_z_score),
                svgProps: {
                    r: 5,
                    fill: d.motif_id === selectedMotifId ? 'red' : 'black',
                },
            })) || [];
        return points;
    }, [data, selectedMotifId]);

    if (error) {
        return <span>Error loading data.</span>;
    }

    const logo = hoverData && !hoverLoading && !hoverError && (
        <Logo alphabet={DNAAlphabet} ppm={hoverData.motif_enrichment[0].motif.pwm} width={'150px'} />
    );

    return (
        <>
            <MotifEnrichmentPlotMemo
                points={points}
                pointsLoading={loading}
                binData={binData}
                binLoading={binLoading}
                binError={binError}
                selectedPoint={selectedPoint}
                setSelectedPoint={setSelectedPoint}
                setHoverPoint={setHoverPoint}
            />
            {logo && <div style={{ position: 'fixed', left: hoverPoint?.[1], top: hoverPoint?.[2] }}>{logo}</div>}
        </>
    );
};

export default MotifEnrichment;
