import React, { useState, useCallback, CSSProperties } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import copyToClipboard from 'copy-to-clipboard';

const CopyButton = ({ onClick, style }) => (
    <Button style={style} onClick={onClick}>
        <Icon className="copy outline" />
        Copy
    </Button>
);

const CopiedButton = ({ onClick, style }) => (
    <Button style={style} onClick={onClick}>
        <Icon className="check" />
        Copied
    </Button>
);

const CopyToClipboardButton: React.FC<{ text?: string; getText?: () => string; style?: CSSProperties }> = ({
    text,
    getText,
    style,
}) => {
    const [copied, setCopied] = useState(false);
    const copy = useCallback(() => {
        const copyText = text || getText!();
        copyToClipboard(copyText);
        setCopied(true);
    }, [text, getText]);

    if (!text && !getText) {
        throw new Error('One of `text` or `getText` must be specified.');
    }

    return copied ? <CopiedButton style={style} onClick={copy} /> : <CopyButton style={style} onClick={copy} />;
};

export default CopyToClipboardButton;
