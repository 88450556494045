import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Header, Grid, Card } from 'semantic-ui-react';

const Downloads: React.FC = () => (
    <Container style={{ width: "90%", marginTop: "7em" }}>
        <Header as="h1" style={{ fontVariant: "small-caps", marginBottom: "0.1em" }}>Factorbook Downloads</Header>
        <Header as="h3" style={{ marginTop: "0em" }}>Select a Factorbook Data Set to Download:</Header>
        <Grid style={{ marginTop: '1em' }}>
            <Grid.Column width={4}>
                <Card style={{ width: "100%" }}>
                    <Card.Content><Card.Header><Link to="/motif/human/download">TF Motif Catalog</Link></Card.Header></Card.Content>
                    <Card.Content style={{ height: "200px" }}>
                        Motifs discovered using MEME on ChIP-seq experiments and the ZMotif neural network on HT-SELEX experiments.
                        The catalog contains more than 6,000 motifs for each (with some redundancy).
                    </Card.Content>
                    <Card.Content extra>
                        <Link to="/motif/human/download">Go to Downloads »</Link>
                    </Card.Content>
                </Card>
            </Grid.Column>
            <Grid.Column width={4}>
                <Card style={{ width: "100%" }}>
                    <Card.Content><Card.Header><Link to="/motif-sites/">Genomic Motif Sites</Link></Card.Header></Card.Content>
                    <Card.Content style={{ height: "200px" }}>
                        Motif sites identified by scanning ChIP-seq peaks and candidate cis-regulatory elements with FIMO. There are
                        approximately 6 million motif sites in ChIP-seq peaks and 7 million motif sites in candidate regulatory elements
                        after merging overlapping motif sites.
                    </Card.Content>
                    <Card.Content extra>
                        <Link to="/motif-sites/">Go to Downloads »</Link>
                    </Card.Content>
                </Card>
            </Grid.Column>
            <Grid.Column width={4}>
                <Card style={{ width: "100%" }}>
                    <Card.Content><Card.Header><Link to="/partitioned-ldr/hg38/">Heritability Models</Link></Card.Header></Card.Content>
                    <Card.Content style={{ height: "200px" }}>
                        Download Partitioned LD Score Regression models for quantifying trait and disease heritability enrichment within
                        TF ChIP-seq peaks or TF motif sites.
                    </Card.Content>
                    <Card.Content extra>
                        <Link to="/partitioned-ldr/hg38/">Go to Downloads »</Link>
                    </Card.Content>
                </Card>
            </Grid.Column>
        </Grid>
    </Container>
);
export default Downloads;
