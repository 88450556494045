export const TRACKS = {'brain': ['full_ENCSR001QTZ.profile.bigwig',
'full_ENCSR002IHL.profile.bigwig',
'full_ENCSR006MAW.profile.bigwig',
'full_ENCSR006XFA.profile.bigwig',
'full_ENCSR010SZN.profile.bigwig',
'full_ENCSR014GTH.profile.bigwig',
'full_ENCSR022UVL.profile.bigwig',
'full_ENCSR024BXN.profile.bigwig',
'full_ENCSR037PIU.profile.bigwig',
'full_ENCSR042DVU.profile.bigwig',
'full_ENCSR057GPV.profile.bigwig',
'full_ENCSR059ETS.profile.bigwig',
'full_ENCSR059NIN.profile.bigwig',
'full_ENCSR060MJY.profile.bigwig',
'full_ENCSR060UPU.profile.bigwig',
'full_ENCSR085XKS.profile.bigwig',
'full_ENCSR086PIL.profile.bigwig',
'full_ENCSR098ISE.profile.bigwig',
'full_ENCSR101HFF.profile.bigwig',
'full_ENCSR112AKE.profile.bigwig',
'full_ENCSR113DHB.profile.bigwig',
'full_ENCSR116UQH.profile.bigwig',
'full_ENCSR134IUJ.profile.bigwig',
'full_ENCSR137FWW.profile.bigwig',
'full_ENCSR161ZGA.profile.bigwig',
'full_ENCSR163BSC.profile.bigwig',
'full_ENCSR163GMC.profile.bigwig',
'full_ENCSR173NHL.profile.bigwig',
'full_ENCSR173YSO.profile.bigwig',
'full_ENCSR176BPJ.profile.bigwig',
'full_ENCSR195GUK.profile.bigwig',
'full_ENCSR209ECO.profile.bigwig',
'full_ENCSR210ESN.profile.bigwig',
'full_ENCSR215DXT.profile.bigwig',
'full_ENCSR217OHA.profile.bigwig',
'full_ENCSR221ROM.profile.bigwig',
'full_ENCSR234VLI.profile.bigwig',
'full_ENCSR239MQN.profile.bigwig',
'full_ENCSR258POP.profile.bigwig',
'full_ENCSR268GBM.profile.bigwig',
'full_ENCSR271QID.profile.bigwig',
'full_ENCSR274FZD.profile.bigwig',
'full_ENCSR276MDV.profile.bigwig',
'full_ENCSR297WRG.profile.bigwig',
'full_ENCSR305WAA.profile.bigwig',
'full_ENCSR306LNA.profile.bigwig',
'full_ENCSR311EEL.profile.bigwig',
'full_ENCSR329FAP.profile.bigwig',
'full_ENCSR330LFP.profile.bigwig',
'full_ENCSR333BUP.profile.bigwig',
'full_ENCSR334MDJ.profile.bigwig',
'full_ENCSR339TVH.profile.bigwig',
'full_ENCSR340XQX.profile.bigwig',
'full_ENCSR344FLH.profile.bigwig',
'full_ENCSR351FWN.profile.bigwig',
'full_ENCSR365FVU.profile.bigwig',
'full_ENCSR373TLU.profile.bigwig',
'full_ENCSR386XPD.profile.bigwig',
'full_ENCSR400MOP.profile.bigwig',
'full_ENCSR401UZL.profile.bigwig',
'full_ENCSR410MFU.profile.bigwig',
'full_ENCSR417LPB.profile.bigwig',
'full_ENCSR426KLJ.profile.bigwig',
'full_ENCSR428ZCP.profile.bigwig',
'full_ENCSR432QFU.profile.bigwig',
'full_ENCSR433NEX.profile.bigwig',
'full_ENCSR440BPA.profile.bigwig',
'full_ENCSR483HUW.profile.bigwig',
'full_ENCSR490UQP.profile.bigwig',
'full_ENCSR496PSH.profile.bigwig',
'full_ENCSR496UJY.profile.bigwig',
'full_ENCSR498RLX.profile.bigwig',
'full_ENCSR503UFM.profile.bigwig',
'full_ENCSR510GRY.profile.bigwig',
'full_ENCSR511PFY.profile.bigwig',
'full_ENCSR516RTV.profile.bigwig',
'full_ENCSR518WRP.profile.bigwig',
'full_ENCSR524QPZ.profile.bigwig',
'full_ENCSR532DBH.profile.bigwig',
'full_ENCSR555DLG.profile.bigwig',
'full_ENCSR558SUT.profile.bigwig',
'full_ENCSR563CZB.profile.bigwig',
'full_ENCSR570BWW.profile.bigwig',
'full_ENCSR571CJM.profile.bigwig',
'full_ENCSR572LDG.profile.bigwig',
'full_ENCSR577QZP.profile.bigwig',
'full_ENCSR579RVI.profile.bigwig',
'full_ENCSR581LHW.profile.bigwig',
'full_ENCSR584LUZ.profile.bigwig',
'full_ENCSR587SZC.profile.bigwig',
'full_ENCSR595CSH.profile.bigwig',
'full_ENCSR599JZA.profile.bigwig',
'full_ENCSR606QDB.profile.bigwig',
'full_ENCSR612IKQ.profile.bigwig',
'full_ENCSR617SVO.profile.bigwig',
'full_ENCSR628GQE.profile.bigwig',
'full_ENCSR635GDW.profile.bigwig',
'full_ENCSR637ZLM.profile.bigwig',
'full_ENCSR638PZJ.profile.bigwig',
'full_ENCSR639VTO.profile.bigwig',
'full_ENCSR645HET.profile.bigwig',
'full_ENCSR646JBR.profile.bigwig',
'full_ENCSR649MJI.profile.bigwig',
'full_ENCSR650TZF.profile.bigwig',
'full_ENCSR652IZW.profile.bigwig',
'full_ENCSR654GSG.profile.bigwig',
'full_ENCSR659JPP.profile.bigwig',
'full_ENCSR661AMM.profile.bigwig',
'full_ENCSR663MNQ.profile.bigwig',
'full_ENCSR670BGI.profile.bigwig',
'full_ENCSR671YPX.profile.bigwig',
'full_ENCSR677XGD.profile.bigwig',
'full_ENCSR682ZZT.profile.bigwig',
'full_ENCSR686LOE.profile.bigwig',
'full_ENCSR688AWP.profile.bigwig',
'full_ENCSR690CVL.profile.bigwig',
'full_ENCSR694DOX.profile.bigwig',
'full_ENCSR709PZI.profile.bigwig',
'full_ENCSR717BBR.profile.bigwig',
'full_ENCSR721DFB.profile.bigwig',
'full_ENCSR722HJG.profile.bigwig',
'full_ENCSR729FNL.profile.bigwig',
'full_ENCSR729TYE.profile.bigwig',
'full_ENCSR730IHD.profile.bigwig',
'full_ENCSR742HBK.profile.bigwig',
'full_ENCSR756FQD.profile.bigwig',
'full_ENCSR770JCP.profile.bigwig',
'full_ENCSR775KLH.profile.bigwig',
'full_ENCSR784GVR.profile.bigwig',
'full_ENCSR785MXI.profile.bigwig',
'full_ENCSR789FJF.profile.bigwig',
'full_ENCSR796YOJ.profile.bigwig',
'full_ENCSR797FIM.profile.bigwig',
'full_ENCSR800MBE.profile.bigwig',
'full_ENCSR802GEV.profile.bigwig',
'full_ENCSR802NBC.profile.bigwig',
'full_ENCSR811OUF.profile.bigwig',
'full_ENCSR814DYF.profile.bigwig',
'full_ENCSR819GAF.profile.bigwig',
'full_ENCSR822GDF.profile.bigwig',
'full_ENCSR832XVZ.profile.bigwig',
'full_ENCSR839GHA.profile.bigwig',
'full_ENCSR839RAJ.profile.bigwig',
'full_ENCSR846VGZ.profile.bigwig',
'full_ENCSR849CYU.profile.bigwig',
'full_ENCSR849WGE.profile.bigwig',
'full_ENCSR856XGY.profile.bigwig',
'full_ENCSR859HEW.profile.bigwig',
'full_ENCSR860UTH.profile.bigwig',
'full_ENCSR865CYW.profile.bigwig',
'full_ENCSR879EVD.profile.bigwig',
'full_ENCSR879XUH.profile.bigwig',
'full_ENCSR880CUB.profile.bigwig',
'full_ENCSR881FBS.profile.bigwig',
'full_ENCSR885WWJ.profile.bigwig',
'full_ENCSR885ZBV.profile.bigwig',
'full_ENCSR887VUC.profile.bigwig',
'full_ENCSR888ZIZ.profile.bigwig',
'full_ENCSR895TUV.profile.bigwig',
'full_ENCSR908MUU.profile.bigwig',
'full_ENCSR916MHX.profile.bigwig',
'full_ENCSR921PPJ.profile.bigwig',
'full_ENCSR923BEY.profile.bigwig',
'full_ENCSR924IHU.profile.bigwig',
'full_ENCSR928PKL.profile.bigwig',
'full_ENCSR943MRO.profile.bigwig',
'full_ENCSR947POC.profile.bigwig',
'full_ENCSR984HJW.profile.bigwig',
'full_ENCSR984SDD.profile.bigwig',
'full_ENCSR987CQN.profile.bigwig'],
'stomach': ['full_ENCSR006IMH.profile.bigwig',
'full_ENCSR208DMX.profile.bigwig',
'full_ENCSR260ZIV.profile.bigwig',
'full_ENCSR337UIU.profile.bigwig',
'full_ENCSR522FGG.profile.bigwig',
'full_ENCSR608AHQ.profile.bigwig',
'full_ENCSR782SSS.profile.bigwig'],
'kidney': ['full_ENCSR022ECC.profile.bigwig',
'full_ENCSR097BWW.profile.bigwig',
'full_ENCSR120LVW.profile.bigwig',
'full_ENCSR129KIV.profile.bigwig',
'full_ENCSR141DMX.profile.bigwig',
'full_ENCSR155NPL.profile.bigwig',
'full_ENCSR206OJJ.profile.bigwig',
'full_ENCSR297VGU.profile.bigwig',
'full_ENCSR540PVZ.profile.bigwig',
'full_ENCSR543YPH.profile.bigwig',
'full_ENCSR550UWM.profile.bigwig',
'full_ENCSR554WUJ.profile.bigwig',
'full_ENCSR580OAH.profile.bigwig',
'full_ENCSR619DRM.profile.bigwig',
'full_ENCSR656QYL.profile.bigwig',
'full_ENCSR757EPJ.profile.bigwig',
'full_ENCSR792ZXA.profile.bigwig',
'full_ENCSR851ZFT.profile.bigwig',
'full_ENCSR873ANE.profile.bigwig',
'full_ENCSR911YVV.profile.bigwig',
'full_ENCSR932DHT.profile.bigwig',
'full_ENCSR941DTJ.profile.bigwig',
'full_ENCSR990XXC.profile.bigwig'],
'musculature of body': ['full_ENCSR033STL.profile.bigwig',
'full_ENCSR040DJK.profile.bigwig',
'full_ENCSR072NBR.profile.bigwig',
'full_ENCSR082PDJ.profile.bigwig',
'full_ENCSR108SYM.profile.bigwig',
'full_ENCSR141NSQ.profile.bigwig',
'full_ENCSR217YRJ.profile.bigwig',
'full_ENCSR357PMV.profile.bigwig',
'full_ENCSR388DHS.profile.bigwig',
'full_ENCSR426TPQ.profile.bigwig',
'full_ENCSR585CGU.profile.bigwig',
'full_ENCSR609GST.profile.bigwig',
'full_ENCSR689SDA.profile.bigwig',
'full_ENCSR823ZCR.profile.bigwig',
'full_ENCSR886VCC.profile.bigwig',
'full_ENCSR890DWH.profile.bigwig'],
'heart': ['full_ENCSR053SGP.profile.bigwig',
'full_ENCSR074WMH.profile.bigwig',
'full_ENCSR085MZL.profile.bigwig',
'full_ENCSR117PYB.profile.bigwig',
'full_ENCSR133CMC.profile.bigwig',
'full_ENCSR133WJY.profile.bigwig',
'full_ENCSR154ZNQ.profile.bigwig',
'full_ENCSR167SYF.profile.bigwig',
'full_ENCSR204PZT.profile.bigwig',
'full_ENCSR204SMO.profile.bigwig',
'full_ENCSR212LYK.profile.bigwig',
'full_ENCSR213YPO.profile.bigwig',
'full_ENCSR214NLQ.profile.bigwig',
'full_ENCSR222CLC.profile.bigwig',
'full_ENCSR225FZH.profile.bigwig',
'full_ENCSR238FMP.profile.bigwig',
'full_ENCSR246VSO.profile.bigwig',
'full_ENCSR286STX.profile.bigwig',
'full_ENCSR298OIK.profile.bigwig',
'full_ENCSR299QGI.profile.bigwig',
'full_ENCSR310RJN.profile.bigwig',
'full_ENCSR310UDW.profile.bigwig',
'full_ENCSR327DCG.profile.bigwig',
'full_ENCSR330WUK.profile.bigwig',
'full_ENCSR355WAJ.profile.bigwig',
'full_ENCSR374VQC.profile.bigwig',
'full_ENCSR374ZRW.profile.bigwig',
'full_ENCSR390SLL.profile.bigwig',
'full_ENCSR392GCE.profile.bigwig',
'full_ENCSR395HAE.profile.bigwig',
'full_ENCSR399OSE.profile.bigwig',
'full_ENCSR437OOJ.profile.bigwig',
'full_ENCSR439TZT.profile.bigwig',
'full_ENCSR451JSB.profile.bigwig',
'full_ENCSR452OSK.profile.bigwig',
'full_ENCSR485UQY.profile.bigwig',
'full_ENCSR522FGI.profile.bigwig',
'full_ENCSR524QBS.profile.bigwig',
'full_ENCSR563ZNI.profile.bigwig',
'full_ENCSR584AXZ.profile.bigwig',
'full_ENCSR593YFB.profile.bigwig',
'full_ENCSR598RVJ.profile.bigwig',
'full_ENCSR622HTS.profile.bigwig',
'full_ENCSR710SMN.profile.bigwig',
'full_ENCSR731ODJ.profile.bigwig',
'full_ENCSR735NTV.profile.bigwig',
'full_ENCSR736CIN.profile.bigwig',
'full_ENCSR745CGG.profile.bigwig',
'full_ENCSR747SEU.profile.bigwig',
'full_ENCSR770OTB.profile.bigwig',
'full_ENCSR805EGZ.profile.bigwig',
'full_ENCSR822BAD.profile.bigwig',
'full_ENCSR846VPV.profile.bigwig',
'full_ENCSR848TMJ.profile.bigwig',
'full_ENCSR851EBF.profile.bigwig',
'full_ENCSR895GSY.profile.bigwig',
'full_ENCSR925LGW.profile.bigwig',
'full_ENCSR928WMU.profile.bigwig',
'full_ENCSR946MAI.profile.bigwig',
'full_ENCSR984SQJ.profile.bigwig'],
'endocrine gland': ['full_ENCSR053ZKP.profile.bigwig',
'full_ENCSR080PZL.profile.bigwig',
'full_ENCSR113MBR.profile.bigwig',
'full_ENCSR164TBP.profile.bigwig',
'full_ENCSR277KRY.profile.bigwig',
'full_ENCSR419MZH.profile.bigwig',
'full_ENCSR541UPY.profile.bigwig',
'full_ENCSR548KIL.profile.bigwig',
'full_ENCSR562FNN.profile.bigwig',
'full_ENCSR628NEA.profile.bigwig',
'full_ENCSR696XSJ.profile.bigwig',
'full_ENCSR838IPF.profile.bigwig',
'full_ENCSR866RCY.profile.bigwig',
'full_ENCSR933GMM.profile.bigwig'],
'pancreas': ['full_ENCSR056PFI.profile.bigwig',
'full_ENCSR236KPK.profile.bigwig',
'full_ENCSR242RPQ.profile.bigwig',
'full_ENCSR251POP.profile.bigwig',
'full_ENCSR338NEI.profile.bigwig',
'full_ENCSR405YLV.profile.bigwig',
'full_ENCSR464TKV.profile.bigwig',
'full_ENCSR515CDW.profile.bigwig',
'full_ENCSR530XBF.profile.bigwig',
'full_ENCSR539WBA.profile.bigwig',
'full_ENCSR595HZQ.profile.bigwig',
'full_ENCSR705KEB.profile.bigwig',
'full_ENCSR765MXG.profile.bigwig',
'full_ENCSR773USD.profile.bigwig',
'full_ENCSR808ZMK.profile.bigwig',
'full_ENCSR828FVZ.profile.bigwig',
'full_ENCSR918TVE.profile.bigwig',
'full_ENCSR999VDH.profile.bigwig'],
'lung': ['full_ENCSR058VBM.profile.bigwig',
'full_ENCSR141IUS.profile.bigwig',
'full_ENCSR242QRV.profile.bigwig',
'full_ENCSR357AWH.profile.bigwig',
'full_ENCSR441OGH.profile.bigwig',
'full_ENCSR456KDF.profile.bigwig',
'full_ENCSR510ITB.profile.bigwig',
'full_ENCSR555ZDH.profile.bigwig',
'full_ENCSR632ONQ.profile.bigwig',
'full_ENCSR647AOY.profile.bigwig',
'full_ENCSR650FLQ.profile.bigwig',
'full_ENCSR658UBE.profile.bigwig',
'full_ENCSR702DPD.profile.bigwig',
'full_ENCSR757NJT.profile.bigwig',
'full_ENCSR785JXR.profile.bigwig',
'full_ENCSR800ADR.profile.bigwig',
'full_ENCSR895SEG.profile.bigwig',
'full_ENCSR945JJB.profile.bigwig',
'full_ENCSR947VPG.profile.bigwig',
'full_ENCSR948MQC.profile.bigwig'],
'nerve': ['full_ENCSR072UYN.profile.bigwig',
'full_ENCSR120MOY.profile.bigwig',
'full_ENCSR508FVM.profile.bigwig',
'full_ENCSR533WFB.profile.bigwig',
'full_ENCSR619LHF.profile.bigwig',
'full_ENCSR831KAH.profile.bigwig'],
'intestine': ['full_ENCSR086OGH.profile.bigwig',
'full_ENCSR340MRJ.profile.bigwig',
'full_ENCSR354FBA.profile.bigwig',
'full_ENCSR355SGJ.profile.bigwig',
'full_ENCSR404LLJ.profile.bigwig',
'full_ENCSR455GUW.profile.bigwig',
'full_ENCSR507DQC.profile.bigwig',
'full_ENCSR548QCP.profile.bigwig',
'full_ENCSR563XRP.profile.bigwig',
'full_ENCSR761TKU.profile.bigwig',
'full_ENCSR931UQB.profile.bigwig'],
'limb': ['full_ENCSR102RSU.profile.bigwig',
'full_ENCSR166TEE.profile.bigwig',
'full_ENCSR167XSQ.profile.bigwig',
'full_ENCSR258JCL.profile.bigwig',
'full_ENCSR272RQX.profile.bigwig',
'full_ENCSR303YII.profile.bigwig',
'full_ENCSR308HPZ.profile.bigwig',
'full_ENCSR361DND.profile.bigwig',
'full_ENCSR484UAU.profile.bigwig',
'full_ENCSR520BAD.profile.bigwig',
'full_ENCSR622CAH.profile.bigwig',
'full_ENCSR630REB.profile.bigwig',
'full_ENCSR818JGZ.profile.bigwig'],
'exocrine gland': ['full_ENCSR124NNL.profile.bigwig',
'full_ENCSR608NWP.profile.bigwig',
'full_ENCSR802ZYE.profile.bigwig',
'full_ENCSR836FIL.profile.bigwig',
'full_ENCSR952SPO.profile.bigwig'],
'placenta': ['full_ENCSR126EVK.profile.bigwig',
'full_ENCSR129TSG.profile.bigwig',
'full_ENCSR267UFM.profile.bigwig',
'full_ENCSR336RGT.profile.bigwig',
'full_ENCSR395EZY.profile.bigwig',
'full_ENCSR499IFY.profile.bigwig',
'full_ENCSR545KQR.profile.bigwig',
'full_ENCSR552RKI.profile.bigwig',
'full_ENCSR552XJI.profile.bigwig',
'full_ENCSR566VAK.profile.bigwig',
'full_ENCSR656KLT.profile.bigwig',
'full_ENCSR685PCS.profile.bigwig',
'full_ENCSR689FYA.profile.bigwig',
'full_ENCSR802AJE.profile.bigwig',
'full_ENCSR831QMZ.profile.bigwig',
'full_ENCSR922YEW.profile.bigwig'],
'immune organ': ['full_ENCSR128GBN.profile.bigwig',
'full_ENCSR158KCS.profile.bigwig',
'full_ENCSR694OWY.profile.bigwig',
'full_ENCSR946OIO.profile.bigwig',
'full_ENCSR979TCG.profile.bigwig'],
'large intestine': ['full_ENCSR133SPH.profile.bigwig',
'full_ENCSR276ITP.profile.bigwig',
'full_ENCSR386HAZ.profile.bigwig',
'full_ENCSR491VXJ.profile.bigwig',
'full_ENCSR496PPU.profile.bigwig',
'full_ENCSR576UAF.profile.bigwig',
'full_ENCSR600ZHS.profile.bigwig',
'full_ENCSR796SJV.profile.bigwig',
'full_ENCSR846VLJ.profile.bigwig',
'full_ENCSR994KTY.profile.bigwig'],
'ovary': ['full_ENCSR138UGH.profile.bigwig',
'full_ENCSR330ZBO.profile.bigwig',
'full_ENCSR365NDK.profile.bigwig',
'full_ENCSR392UJM.profile.bigwig',
'full_ENCSR490MSG.profile.bigwig',
'full_ENCSR798XLO.profile.bigwig',
'full_ENCSR872DUY.profile.bigwig',
'full_ENCSR996ZCR.profile.bigwig'],
'thyroid gland': ['full_ENCSR195ONB.profile.bigwig',
'full_ENCSR201FIW.profile.bigwig',
'full_ENCSR450PWF.profile.bigwig',
'full_ENCSR474XFV.profile.bigwig',
'full_ENCSR914DTI.profile.bigwig'],
'adrenal gland': ['full_ENCSR241OBO.profile.bigwig',
'full_ENCSR259GYP.profile.bigwig',
'full_ENCSR414DVK.profile.bigwig',
'full_ENCSR541JMK.profile.bigwig',
'full_ENCSR553LAZ.profile.bigwig',
'full_ENCSR728BAD.profile.bigwig',
'full_ENCSR962VJO.profile.bigwig'],
'colon': ['full_ENCSR279SXQ.profile.bigwig',
'full_ENCSR404TSP.profile.bigwig',
'full_ENCSR612CTW.profile.bigwig',
'full_ENCSR867XFA.profile.bigwig',
'full_ENCSR923JYH.profile.bigwig'],
'vasculature': ['full_ENCSR295TFY.profile.bigwig',
'full_ENCSR298SDT.profile.bigwig',
'full_ENCSR324NVG.profile.bigwig',
'full_ENCSR552QUA.profile.bigwig',
'full_ENCSR713KNQ.profile.bigwig']};

function generateSequentialColors(n: number) {
    const colors: string[] = [];
    const hueIncrement = 360 / n;
    for (let i = 0; i < n; i++) {
      const hue = i * hueIncrement;
      const color = `hsl(${hue}, 50%, 50%)`;
      colors.push(color);
    }
    return colors;
}
  

export const TRACK_SUBSETS = (() => {
    const keys = Object.keys(TRACKS).sort();
    const colors = generateSequentialColors(keys.length)
    const tracks: [string, string, string[]][] = [];
    keys.forEach((key, i) => {
        tracks.push([ key, colors[i], TRACKS[key].slice(0, 5) ]);
    });
    return tracks;
})();
