import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { cellTypeRoute } from '../../routing';
import Summary from './summary';

const CellTypePage: React.FC = () => {
    const { species, celltype, details } = useParams<{ species: string; celltype: string; details: string }>();
    const assembly = species === 'human' ? 'GRCh38' : 'mm10';

    if (!details) return <Redirect to={cellTypeRoute(species, celltype, 'summary')} />;
    return (
        <div style={{ paddingTop: '5em', paddingLeft: '1em', position: 'relative' }}>
            <div style={{ marginTop: '1rem' }}>
                {details.toLowerCase() === 'summary' ? (
                    <Summary assembly={assembly} celltype={celltype} species={species} />
                ) : (
                    <Redirect to={cellTypeRoute(species, celltype, 'summary')} />
                )}
            </div>
        </div>
    );
};
export default CellTypePage;
