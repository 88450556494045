import * as React from 'react';

import mouseIcon from '../../../images/mouse.png';
import { HeaderProps } from './speciesheader';

const MouseHeader: React.SFC<HeaderProps> = props => (
    <svg viewBox="0 0 1200 200">
        <g transform="translate(1000,0)">
            <image width={200} height={200} xlinkHref={mouseIcon} />
        </g>
        <line x1={0} x2={1000} y1={102} y2={102} strokeWidth={2.5} stroke="#888888" />
        <text style={{ fontSize: '60px', textAnchor: 'end' }} x={970} y={80} color="">
            Search Mouse TFs
        </text>
        {props.counts && (
            <text style={{ fontSize: '24px', textAnchor: 'end' }} x={970} y={140} fill="#666666">
                {props.counts!.total} experiments &#8231;&nbsp;
                {props.counts!.targets} transcription factors &#8231;&nbsp;
                {props.counts!.biosamples} cell types
            </text>
        )}
    </svg>
);
export default MouseHeader;
