import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import 'semantic-ui-css/semantic.min.css';
import './index.css';
import 'ts-ztable/src/styles.css';

import Factorbook from './app';
import { FactorbookConfig } from './config';

ReactGA.initialize('UA-93680006-2');
ReactGA.pageview(window.location.pathname + window.location.search);

var xhttp = new XMLHttpRequest();
let data: FactorbookConfig | undefined = undefined;
xhttp.onreadystatechange = function() {
    if (this.readyState === 4 && this.status === 200) {
        // Typical action to be performed when the document is ready:
        try {
            data = JSON.parse(xhttp.responseText);
        } catch {}
        if (data && Object.keys(data).length > 0) {
            ReactDOM.render(<Factorbook config={data} />, document.getElementById('root'));
        } else {
            // FIXME: this should be completely specified by the config type
            ReactDOM.render(<Factorbook config={{} as any} />, document.getElementById('root'));
        }
    }
};

xhttp.open('GET', `/config.json`, true);
xhttp.send();
