import { groupBy } from 'queryz';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Grid, Header, Icon, Loader, Menu, Message } from 'semantic-ui-react';
import { formatFactorName } from 'utilities/misc';
import { useGeneExpressionData } from './hooks';
import { GeneExpressionPageProps } from './types';
import { downloadTSV, spacedColors, downloadSVG } from './utils';
import { ViolinPlotMousedOverState } from './violin/types';
import ViolinPlot from './violin/violin';

const GeneExpressionPage: React.FC<GeneExpressionPageProps> = props => {
    const [polyA, setPolyA] = useState(false);
    const { data, loading } = useGeneExpressionData(
        props.assembly,
        formatFactorName(props.gene_name, props.assembly === 'mm10' ? 'mouse' : 'human'),
        polyA ? 'polyA plus RNA-seq' : 'total RNA-seq'
    );
    const biosampleTypes = new Set(data?.gene_dataset.map(x => x.biosample_type) || []);
    const [biosampleType, setBiosampleType] = useState(2);
    const [mousedOver, setMousedOver] = useState<ViolinPlotMousedOverState>({ inner: null, outer: null });

    const sortedBiosampleTypes = useMemo(
        () => [...biosampleTypes].sort().filter(x => x !== 'in vitro differentiated cells'),
        [biosampleTypes]
    );
    const ref = useRef<SVGSVGElement>(null);

    const grouped = useMemo(
        () =>
            groupBy(
                data?.gene_dataset.filter(x => x.gene_quantification_files.length > 0) || [],
                x => x.biosample_type,
                x => x
            ),
        [data]
    );
    const subGrouped = useMemo(
        () =>
            groupBy(
                grouped.get(sortedBiosampleTypes[biosampleType]) || [],
                x => (sortedBiosampleTypes[biosampleType] === 'tissue' ? x.tissue : x.biosample),
                x => x
            ),
        [grouped, sortedBiosampleTypes, biosampleType]
    );
    const sortedKeys = useMemo(
        () =>
            [...subGrouped.keys()]
                .filter(
                    x =>
                        x !== null &&
                        subGrouped
                            .get(x)!
                            .flatMap(x => x.gene_quantification_files)
                            .filter(x => x.quantifications[0]?.tpm !== undefined).length > 2
                )
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
        [subGrouped]
    );
    const toPlot = useMemo(
        () =>
            new Map(
                sortedKeys
                    .map(
                        x =>
                            [
                                x,
                                new Map([
                                    [
                                        'all',
                                        subGrouped
                                            .get(x)!
                                            .flatMap(x =>
                                                x.gene_quantification_files.map(x => x.quantifications[0]?.tpm)
                                            )
                                            .filter(x => x !== undefined)
                                            .map(x => Math.log10(x! + 0.01)),
                                    ],
                                ]),
                            ] as [string, Map<string, number[]>]
                    )
                    .filter(x => x[1].get('all')!.length > 1)
            ),
        [sortedKeys, subGrouped]
    );
    const domain: [number, number] = useMemo(() => {
        const values = [...toPlot.values()].flatMap(x => x.get('all')!);
        return [Math.log10(0.01), Math.max(...values, 4.5)];
    }, [toPlot]);
    const width = useMemo(() => {
        const keys = [...toPlot.keys()].length;
        return (28 + (keys < 27 ? 27 : keys)) * 200;
    }, [toPlot]);
    const color = useCallback(spacedColors(sortedKeys.length), [sortedKeys]);
    const download = useCallback(() => {
        downloadTSV(
            'cell type\ttissue ontology\tbiosample type\texperiment accession\tfile accession\tTPM\n' +
                (
                    data?.gene_dataset.flatMap(x =>
                        x.gene_quantification_files.flatMap(q =>
                            q.quantifications
                                .filter(x => x.tpm !== undefined)
                                .map(
                                    v =>
                                        `${x.biosample}\t${x.tissue}\t${x.biosample_type}\t${x.accession}\t${
                                            q.accession
                                        }\t${v.tpm!.toFixed(2)}`
                                )
                        )
                    ) || []
                ).join('\n'),
            `factorbook-${props.gene_name}-expression.tsv`
        );
    }, [props.gene_name, data]);

    return loading ? (
        <Loader active style={{ marginTop: '7em' }}>
            Loading...
        </Loader>
    ) : (
        <Grid style={{ width: '90%' }}>
            <Grid.Column width={3}>
                <Menu vertical secondary>
                    <Menu.Item>
                        <strong>Select a biosample type:</strong>
                    </Menu.Item>
                    {sortedBiosampleTypes.map((t, i) => (
                        <Menu.Item key={t} onClick={() => setBiosampleType(i)} active={i === biosampleType}>
                            {t}s
                        </Menu.Item>
                    ))}
                </Menu>
            </Grid.Column>
            <Grid.Column width={13}>
                <Menu secondary pointing>
                    <Menu.Item active={polyA} onClick={() => setPolyA(true)}>
                        Poly-A enriched
                    </Menu.Item>
                    <Menu.Item active={!polyA} onClick={() => setPolyA(false)}>
                        Total RNA-seq
                    </Menu.Item>
                </Menu>
                <Header as="h3" style={{ marginLeft: '5em' }}>
                    {props.gene_name} expression in {sortedBiosampleTypes[biosampleType]}s: RNA-seq
                </Header>
                <Button size="tiny" style={{ marginLeft: '7.5em' }} onClick={download}>
                    <Icon name="download" />
                    Download all {polyA ? 'poly-A enriched' : 'total RNA-seq'} expression data for {props.gene_name}
                </Button>&nbsp;
                <Button size="tiny" style={{ marginLeft: '7.5em' }} onClick={() => ref.current && downloadSVG(ref, `${props.gene_name}-gene-expression.svg`)}>
                    <Icon name="download" />
                    Export plot as SVG
                </Button>
                {toPlot.size > 0 ? (
                    <svg viewBox={`0 0 ${width} ${width / 2}`} style={{ width: '100%' }} ref={ref}>
                        <ViolinPlot
                            data={toPlot}
                            title="log10 TPM"
                            width={width}
                            height={width / 2}
                            colors={new Map(sortedKeys.map((x, i) => [x, color(i)]))}
                            domain={domain}
                            tKeys={28}
                            onViolinMousedOut={() => setMousedOver({ inner: null, outer: null })}
                            onViolinMousedOver={setMousedOver}
                            mousedOver={mousedOver}
                        />
                    </svg>
                ) : (
                    <Message error style={{ marginLeft: '6.5em', width: '70%' }}>
                        There is no expression data available for the assay and biosample combination you have selected.
                        Please use the menus above and to the left of this message to select a different combination.
                    </Message>
                )}
            </Grid.Column>
        </Grid>
    );
};
export default GeneExpressionPage;
