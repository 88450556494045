import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import React, { useRef, useState, useCallback, useMemo } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import {
    GenomeBrowser,
    GraphQLTranscriptTrack,
    WrappedTrack,
    EmptyTrack,
    WrappedSquishTranscriptTrack,
    WrappedPackTranscriptTrack,
    UCSCControls,
    MotifTrack,
    WrappedDenseMotif,
    WrappedRulerTrack,
    GraphQLTrackSet,
    WrappedFullBigWig
} from 'umms-gb';
import { GraphQLImportanceTrack } from 'bpnet-ui';

import { MemeMotif } from '../types';
import CytobandView from './Cytobands';
import Searchbox from './searchbox';

export const parseDomain = (domain: string) => ({
    chromosome: domain.split(':')[0],
    start: +domain
        .split(':')[1]
        .split('-')[0]
        .replace(/,/g, ''),
    end: +domain.split('-')[1].replace(/,/g, ''),
});

export const DEFAULT_TRACKS_HG38: Map<string, { url: string }> = new Map([
    [ "PhyloP 100-way", { url: "gs://gcp.wenglab.org/factorbook-download/phyloP-100-way.hg38.bigWig" } ],
    [ "DNase", { url: "gs://data.genomealmanac.org/dnase.hg38.sum.bigWig" } ]
]);

export const DEFAULT_TRACKS_MM10: Map<string, { url: string }> = new Map([
    [ "PhyloP 60-way", { url: "gs://gcp.wenglab.org/factorbook-download/phyloP-60-way.mm10.bigWig" } ],
    [ "DNase", { url: "gs://data.genomealmanac.org/dnase.mm10.sum.bigWig" } ]
]);

export const DEFAULT_TRACKS = new Map([ [ "mm10", DEFAULT_TRACKS_MM10 ], [ "grch38", DEFAULT_TRACKS_HG38 ] ]);

export const TRACK_ORDER = new Map([ [ "GRCh38", [ "PhyloP 100-way", "DNase" ] ], [ "mm10", [ "PhyloP 60-way", "DNase" ] ] ]);

export const COLOR_MAP: Map<string, string> = new Map([
    [ "DNase", "#06DA93" ],
    [ "PhyloP 100-way", "#000088" ],
    [ "PhyloP 60-way", "#000088" ]
]);

const QUERY = gql`
query signal($accession: [String], $assembly: String) {
    peakDataset(accession: $accession) {
      datasets {
        files(types: [ "normalized_signal" ], assembly: $assembly) {
          accession
        }
      }
    }
}
`;

const MotifGenomeBrowser: React.FC<{ peaks_accession: string; eacc: string; consensus_regex: string; assembly: string, motif?: MemeMotif }> = props => {
    let peaks_accession = props.peaks_accession;
    let consensus_regex = props.consensus_regex;
    const svg = useRef<SVGSVGElement>(null);

    const client = useMemo( () => new ApolloClient({ cache: new InMemoryCache(), uri: "https://ga.staging.wenglab.org/graphql" }), []);
    const { data, loading } = useQuery(QUERY, {
        variables: { assembly: props.assembly, accession: props.eacc },
        client
    });

    const [domain, setDomain] = useState<any>({ chromosome: 'chr18', start: 35494852, end: 35514000 });
    const onDomainChanged = useCallback(
        d => {
            const chr = d.chromosome === undefined ? domain.chromosome : d.chromosome;
            setDomain({ chromosome: chr, ...d });
        },
        [domain]
    );
    const [defaultTracks, setDefaultTracks] = useState<Record<string, string>>({
        transcript: 'squish',
        asn: '',
        afr: '',
        eur: '',
        amr: '',
        motiftrack: '',
    });
    const pos = useMemo( () => ({ chr1: domain.chromosome, start: domain.start, end: domain.end, chr2: domain.chromosome }), [ domain ]);
    const gtracks = useMemo( () => data && data.peakDataset.datasets.length > 0 && data.peakDataset.datasets[0].files[0] ? [{
        ...pos, url: `https://www.encodeproject.org/files/${data.peakDataset.datasets[0].files[0].accession}/@@download/${data.peakDataset.datasets[0].files[0].accession}.bigWig`,
        preRenderedWidth: 1850
    }] : [], [ data, pos ]);
    const tracks = useMemo( () => [ ...TRACK_ORDER.get(props.assembly)! ].map(x => ({ ...pos, ...DEFAULT_TRACKS.get(props.assembly.toLocaleLowerCase())!.get(x)!, preRenderedWidth: 1850 })), [ pos, props ]);

    return (
        <Container style={{ width: "90%", marginTop: "5em" }}>
            <Grid columns={1} centered>
                <Grid.Row textAlign="center">
                    <Grid.Column width={16} textAlign='center'>
                        <CytobandView motif={props.motif} client={client} assembly={props.assembly} chromosome={domain.chromosome} position={domain} innerWidth={2000} height={30} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row textAlign="center">
                    <Grid.Column width={16} textAlign='center'>
                        <UCSCControls onDomainChanged={onDomainChanged} domain={domain} withInput={false} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Column width={10}>
                    <Grid.Row textAlign="center">
                        <Searchbox
                            onSearchSubmit={(domain: string, name?: string, isSnp?: boolean) => {
                                let d: any = parseDomain(domain);
                                if (isSnp) {
                                    d = {
                                        ...d,
                                        start: d.start - 10000,
                                        end: d.end + 10000,
                                    };
                                }
                                onDomainChanged(d);
                            }}
                            assembly={props.assembly}
                        />
                    </Grid.Row>
                </Grid.Column>
                <Grid.Row textAlign="center">
                    <Grid.Column textAlign={'center'}>
                        <GenomeBrowser
                            onDomainChanged={onDomainChanged}
                            svgRef={svg}
                            domain={domain}
                            innerWidth={2000}
                            width="100%"
                            onModeChange={(id: string, mode: string) => {
                                if (defaultTracks[id] !== undefined) {
                                    let dTracks = { ...defaultTracks };
                                    dTracks[id] = mode;
                                    setDefaultTracks(dTracks);
                                }
                            }}
                        >
                            <WrappedRulerTrack
                                width={2000}
                                height={80}
                                title="scale"
                                id="ruler"
                                domain={domain}
                                titleSize={12}
                                trackMargin={12}
                            />
                            {defaultTracks['transcript'] === 'hide' ? (
                                <WrappedTrack width={2000} height={0} id={'wrappedemptytranscript'}>
                                    <EmptyTrack
                                        width={2000}
                                        transform={'translate (0,0)'}
                                        height={0}
                                        id={'emptytranscript'}
                                    />
                                </WrappedTrack>
                            ) : (
                                <GraphQLTranscriptTrack
                                    id={'transcript'}
                                    domain={domain}
                                    transform={'translate (0,0)'}
                                    assembly={props.assembly}
                                    endpoint={'https://ga.staging.wenglab.org/graphql'}
                                >
                                    {defaultTracks['transcript'] === 'squish' || domain.end - domain.start <= 500000 ? (
                                        <WrappedSquishTranscriptTrack
                                            color="#8b0000"
                                            titleSize={15}
                                            trackMargin={12}
                                            title={'GENCODE v29 transcripts'}
                                            id="transcript"
                                            rowHeight={14}
                                            width={2000}
                                            domain={domain}
                                        />
                                    ) : (
                                        <WrappedPackTranscriptTrack
                                            color="#8b0000"
                                            titleSize={15}
                                            trackMargin={12}
                                            title={'GENCODE v29 transcripts'}
                                            id="transcript"
                                            rowHeight={14}
                                            width={2000}
                                            domain={domain}
                                        />
                                    )}
                                </GraphQLTranscriptTrack>
                            )}
                            <GraphQLTrackSet id="main" tracks={tracks} transform="" endpoint="https://ga.staging.wenglab.org/graphql" width={2000}>
                                { TRACK_ORDER.get(props.assembly)!.map( m => (
                                    <WrappedFullBigWig
                                        titleSize={15}
                                        title={m === "PhyloP 100-way" || m === "PhyloP 60-way" ? m : `aggregated ${m} signal across all ENCODE biosamples`}
                                        width={2000}
                                        height={100}
                                        domain={domain}
                                        id={m}
                                        key={m}
                                        color={COLOR_MAP.get(m)}
                                    />
                                ))}
                            </GraphQLTrackSet>
                            {defaultTracks['motiftrack'] === 'hide' ? (
                                <WrappedTrack width={2000} height={0} id={'wrappedemptymotiftrack'}>
                                    <EmptyTrack
                                        width={2000}
                                        transform={'translate (0,0)'}
                                        height={0}
                                        id={'emptymotiftrack'}
                                    />
                                </WrappedTrack>
                            ) : domain.end - domain.start <= 500000 ? (
                                <MotifTrack
                                    track={{
                                        chromosome: domain.chromosome,
                                        end: domain.end,
                                        start: domain.start,
                                        peaks_accession,
                                        consensus_regex,
                                    }}
                                    assembly={props.assembly}
                                    transform={'translate (0,0)'}
                                    id={'motiftrack'}
                                    width={2000}
                                    endpoint={'https://ga.staging.wenglab.org/graphql'}
                                >
                                    <WrappedDenseMotif
                                        width={2000}
                                        titleSize={15}
                                        trackMargin={12}
                                        title={`ChIP-seq peaks with motif sites (${peaks_accession})`}
                                        height={50}
                                        domain={domain}
                                        id="motiftrack"
                                    />
                                </MotifTrack>
                            ) : (
                                <WrappedTrack id="wrappedemptymotiftrack" width={2000} height={50}>
                                    <EmptyTrack
                                        id={'emptymotiftrack'}
                                        height={50}
                                        width={2000}
                                        text={'Zoom in to view motif track'}
                                        transform={'translate (0,0)'}
                                    />
                                </WrappedTrack>
                            )}
                            { !loading && gtracks && gtracks[0]?.url && (
                                <GraphQLTrackSet id="main" tracks={gtracks} transform="" endpoint="https://ga.staging.wenglab.org/graphql" width={2000}>
                                    <WrappedFullBigWig
                                        titleSize={15}
                                        title={`ChIP-seq signal (${data?.peakDataset.datasets[0].files[0]?.accession || ""})`}
                                        width={2000}
                                        height={100}
                                        domain={domain}
                                        id="signal"
                                        color="#3287a8"
                                    />
                                </GraphQLTrackSet>
                            ) }
                            { domain.end - domain.start < 2000 && (
                                <WrappedTrack
                                    width={2000}
                                    height={100}
                                    id=""
                                    title="Sequence Scaled by phyloP 100-way"
                                    titleSize={20}
                                >
                                    <GraphQLImportanceTrack
                                        width={1850}
                                        height={100}
                                        endpoint="https://ga.staging.wenglab.org"
                                        signalURL="gs://gcp.wenglab.org/hg38.phyloP100way.bigWig"
                                        sequenceURL="gs://gcp.wenglab.org/hg38.2bit"
                                        coordinates={domain}
                                        key={`${pos.start}-${pos.end}`}
                                    />
                                </WrappedTrack>
                            )}
                        </GenomeBrowser>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

export default MotifGenomeBrowser;

/*
{props.assembly === 'GRCh38' && (
    <GraphQLLDTrack
        domain={domain}
        width={2000}
        transform="translate (0,0)"
        id="ldtracks"
        populations={POPULATIONS}
        anchor={anchor}
        assembly={'hg38'}
        endpoint={'https://ga.staging.wenglab.org/graphql'}
    >
        {defaultTracks['afr'] === 'hide' ? (
            <WrappedTrack width={2000} height={0} id={'wrappedemptyldafr'}>
                <EmptyTrack
                    width={2000}
                    transform={'translate (0,0)'}
                    height={0}
                    id={'emptyldafr'}
                />
            </WrappedTrack>
        ) : (
            <WrappedLDTrack
                height={70}
                domain={domain}
                width={2000}
                titleSize={15}
                id="afr"
                title={'Common African SNPs with Linkage Disequilibrium'}
                ldThreshold={0.1}
                onVariantClick={snp => {
                    setAnchor(snp.id);
                }}
            />
        )}
        {defaultTracks['amr'] === 'hide' ? (
            <WrappedTrack width={2000} height={0} id={'wrappedemptyldamr'}>
                <EmptyTrack
                    width={2000}
                    transform={'translate (0,0)'}
                    height={0}
                    id={'emptyldamr'}
                />
            </WrappedTrack>
        ) : (
            <WrappedLDTrack
                height={70}
                domain={domain}
                width={2000}
                titleSize={15}
                id="amr"
                title={'Common American SNPs with Linkage Disequilibrium'}
                ldThreshold={0.7}
                onVariantClick={snp => {
                    setAnchor(snp.id);
                }}
            />
        )}
        {defaultTracks['eas'] === 'hide' ? (
            <WrappedTrack width={2000} height={0} id={'wrappedemptyldeas'}>
                <EmptyTrack
                    width={2000}
                    transform={'translate (0,0)'}
                    height={0}
                    id={'emptyldeas'}
                />
            </WrappedTrack>
        ) : (
            <WrappedLDTrack
                height={70}
                domain={domain}
                titleSize={15}
                width={2000}
                id="eas"
                title={'Common East Asian SNPs with Linkage Disequilibrium'}
                ldThreshold={0.7}
                onVariantClick={snp => {
                    setAnchor(snp.id);
                }}
            />
        )}
        {defaultTracks['eur'] === 'hide' ? (
            <WrappedTrack width={2000} height={0} id={'wrappedemptyldeur'}>
                <EmptyTrack
                    width={2000}
                    transform={'translate (0,0)'}
                    height={0}
                    id={'emptyldeur'}
                />
            </WrappedTrack>
        ) : (
            <WrappedLDTrack
                height={70}
                domain={domain}
                width={2000}
                titleSize={15}
                id="eur"
                title={'Common European SNPs with Linkage Disequilibrium'}
                ldThreshold={0.7}
                onVariantClick={snp => {
                    setAnchor(snp.id);
                }}
            />
        )}
        {defaultTracks['sas'] === 'hide' ? (
            <WrappedTrack width={2000} height={0} id={'wrappedemptyldsas'}>
                <EmptyTrack
                    width={2000}
                    transform={'translate (0,0)'}
                    height={0}
                    id={'emptyldsas'}
                />
            </WrappedTrack>
        ) : (
            <WrappedLDTrack
                height={70}
                domain={domain}
                width={2000}
                titleSize={15}
                id="sas"
                title={'Common South Asian SNPs with Linkage Disequilibrium'}
                ldThreshold={0.7}
                onVariantClick={snp => {
                    setAnchor(snp.id);
                }}
            />
        )}
    </GraphQLLDTrack>
)}
*/