import React, { useContext, useState, useEffect } from 'react';
import { Grid, Loader, Icon, Pagination } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { ApiContext } from 'apicontext';

import { logLikelihood } from '../utilities';
import { reverseComplement } from '../../tf/motifenrichment/motifs';
import { MOTIF_SEARCH_QUERY } from './queries';
import MotifResult from './result';
import { MotifSearchResultsPaneProps, MotifSearchQueryResult } from './types';

const MOTIFS_PER_PAGE = 3;

export const MotifSearchResultSet: React.FC<MotifSearchResultsPaneProps> = ({
    species,
    assembly,
    pwm,
    offset,
    onResultsLoaded,
}) => {
    const client = useContext(ApiContext).client;
    const { data, loading } = useQuery<MotifSearchQueryResult>(MOTIF_SEARCH_QUERY, {
        client,
        variables: {
            assembly,
            pwms: [pwm],
            offset: (offset || 0) * MOTIFS_PER_PAGE,
            limit: MOTIFS_PER_PAGE,
        },
    });
    useEffect(() => {
        data && onResultsLoaded && onResultsLoaded(data.meme_motif_search[0].total);
    });

    return (
        <Grid divided="vertically" style={{ overflowY: 'auto' }}>
            <Loader active={loading}>Searching...</Loader>
            {data &&
                data.meme_motif_search[0].results.map((m, i) => {
                    const rpwm = m.motif.pwm.map(logLikelihood([0.25, 0.25, 0.25, 0.25]));
                    const alignment = {
                        ...m,
                        motif: {
                            ...m.motif,
                            pwm: m.reverseComplement ? reverseComplement(rpwm) : rpwm,
                        },
                    };
                    return (
                        <MotifResult
                            key={i}
                            peak_accession={m.motif.peaks_accession}
                            alignment={alignment}
                            query={pwm}
                            species={species}
                            tomtom_match={m.motif.tomtom_matches?.slice().sort((a, b) => a.e_value - b.e_value)[0]}
                        />
                    );
                })}
        </Grid>
    );
};

const MotifSearchResultsPane: React.FC<MotifSearchResultsPaneProps> = ({ species, assembly, pwm }) => {
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);

    return (
        <>
            <MotifSearchResultSet
                species={species}
                assembly={assembly}
                pwm={pwm}
                offset={offset}
                onResultsLoaded={setTotal}
            />
            {total > 0 && (
                <Pagination
                    activePage={offset + 1}
                    onPageChange={(_: any, v: any) => setOffset(v.activePage - 1)}
                    totalPages={Math.ceil(total / MOTIFS_PER_PAGE)}
                    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                    firstItem={{ content: <Icon name="angle double left" />, icon: true }}
                    lastItem={{ content: <Icon name="angle double right" />, icon: true }}
                    prevItem={{ content: <Icon name="angle left" />, icon: true }}
                    nextItem={{ content: <Icon name="angle right" />, icon: true }}
                />
            )}
        </>
    );
};
export default MotifSearchResultsPane;
