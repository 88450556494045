import gql from 'graphql-tag';

export const MOTIF_ENRICHMENT_BIOSAMPLES_QUERY = gql`
    query MotifEnrichmentBiosamples {
        motif_enrichment_biosamples
    }
`;

export interface MOTIF_ENRICHMENT_BIOSAMPLES_QUERY_DATA {
    motif_enrichment_biosamples: string[];
}

export const MOTIF_ENRICHMENT_QUERY = gql`
    query MotifEnrichment($motif_id: String, $biosample: String) {
        motif_enrichment(motif_id: $motif_id, biosample: $biosample) {
            motif_id
            top_enrichment_z_score
            top_enrichment_value
        }
    }
`;

export interface MOTIF_ENRICHMENT_QUERY_DATA {
    motif_enrichment: {
        motif_id: string;
        top_enrichment_z_score: number;
        top_enrichment_value: number;
    }[];
}

export const MOTIF_ENRICHMENT_BINS_QUERY = gql`
    query MotifEnrichmentBins($motif_id: String, $biosample: String) {
        motif_enrichment(motif_id: $motif_id, biosample: $biosample) {
            bins
            p_value
            top_enrichment_z_score
            top_enrichment_value
            motif {
                peaks_accession
                pwm
                peaks_file {
                    dataset {
                        accession
                        biosample
                        target
                    }
                }
            }
        }
    }
`;

export interface MOTIF_ENRICHMENT_BINS_QUERY_DATA {
    motif_enrichment: {
        bins: number[];
        p_value: number;
        top_enrichment_z_score: number;
        top_enrichment_value: number;
        motif: {
            peaks_accession: string;
            pwm: number[][];
            peaks_file: {
                dataset: {
                    accession: string;
                    biosample: string;
                    target: string;
                };
            };
        };
    }[];
}

export const MOTIF_ENRICHMENT_MOTIF_QUERY = gql`
    query MotifEnrichmentBins($motif_id: String, $biosample: String) {
        motif_enrichment(motif_id: $motif_id, biosample: $biosample) {
            motif {
                pwm
            }
        }
    }
`;

export interface MOTIF_ENRICHMENT_MOTIF_QUERY_DATA {
    motif_enrichment: {
        motif: {
            pwm: number[][];
        };
    }[];
}
