import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { ApiContext } from 'apicontext';
import { Grid, Segment, Card, Table, Label } from 'semantic-ui-react';
import { DataTable, DataTableColumn } from 'ts-ztable';

import { includeTargetTypes, excludeTargetTypes } from '../../consts';
import TfDetails from '../tf/tfdetails';
import { TargetPartitionedDatasetCollection } from 'components/types';
import { TFLink, DatasetQueryResponse } from 'components/tf/types';
import { datasetColumns } from 'components/tf/function';
import { SummaryProps, CellTypeDescription } from './types';
import { CELLTYPE_DESCRIPTION_QUERY, DATASET_QUERY } from './queries';

function tfColumns(species: string): DataTableColumn<TargetPartitionedDatasetCollection>[] {
    return [
        {
            header: '',
            value: row => row.target.name,
            render: row => <TfDetails hideCellTypeCounts row={row} species={species} factor={row.target.name} />,
            sort: (a, b) => b.counts.biosamples - a.counts.biosamples,
        },
    ];
}

function ctDetailLinks(assembly: string, celltype: string): TFLink[] {
    return [
        {
            title: 'ENCODE',
            url: `https://www.encodeproject.org/search/?searchTerm=${celltype.replace(
                /\s+/g,
                '+'
            )}&type=Experiment&assay_title=TF+ChIP-seq&status=released&files.output_type=optimal+IDR+thresholded+peaks&files.output_type=pseudoreplicated+IDR+thresholded+peaks&assembly=${assembly}`,
        },
        {
            title: 'Wikipedia',
            url: `https://en.wikipedia.org/wiki/${celltype}`,
        },
    ];
}

const DETAILS_COLUMNS: DataTableColumn<TFLink>[] = [
    {
        header: 'Link Title',
        value: row => row.title,
        render: row => (
            <a target="_blank" rel="noopener noreferrer" href={row.url}>
                {row.title}
            </a>
        ),
    },
];

const Summary: React.FC<SummaryProps> = ({ assembly, species, celltype }) => {
    const client = useContext(ApiContext).client;

    const ctData = useQuery<{ celltype: CellTypeDescription[] }>(CELLTYPE_DESCRIPTION_QUERY, {
        client,
        variables: {
            assembly,
            name: [celltype],
        },
    });
    const celltypeDesc = ctData && ctData.data && ctData.data.celltype[0];
    const ctRows = ctDetailLinks(assembly, celltype);
    const { data, loading } = useQuery<DatasetQueryResponse>(DATASET_QUERY, {
        client,
        variables: {
            processed_assembly: assembly,
            biosample: celltype,
            replicated_peaks: true,
            include_investigatedas: includeTargetTypes,
            exclude_investigatedas: excludeTargetTypes,
        },
    });
    console.log(data);

    const wikipediacard = celltypeDesc && celltypeDesc.wiki_desc && (
        <Segment raised>
            <Label color="blue" ribbon>
                {'Wikipedia'}
            </Label>
            <blockquote>{celltypeDesc.wiki_desc}</blockquote>
        </Segment>
    );
    const experimentscard = !loading && data && (
        <Segment raised>
            <Label color="blue" ribbon>
                {data.peakDataset.counts.total} experiment
                {data.peakDataset.counts.total !== 1 ? 's' : ''} performed
            </Label>
            <DataTable
                columns={datasetColumns(species, false)}
                rows={data.peakDataset.datasets}
                searchable
                itemsPerPage={5}
                sortColumn={2}
                sortDescending
            />
        </Segment>
    );
    const factorscard = !loading && data && (
        <Segment raised>
            <Label color="blue" ribbon>
                {data.peakDataset.partitionByTarget.length} factors profiled
            </Label>
            <DataTable
                columns={tfColumns(species)}
                rows={data.peakDataset.partitionByTarget}
                searchable
                itemsPerPage={4}
                sortColumn={0}
            />
        </Segment>
    );
    const inner = (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column width={3}>
                    <Card>
                        <Card.Content>
                            <Card.Header>{celltype}</Card.Header>
                            <Card.Meta>
                                <Table celled>
                                    <Table.Body>
                                        {ctRows.map((row, i) => (
                                            <Table.Row key={i}>
                                                {DETAILS_COLUMNS.map((column, j) => (
                                                    <Table.Cell key={`${column.header}${i}_${j}`} textAlign="center">
                                                        {column.render ? column.render(row) : column.value(row)}
                                                    </Table.Cell>
                                                ))}
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </Card.Meta>
                            <Card.Description />
                        </Card.Content>
                    </Card>
                </Grid.Column>
                <Grid.Column width={10}>
                    {wikipediacard}
                    {experimentscard}
                    {factorscard}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
    return inner;
};

export default Summary;
