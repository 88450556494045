import React, {useState, useEffect, useContext} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react';
import { cellTypeRoute } from '../../routing';
import { ApiContext } from 'apicontext';
import { DataTable, DataTableColumn } from 'ts-ztable';
import { useQuery } from '@apollo/client';
import { useTFInfo } from './hooks';
import { CELLTYPE_DESCRIPTION_QUERY } from './queries';
interface CtRow {    
    name: string;
    targets: number;
    total: number;
    description?: string;
}
function ctTablecolumns(species: string): DataTableColumn<CtRow>[] { 
    return [    
    {
      header: "Celltype",
      render: (row: CtRow) => (       
          <>
                {' '}
                <Link to={cellTypeRoute(species, row.name)}>
                        {' '}
                        <h3>{row.name}</h3>
                </Link>
                <br />                
                {row.total + ' Experiments'}                    
                <>
                    <br />
                    {row.targets + ' Factors'}
                </>
          </>        
      ),
      value: (row: CtRow) =>
        `${row.name}, ${row.total} Experiments, ${
          row.targets
        } Factors`,
      sort: (a: CtRow, b: CtRow) => b.targets - a.targets,
    
    },
    {
      header: "Description",
      render: (row: CtRow) => (       
          <>
            {row.description}            
          </>
        
      ),
      value: (row: CtRow) => row.description || "",
    },
  ]};

/*function ctColumns(species: string): DataTableColumn<BiosamplePartitionedDatasetCollection>[] {
    return [
        {
            header: '',
            value: row => row.biosample.name,
            render: row => <CtDetails species={species} celltype={row.biosample.name} row={row} />,
            sort: (a, b) => b.counts.targets - a.counts.targets,
        },
    ];
}*/

const Ct: React.FC = () => {
    const { species } = useParams<{ species: string }>();
    const [rows, setRows] = useState<CtRow[]>([]);
    const { data, loading, error } = useTFInfo();
    const client = useContext(ApiContext).client;
    const assembly = species === 'human' ? 'GRCh38' : 'mm10';
    const {
        data: ctData,
        loading: ctLoading,
        error: ctError,
      } = useQuery(CELLTYPE_DESCRIPTION_QUERY, {
        client,
        variables: {
            assembly,
            name: data && data.peakDataset.partitionByBiosample.map(r=>r.biosample.name).filter(b=>b!=="Peyer's patch"),
        },
        skip: !data
    });
    console.log("ctData",ctData)
    useEffect(() => {
        if (data && ctData ) {
          const ctDescriptions: CtRow[] =
            data.peakDataset.partitionByBiosample.map((biosample) => {
              const ct = ctData.celltype.find(
                (ct) => ct.celltype === biosample.biosample.name
              );
              const description =
                ct?.wiki_desc?.split(".")[0] || "";
    
              return {
               
                name: biosample.biosample.name,
                total: biosample.counts.total,
                targets: biosample.counts.targets,
                description: description + ".",
              };
            });
    
          setRows(ctDescriptions);
        }
      }, [data, ctData, ]);
    
    
    return (
        <>
            <div style={{ height: '5em' }} />
            {loading && ctLoading && <Loader active />}
            {data && ctData && !ctError && !error && (
                <Container>
                    <h2>
                        Browsing {data.peakDataset.partitionByBiosample.length} cell types with data available in{' '}
                        {species}:
                    </h2>
                    <DataTable
                        columns={ctTablecolumns(species)}
                        rows={rows}
                        searchable
                        itemsPerPage={4}
                        sortColumn={0}
                        sortDescending
                    />
                </Container>
            )}
        </>
    );
};
export default Ct;
