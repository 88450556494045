import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { Container, Tab, Divider, Input, Header, Form, Button, Icon, Menu, Grid, Popup } from 'semantic-ui-react';

import { FileUploader } from './uploader';
import { MotifFileMenu, RegexSearchResults } from './results';
import ErrorMessage from './errormessage';
import { ParsedMotifFile } from './types';
import { motifSearchRoute } from 'routing';
import MotifUMAP from './umap';
import { useGetDownloadFileUrl } from 'hooks';

const MotifSearch: React.FC = () => {
    const history = useHistory();
    const { species, regex } = useParams<{ species: string; regex?: string }>();
    const assembly: string = species === 'human' ? 'GRCh38' : 'mm10';
    const [files, setFiles] = useState<ParsedMotifFile[]>([]);
    let [q, setQ] = useState('');

    const parsedFiles = files.filter((x: ParsedMotifFile): boolean => !!x.motifs && x.motifs!.length > 0 && !x.error);
    const erroredFiles = files.filter((x: ParsedMotifFile): boolean => !x.motifs || x.motifs.length === 0 || !!x.error);
    
    const match = useRouteMatch<{ page: string }>(`/motif/human/:page`);
    useEffect( () => {
        if (match?.params.page === undefined) history.push("/motif/human/meme-umap");
    }, [ match ]);    
    
    const factorbookCatalogMemeUrl = useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/complete-factorbook-catalog.meme.gz", "https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/complete-factorbook-catalog.meme.gz")
    const selexMotifs = useGetDownloadFileUrl("https://downloads.wenglab.org/factorbook-download/all-selex-motifs.meme.gz", "https://storage.googleapis.com/gcp.wenglab.org/factorbook-download/all-selex-motifs.meme.gz")
    const chipseqMemeTsvUrl = useGetDownloadFileUrl("https://storage.googleapis.com/gcp.wenglab.org/factorbook_chipseq_meme_motifs.tsv","https://storage.googleapis.com/gcp.wenglab.org/factorbook_chipseq_meme_motifs.tsv")
    return (
        <Container style={{ marginTop: '1em' }}>
            <div style={{ height: '5em' }} />
            <div style={{ fontVariant: "small-caps", marginLeft: "5%" }}><Header as="h2">Factorbook Motif Catalog</Header></div>
            <Menu secondary pointing>
                <Menu.Item onClick={() => history.push("/motif/human/meme-umap")} active={match?.params.page === "meme-umap"}>MEME Motif UMAP</Menu.Item>
                <Menu.Item onClick={() => history.push("/motif/human/selex-umap")} active={match?.params.page === "selex-umap"}>HT SELEX Motif UMAP</Menu.Item>
                <Menu.Item onClick={() => history.push("/motif/human/meme-search")} active={match?.params.page === "meme-search" || match?.params.page === "regex"}>Motif Search</Menu.Item>
                <Menu.Item onClick={() => history.push("/motif/human/download")} active={match?.params.page === "download"}>Downloads</Menu.Item>
            </Menu>
            { match?.params.page === "meme-umap" ? (
                <MotifUMAP key="meme" title="meme" url="/human-meme-umap.json.gz" />
            ) : match?.params.page === "selex-umap" ? (
                <MotifUMAP key="selex" title="selex" url="/ht-selex-umap.json.gz" />
            ) : match?.params.page === "download" ? (
                <Grid style={{ marginTop: "2em" }}>
                    <Grid.Column width={8}>
                        <Header as="h2" style={{ marginBottom: "0.1em" }}>MEME ChIP-seq Catalog
                            <Popup trigger={<Icon size="tiny" style={{ fontSize: "0.8em", marginLeft: "0.5em", marginTop: "-0.2em" }} name="info circle" />}>
                                These motifs were identified by applying MEME to the top 500 IDR thresholded ChIP-seq peaks from more than 3,000 ENCODE
                                ChIP-seq experiments. Five motifs were identified per experiment; these were subsequently filtered for quality using peak
                                centrality and enrichment metrics.
                            </Popup>
                        </Header>
                        <span style={{ fontSize: "1.2em" }}>6,069 Motifs ‧ 733 TFs</span><br />
                        <Divider />
                        <a href={factorbookCatalogMemeUrl}>
                            <Button size="large">
                                <Icon name="download" />Download motifs in MEME format<br /><span style={{ fontSize: "0.8em" }}>1 Mb</span>
                            </Button>
                        </a>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Header as="h2" style={{ marginBottom: "0.1em" }}>HT-SELEX Catalog
                            <Popup trigger={<Icon size="tiny" style={{ fontSize: "0.8em", marginLeft: "0.5em", marginTop: "-0.2em" }} name="info circle" />}>
                                These motifs were identified by applying the ZMotif neural network to reads from HT-SELEX experiments and negative reads generated
                                by dinucleotide shuffling of true positive reads. The motif identified by the network as most predictive of positive reads for each
                                experiment is contained in this set. HDF5 format motifs can be loaded into Python for application in new models.
                            </Popup>
                        </Header>
                        <span style={{ fontSize: "1.2em" }}>6,700 Motifs ‧ 631 TFs</span><br />
                        <Divider />
                        <a href={selexMotifs} download>
                            <Button size="large"><Icon name="download" />Download in MEME format<br /><span style={{ fontSize: "0.8em" }}>742 kb</span></Button>&nbsp;
                        </a>
                        { false && <Button size="large"><Icon name="download" />Download in HDF5 format<br /><span style={{ fontSize: "0.8em" }}>742 kb</span></Button> }
                    </Grid.Column>
                    <Grid.Column width={8}>                                   
                        <a href={chipseqMemeTsvUrl}>
                            <Button size="large">
                                <Icon name="download" />Download metadata in TSV format<br /><span style={{ fontSize: "0.8em" }}>2.9 Mb</span>
                            </Button>
                        </a>
                    </Grid.Column>
                </Grid>
            ) : (
                <>
                    <ErrorMessage files={erroredFiles} />
                    {parsedFiles.length !== 0 || regex ? (
                        <>
                            <Header as="h2">Motif Search Results: {regex || 'uploaded MEME files'}</Header>
                            <Button
                                onClick={() => {
                                    setFiles([]);
                                    history.push(motifSearchRoute(species));
                                }}
                            >
                                Perform a New Search
                            </Button>
                            <Divider />
                        </>
                    ) : null}
                    {parsedFiles.length !== 0 ? (
                        <Tab
                            menu={{ pointing: true }}
                            panes={parsedFiles.map((file, i) => ({
                                menuItem: file.file.name,
                                render: () => (
                                    <MotifFileMenu key={i} assembly={assembly} species={species} motifs={file.motifs!} />
                                ),
                            }))}
                        />
                    ) : regex ? (
                        <RegexSearchResults assembly={assembly} />
                    ) : (
                        <>
                            <Header as="h2">Factorbook Motif Search</Header>
                            <Form
                                onSubmit={() => history.push(motifSearchRoute(species, 'regex', q))}
                                style={{ marginBottom: '0.3em' }}
                            >
                                <span style={{ fontSize: '1.25em' }}>Enter a consensus sequence or regex:</span>
                                <Input
                                    icon="search"
                                    onChange={(e, d) => setQ(d.value || '')}
                                    placeholder="enter sequence or regex"
                                    style={{ width: '90%' }}
                                />
                                &nbsp;
                                <Button
                                    style={{ width: '8%' }}
                                    onClick={() => history.push(motifSearchRoute(species, 'regex', q))}
                                >
                                    Go
                                </Button>
                                <br />
                            </Form>
                            <strong>Examples: cca[cg]cag[ag]gggcgc</strong> or <strong>ccascagrgggcgc</strong>
                            <Divider horizontal>or</Divider>
                            <FileUploader onMotifsParsed={setFiles} />
                        </>
                    )}
                </>
            )}
        </Container>
    );
};
export default MotifSearch;
