import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import { cellTypeRoute } from '../../routing';
import { useCellTypeDescription } from './hooks';
import { CtDetailProps } from './types';

const CtDetails: React.FC<CtDetailProps> = props => {
    const assembly = props.species === 'human' ? 'GRCh38' : 'mm10';
    const ctData = useCellTypeDescription(assembly, props.celltype);
    const celltypeDesc = ctData && ctData.data && ctData.data.celltype[0];
    return (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column width={6}>
                    {' '}
                    <Link to={cellTypeRoute(props.species, props.row.biosample.name)}>
                        {' '}
                        <h3>{props.row.biosample.name}</h3>
                    </Link>
                    <br />
                    {props.row.counts.total + ' Experiments'}
                    {!props.hideFactorCounts && (
                        <>
                            <br />
                            {props.row.counts.targets + ' Factors'}
                        </>
                    )}
                </Grid.Column>
                {celltypeDesc && celltypeDesc.wiki_desc && (
                    <Grid.Column width={8}>{celltypeDesc.wiki_desc.split('.')[0]}.</Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    );
};
export default CtDetails;
