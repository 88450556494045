import React from 'react';
import { Message, Icon } from 'semantic-ui-react';

export type QCMessageProps = {
    message: string;
    icon: 'close' | 'warning sign';
    error?: boolean;
};

const QCMessage: React.FC<QCMessageProps> = ({ message, icon, error }) => (
    <Message
        warning={!error}
        error={error}
        style={{ display: 'inline-block', padding: '5px 20px 5px 20px', marginRight: '5px' }}
    >
        <Icon name={icon} />
        {message}
    </Message>
);
export default QCMessage;
