export const cellTypeRoute = (species?: string, celltype?: string, details?: string): string =>
    `/ct${species ? `/${species}` : ''}${species && celltype ? `/${celltype}` : ''}${
        species && celltype && details ? `/${details}` : ''
    }`;

export const tfRoute = (species?: string, factor?: string, details?: string, accession?: string): string =>
    `/tf${species ? `/${species}` : ''}${species && factor ? `/${factor}` : ''}` +
    `${species && factor && details ? `/${details}` : ''}${
        species && factor && details && accession ? `/${accession}` : ''
    }`;

export const experimentRoute = (accession?: string, details?: string): string =>
    `/experiment${accession ? `/${accession}` : ''}${accession && details ? `/${details}` : ''}`;

export const searchByRegionRoute = (species?: string): string => `/peaks${species ? `/${species}` : ''}`;

export const motifSearchRoute = (species: string, subpage?: string, regex?: string, page?: string): string =>
    `/motif/${species}${subpage ? `/${subpage}` : ''}${regex ? `/${regex}` : ''}${regex && page ? `/${page}` : ''}`;

export const motifEnrichmentRoute = (species: string, celltype?: string): string =>
    `/motifenrichment/${species}${celltype ? `/${celltype}` : ''}`;

export const snpRoute = (assembly: string): string => `/snpannotation/${assembly}`;

export const ldrRoute = (assembly: string): string => `/partitioned-ldr/${assembly}`;

export const motifBrowserRoute = (species: string, factor: string, eacc: string, accession: string, consensus_regex: string, detail?: string): string => `/${species}/${factor}/mememotif/${eacc}/${accession}/${consensus_regex}${detail ? `/${detail}` : ''}`
