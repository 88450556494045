import React, { useState } from 'react';
import { Button, Container, Divider, Header, Icon, List, Accordion, Segment } from 'semantic-ui-react';
import { DataTable, DataTableColumn } from 'ts-ztable';

type ModelDetails = {
    title: string;
    partitions: {
        title: string;
        footprint: number;
    }[];
    url: string;
};

function sum(x: number[]): number {
    let xx = 0;
    x.forEach( xxx => xx += xxx );
    return xx;
}

const MODEL_COLUMNS: DataTableColumn<ModelDetails & { factors: number, peaks: number }>[] = [{
    header: "Model Title",
    value: x => x.title,
    render: x => <a href="#">{x.title}</a>
}, {
    header: "Total Peak Regions (Overlaps Merged)",
    value: x => x.peaks.toLocaleString()
}, {
    header: "Distinct TFs represented",
    value: x => x.factors
}, {
    header: "Genomic Footprint of Peaks",
    value: x => `${sum(x.partitions.map(xx => xx.footprint)).toFixed(1)}%`
}, {
    header: "URL",
    value: x => x.url,
    render: x => <a href={x.url} download>{x.url}</a>
}];

const MODELS: (ModelDetails & { factors: number, peaks: number })[] = [{
    title: "GM12878",
    partitions: [{
        title: "peaks",
        footprint: 10.9
    }],
    url: "http://gcp.wenglab.org/ldr-models/GM12878.tar.gz",
    factors: 136,
    peaks: 456036
}, {
    title: "MCF-7",
    partitions: [{
        title: "peaks",
        footprint: 17.2
    }],
    url: "http://gcp.wenglab.org/ldr-models/MCF-7.tar.gz",
    factors: 90,
    peaks: 575454
}, {
    title: "A549",
    partitions: [{
        title: "peaks",
        footprint: 31.3
    }],
    url: "http://gcp.wenglab.org/ldr-models/A549.tar.gz",
    factors: 46,
    peaks: 736179
}, {
    title: "K562",
    partitions: [{
        title: "peaks",
        footprint: 20.3
    }],
    url: "http://gcp.wenglab.org/ldr-models/K562.tar.gz",
    factors: 319,
    peaks: 724468
}, {
    title: "H1-hESC",
    partitions: [{
        title: "peaks",
        footprint: 18.9
    }],
    url: "http://gcp.wenglab.org/ldr-models/H1.tar.gz",
    factors: 51,
    peaks: 810772
}, {
    title: "HepG2",
    partitions: [{
        title: "peaks",
        footprint: 23.3
    }],
    url: "http://gcp.wenglab.org/ldr-models/HepG2.tar.gz",
    factors: 201,
    peaks: 808860
}, {
    title: "HEK293",
    partitions: [{
        title: "peaks",
        footprint: 14.9
    }],
    url: "http://gcp.wenglab.org/ldr-models/HEK293.tar.gz",
    factors: 191,
    peaks: 613333
}].sort( (a, b) => a.title.localeCompare(b.title) );

const PeakModelPage: React.FC = () => {

    const [ model, setModel ] = useState<(ModelDetails & { factors: number, peaks: number }) | null>(null);
    const [ expanded, setExpanded ] = useState(false);

    return model === null ? (
        <Container style={{ width: "90%", marginTop: "7em" }}>
            <Header as="h1">
                Quantify heritability in TF ChIP-seq peaks
            </Header>
            <p>
                These models quantify heritability within TF ChIP-seq peaks in ENCODE cell lines as identified by the ENCODE ChIP-seq uniform processing pipeline.
                The included peaks are high-confidence, replicated peaks identiied using MACS2 and the irreproducible discovery rate (IDR) framework. The union of
                all peaks for all ChIPped factors is included in the model for each cell type. To download or apply a model, click the corresponding cell type name
                in the table below.
            </p>
            <DataTable
                columns={MODEL_COLUMNS}
                rows={MODELS}
                onRowClick={row => setModel(row)}
                sortColumn={0}
                sortDescending
            />
        </Container>
    ) : (
        <Container style={{ width: "90%", marginTop: "7em" }}>
            <Header as="h1">
                Quantify heritability in {model.title} TF ChIP-seq peaks
            </Header>
            <Divider />
            <div style={{ textAlign: "center" }}>
                <Button
                    as="a"
                    href={model.url}
                    size="huge"
                >
                    <Icon name="download" /> Download this model <br />
                    <span style={{ fontSize: '0.7em' }}>
                        click below for usage instructions
                    </span>
                </Button>
            </div>
            <Divider />
            <Accordion>
                <Accordion.Title onClick={() => setExpanded(!expanded)}>
                    <Header as="h2">How to use this model</Header>
                    Click to { expanded ? "collapse" : "expand" } instructions.
                </Accordion.Title>
                <Accordion.Content active={expanded}>
                    <List ordered>
                        <List.Item><a href="https://docs.docker.com/get-docker/" target="_blank" rel="noopenner noreferrer">Install Docker</a></List.Item>
                        <List.Item>Create a folder to store inputs and outputs. In this example we use a folder called "ldr" in the current user's home directory.</List.Item>
                        <List.Item><a href={model.url} download>Download</a> the model into the input directory. Download or copy summary statistics into the input directory.</List.Item>
                        <List.Item>Run the following command to unpack the model and partition heritability for a set of summary statistics:</List.Item>
                    </List>
                    <Segment style={{ fontSize: "0.8em" }}>
                        <pre>
                            tar xfvz {model.url.split('/')[model.url.split('/').length - 1]} &amp;&amp; \<br />
                            \<br />
                            docker run \<br />
                            &nbsp;&nbsp;--volume /path/to/inputs:/input \<br />
                            &nbsp;&nbsp;ghcr.io/weng-lab/ldr/ldr:latest \<br />
                            &nbsp;&nbsp;python3 -m ldr.h2 \<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;--ld-scores /input/{model.url.split('/')[model.url.split('/').length - 1].split('.')[0]} \<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;--ld-prefix annotations \<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;--summary-statistics /inputs/summary-stats.txt &gt; partitioned-heritability.txt
                        </pre>
                    </Segment><br />
                    <p>
                        Output will be located at <pre>/path/to/outputs/partitioned-heritability.txt</pre> when the command finishes. The final line in this
                        file will include heritability enrichment for the <strong>{model.title} peak</strong> partition.
                    </p>
                </Accordion.Content>
            </Accordion>
            <Divider />
            <Header as="h2">Model overview</Header>
            <strong>Total TFs represented</strong>: {model.factors}<br />
            <strong>Total non-overlapping peaks included</strong>: {model.peaks.toLocaleString()}<br />
            <Header as="h2">Genomic Partitions in this Model</Header>
            <List ordered>
                {model.partitions.map(x => (
                    <List.Item key={x.title}><strong>{x.title}</strong>: {x.footprint.toFixed(1)}% of human genome</List.Item>
                ))}
            </List>
            <Header as="h2">Limitations of this model</Header>
            <List ordered>
                <List.Item>
                    <strong>Cell type representation</strong>: This model <em>only</em> includes TF ChIP-seq peaks for {model.title}. The {model.factors} factors in this model may have other regulatory
                    binding sites active only in other cell types which are not represented here.
                </List.Item>
            </List>
        </Container>
    );

};
export default PeakModelPage;
