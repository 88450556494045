import React, { useState, useContext, useMemo } from 'react';
import { Container, Message, Button } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { MultiRegionSearch } from 'genomic-file-upload';

import { GenomicRange } from '../types';
import { ApiContext } from 'apicontext';
import PeakDataTable from './datatable';
import Download from './download';

export type SearchByRegionProps = {
    factor?: string;
    assembly?: string;
    accession?: string;
    marginTop?: string;
};

const SearchByRegion: React.FC<{
    factor?: string;
    assembly?: string;
    accession?: string;
    marginTop?: string;
}> = ({ factor, assembly, accession, marginTop }) => {
    const { species } = useParams<{ species: string }>();
    const asm = assembly ? assembly : species === 'human' ? 'GRCh38' : 'mm10';
    const clients = useContext(ApiContext);
    const streamPeaksService = clients.restEndpoints.streamPeaks;
    const [regions, setRegions] = useState<GenomicRange[]>([]);
    const [downloading, setDownloading] = useState(false);
    const formattedRegions = useMemo(
        () => regions.map(x => ({ chrom: x.chromosome!, chrom_start: x.start!, chrom_end: x.end! })),
        [regions]
    );

    return (
        <div style={{ marginLeft: '3%', marginTop: marginTop || '2em' }}>
            <Container>
                <MultiRegionSearch
                    searchInstructions={`Enter genomic coordinates (${asm}):`}
                    title={`Searching ${!factor ? 'all ' : ''}ENCODE ChIP-seq peaks ${factor ? `for ${factor}` : ''}`}
                    onSelectionChanged={setRegions}
                />
                {regions && regions.length > 0 && regions.length < 5000 ? (
                    <PeakDataTable assembly={asm} factor={factor} regions={formattedRegions} accession={accession} />
                ) : regions && regions.length > 5000 ? (
                    <Message warning>
                        The uploaded file(s) contain more than 5,000 regions, so we cannot show a preview of the results
                        on this page. You can download the full set of results in BED or JSON format.
                    </Message>
                ) : null}
                {regions && regions.length > 0 && (
                    <Button onClick={() => setDownloading(true)}>Download peaks in BED format</Button>
                )}
                {downloading && (
                    <Download
                        factor={factor}
                        assembly={asm}
                        accession={accession}
                        onComplete={() => setDownloading(false)}
                        name="factorbook-peaks"
                        regions={formattedRegions}
                        format="bed"
                        onError={() => {
                            setDownloading(false);
                        }}
                        streamPeaksService={streamPeaksService}
                    />
                )}
            </Container>
        </div>
    );
};
export default SearchByRegion;
