import React, { useContext, useState, useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { ApiContext } from 'apicontext';
import { Grid, Button, Search, Form, Popup } from 'semantic-ui-react';

import { SearchRowProps } from './types';
import { AutocompleteQueryResult } from '../types';

const SearchRow: React.SFC<SearchRowProps> = props => {
    const history = useHistory();
    const [q, setQuery] = useState('');
    const [errorPopupOpen, setErrorPopupOpen] = useState(false);
    const setQ = useCallback(
        (q: string) => {
            setErrorPopupOpen(false);
            setQuery(q);
        },
        [setErrorPopupOpen, setQuery]
    );

    const client = useContext(ApiContext).client;
    const result = useQuery<AutocompleteQueryResult>(props.query, {
        client,
        variables: {
            assembly: props.assembly,
            q,
            limit: 3,
        },
    });
    const matchedSearch = useMemo(() => {
        for (const res of (result && result.data && result.data.counts) || [])
            if (res.name!.toLowerCase() === q.toLowerCase()) return res.name!;
        return undefined;
    }, [result, q]);

    const handleOpen = useCallback(() => q.length > 0 && !matchedSearch && setErrorPopupOpen(true), [
        matchedSearch,
        q,
        setErrorPopupOpen,
    ]);
    const handleClose = useCallback(() => setErrorPopupOpen(false), [setErrorPopupOpen]);
    const formattedResults = useMemo(
        () => result && result.data && result.data.counts && result.data.counts.map(props.resultFormatter),
        [result, props.resultFormatter]
    );

    const redirectTo = useCallback((q: string) => history.push(`/${props.urlBase}/${props.species}/${q}`), [
        history,
        props.urlBase,
        props.species,
    ]);
    const tryRedirect = useCallback(() => {
        if (q.length === 0) redirectTo('');
        else if (matchedSearch) redirectTo(matchedSearch);
        else setErrorPopupOpen(true);
    }, [redirectTo, setErrorPopupOpen, matchedSearch, q.length]);

    return (
        <Grid>
            <Grid.Row>
                {props.initialPadding && <Grid.Column width={1} />}
                <Grid.Column width={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={props.image.src} alt={props.image.alt} style={{ width: '100%' }} />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Form onSubmit={tryRedirect} style={{ marginBottom: '0.3em' }}>
                        <Search
                            input={{ fluid: true }}
                            placeholder={props.placeholder}
                            onSearchChange={(e, d) => setQ(d.value || '')}
                            onResultSelect={(e, d) => redirectTo(d.result.title)}
                            results={formattedResults}
                            style={{ display: 'inline-block', width: '80%' }}
                        />
                        &nbsp;
                        <Popup
                            content={'Invalid query. Try searching for something else or click the link to view all.'}
                            open={errorPopupOpen}
                            onOpen={handleOpen}
                            onClose={handleClose}
                            trigger={<Button onClick={tryRedirect}>Go</Button>}
                            on="hover"
                            style={{
                                backgroundColor: '#fff6f6',
                                borderColor: '#e0b4b4',
                                color: '#9f3a38',
                                boxShadow: 'none',
                            }}
                        />
                    </Form>
                    <Button
                        as={Link}
                        to={`/${props.urlBase}/${props.species}`}
                        size="mini"
                        icon="right arrow"
                        labelPosition="right"
                        content={`Browse all TFs`}
                    />
                    <Button
                        as={Link}
                        to={`/ct/${props.species}`}
                        size="mini"
                        icon="right arrow"
                        labelPosition="right"
                        content={`Browse all cell types`}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
export default SearchRow;
