import React from 'react';
import { Progress, Modal } from 'semantic-ui-react';

import { ProgressModalProps } from './types';

export const ProgressModal: React.FC<ProgressModalProps> = ({ index, total }) => (
    <Modal open>
        <Modal.Header>Processing Files</Modal.Header>
        <Modal.Content>
            <p>
                Working on file {index + 1} of {total}...
            </p>
            <Progress percent={((index + 1) / total) * 100} progress size="medium" indicating />
        </Modal.Content>
    </Modal>
);
export default ProgressModal;
