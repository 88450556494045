import React, { useCallback, useMemo, useRef, useState, useContext } from 'react';
import {
    Grid,
    Button,
    Icon,
    Table,
    Modal,
    Input,
    Header,
    Popup,
    Menu,
    Message,
    Divider,
    Loader,
} from 'semantic-ui-react';
import { Logo, DNAAlphabet } from 'logojs-react';
import { groupBy } from 'queryz';

import { downloadData, downloadSVG } from 'utilities/svgdata';
import CopyToClipboardButton from 'components/copytoclipboardbutton';
import QCMessage from './qcmessage';

import CentralityPlot from './peakscentrality';
import { MemeMotif } from './types';
import { useMotifData } from './hooks';
import { ATACPlot, ConservationPlot } from './plots';
import { TOMTOMMessage } from 'components/shared';
import { TOMTOMMatch } from 'components/shared/types';
import { useHistory, useParams } from 'react-router-dom';
import DownloadMemeOccurrences from './downloadmemeoccurrences';
import { ApiContext } from 'apicontext';
import { meme, MMotif } from 'components/motifsearch/umap';
import MNasePlot from './plots/mnase';

DNAAlphabet[0].color = "#228b22";
DNAAlphabet[3].color = "red";

const TransparentAlphabet = DNAAlphabet.map( (letter: any) => ({
    ...letter,
    fillOpacity: 0.3,
}));

const MethylAlphabet = [
    { regex: 'M', color: '#008864' },
    { regex: 'W', color: '#008888' },
];

const MethylTransparentAlphabet = MethylAlphabet.map(letter => ({
    ...letter,
    fillOpacity: 0.3,
}));

export const reverseComplement = (ppm: number[][]): number[][] =>
    ppm && ppm[0] && ppm[0].length === 4
        ? ppm.map(inner => inner.slice().reverse()).reverse()
        : ppm.map(entry => [entry[3], entry[2], entry[1], entry[0], entry[5], entry[4]]).reverse();

function logLikelihood(backgroundFrequencies: number[]): (r: number[]) => number[] {
    return (r: number[]): number[] => {
        let sum = 0.0;
        r.map((x, i) => (sum += x === 0 ? 0 : x * Math.log2(x / (backgroundFrequencies[i] || 0.01))));
        return r.map(x => x * sum);
    };
}

const Motif: React.FC<{ eacc?: string; motif: MemeMotif; methyl?: boolean; peaks_accession: string; tomtom?: TOMTOMMatch[], ct?: string }> = ({
    motif,
    methyl,
    peaks_accession,
    tomtom,
    eacc,
    ct
}) => {
    const history = useHistory();
    const { species, factor } = useParams<{ species?: string; factor?: string }>();

    const redirect = useCallback(
        (accession: string, eacc: string, regex: string) => history.push(`/${species}/${factor}/mememotif/${eacc}/${accession}/${regex}`),
        [history, species, factor]
    );
    const [isReverseComplement, setReverseComplement] = useState(false);
    const [isConsensusSeqModalOpen, setConsensusSeqModalOpen] = useState(false);
    const [peakCentralityPlot, showPeakCentralityPlot] = useState(false);
    const [epigeneticSignalPlot, showEpigeneticSignalPlot] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const togglePeakCentralityPlot = useCallback(() => showPeakCentralityPlot(!peakCentralityPlot), [
        peakCentralityPlot,
    ]);
    const toggleEpigeneticSignal = useCallback( () => showEpigeneticSignalPlot(!epigeneticSignalPlot), [ epigeneticSignalPlot ]);

    const clients = useContext(ApiContext);
    const streamMemeService = (clients.restEndpoints as any).streamMemeService;

    const svg = useRef<any>(null);
    const toggleReverseComplement = useCallback(() => setReverseComplement(!isReverseComplement), [
        isReverseComplement,
    ]);
    const ppm = isReverseComplement ? reverseComplement(motif.pwm) : motif.pwm;
    const poorCentrality = motif.flank_z_score < 0 || motif.flank_p_value > 0.05;
    const poorPeakEnrichment = motif.shuffled_z_score < 0 || motif.shuffled_p_value > 0.05;
    const logoWidth = (ppm.length * 95) / 30;
    const transparentAlphabet = methyl ? [...TransparentAlphabet, ...MethylTransparentAlphabet] : TransparentAlphabet;
    const opaqueAlphabet = methyl ? [...DNAAlphabet, ...MethylAlphabet] : DNAAlphabet;

    const backgroundFrequencies = motif.background_frequencies || ppm[0].map(_ => 1.0 / ppm[0].length);
    const ll = useMemo(() => logLikelihood(backgroundFrequencies), [backgroundFrequencies]);
    const pwm = useMemo(() => ppm.map(ll), [ppm, ll]);
    const yMax = Math.max(...pwm.map(xx => xx.reduce((x, v) => x + v, 0.0)));
    const tomtomMatch = useMemo(() => tomtom?.slice().sort((a, b) => a.e_value - b.e_value)[0], [tomtom]);

    return (
        <Grid.Row style={{ overflowX: 'hidden' }}>
            <Grid>
                <Grid.Row style={{ paddingBottom: '0px', marginTop: '0.5em' }}>
                    <Grid.Column width={1} />
                    <Grid.Column width={7}>
                        {(poorCentrality || poorPeakEnrichment) && (
                            <div style={{ paddingBottom: '10px' }}>
                                {poorCentrality && <QCMessage message="poor peak centrality" icon="warning sign" />}
                                {poorPeakEnrichment && (
                                    <QCMessage error message="poor peak enrichment" icon="warning sign" />
                                )}
                            </div>
                        )}
                        <div>
                            <Logo
                                yAxisMax={yMax}
                                backgroundFrequencies={backgroundFrequencies}
                                alphabet={poorCentrality || poorPeakEnrichment ? transparentAlphabet : opaqueAlphabet}
                                ppm={ppm}
                                ref={svg}
                                width={`${logoWidth}%`}
                            />
                            <br />
                            <TOMTOMMessage tomtomMatch={tomtomMatch} />
                        </div>
                        <div />
                    </Grid.Column>
                    <Grid.Column width={4} style={{ marginLeft: '1em' }}>
                        <Table>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell
                                        style={{
                                            width: '5px',
                                            padding: '0.5em',
                                            paddingRight: '0px',
                                            paddingLeft: '0.5em',
                                        }}
                                    >
                                        <Popup trigger={<Icon name="help circle" />}>
                                            <p>
                                                The statistical significance of the motif. The E-value is an estimate of
                                                the expected number that one would find in a similarly sized set of
                                                random sequences (sequences where each position is independent and
                                                letters are chosen according to the background letter frequencies).
                                            </p>
                                        </Popup>
                                    </Table.Cell>
                                    <Table.Cell style={{ padding: '0.5em' }}>E-value</Table.Cell>
                                    <Table.Cell style={{ padding: '0.5em' }}>{motif.e_value || '< 1e-300'}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell
                                        style={{
                                            width: '5px',
                                            padding: '0.5em',
                                            paddingRight: '0px',
                                            paddingLeft: '0.5em',
                                        }}
                                    >
                                        <Popup trigger={<Icon name="help circle" />}>
                                            <p>
                                                The number of optimal IDR thresholded peaks which contained at least one
                                                occurrence of this motif according to FIMO.
                                            </p>
                                        </Popup>
                                    </Table.Cell>
                                    <Table.Cell style={{ padding: '0.5em' }}>Occurrences</Table.Cell>
                                    <Table.Cell style={{ padding: '0.5em' }}>
                                        {motif.original_peaks_occurrences.toLocaleString()} /{' '}
                                        {motif.original_peaks.toLocaleString()} peaks
                                    </Table.Cell>
                                </Table.Row>
                                { !poorCentrality && !poorPeakEnrichment && (
                                    <Table.Row>
                                        <Table.Cell
                                            style={{
                                                width: '5px',
                                                padding: '0.5em',
                                                paddingRight: '0px',
                                                paddingLeft: '0.5em',
                                            }}
                                        >
                                            <Popup trigger={<Icon name="help circle" />}>
                                                <p>
                                                    Downloads all sites of this motif within this set of ChIP-seq peaks as identified by FIMO.
                                                </p>
                                            </Popup>
                                        </Table.Cell>
                                        <Table.Cell style={{ padding: '0.5em' }}>Download Peak Sites<br />({species === "human" ? "hg38" : "mm10"} genome)</Table.Cell>
                                        <Table.Cell style={{ padding: '0.5em' }}>
                                            <a href={`https://screen-beta-api.wenglab.org/factorbook_downloads/hq-occurrences/${peaks_accession}_${motif.name}.gz`} download>
                                                <Icon name="download" />
                                            </a>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Grid.Column>
                    <Modal open={isConsensusSeqModalOpen} onClose={() => setConsensusSeqModalOpen(false)} size="small">
                        <Header>Consensus sequence</Header>
                        <Modal.Content>
                            <Input value={motif.consensus_regex} spellCheck={false} style={{ width: '80%' }} />
                            &nbsp;
                            <CopyToClipboardButton text={motif.consensus_regex} />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color="green" onClick={() => setConsensusSeqModalOpen(false)}>
                                Done
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    {downloading && (
                        <DownloadMemeOccurrences
                            peaks_accession={peaks_accession}
                            consensus_regex={motif.consensus_regex}
                            onComplete={() => setDownloading(false)}
                            onError={() => {
                                setDownloading(false);
                            }}
                            streamMemeOccurrencesService={streamMemeService}
                        />
                    )}
                </Grid.Row>
                <Grid.Row style={{ paddingTop: '0px' }}>
                    <Grid.Column width={1} />
                    <Grid.Column width={15}>
                        <Menu secondary>
                            <Menu.Item link onClick={() => downloadData(meme([ { accession: peaks_accession, pwm: motif.pwm, factor: motif.name, sites: motif.sites, e: motif.e_value } as any as MMotif ]), `${motif.name}.meme`)}>
                                <Icon name="download" /> export motif (MEME)
                            </Menu.Item>
                            <Menu.Item link onClick={() => downloadSVG(svg, 'logo.svg')}>
                                <Icon name="image" /> export logo (SVG)
                            </Menu.Item>
                            <Menu.Item link onClick={toggleReverseComplement}>
                                <Icon name="exchange" /> reverse complement
                            </Menu.Item>
                            { (ct === "K562" || ct === "GM12878") && (
                                <Menu.Item link onClick={toggleEpigeneticSignal}>
                                    <Icon name="dna" /> epigenetic signal profile
                                </Menu.Item>
                            )}
                            <Menu.Item link onClick={() => setConsensusSeqModalOpen(true)} style={{ display: "none" }}>
                                <Icon name="search" /> show consensus sequence
                            </Menu.Item>
                            <Menu.Item link onClick={() => redirect(peaks_accession, eacc || "_", motif.consensus_regex)}>
                                <Icon name="world" /> show genomic sites
                            </Menu.Item>
                            <Menu.Item link onClick={togglePeakCentralityPlot}>
                                <Icon name={peakCentralityPlot ? 'minus square outline' : 'plus square outline'} />
                                {peakCentralityPlot ? 'hide QC' : 'show QC'}
                            </Menu.Item>
                        </Menu>
                        {peakCentralityPlot && (
                            <Grid>
                                <Grid.Column width={5}>
                                    <CentralityPlot peak_centrality={motif.peak_centrality} />
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <ATACPlot name={motif.name} accession={peaks_accession} pwm={pwm} />
                                </Grid.Column>
                                <Grid.Column width={5}>
                                    <ConservationPlot name={motif.name} accession={peaks_accession} pwm={pwm} />
                                </Grid.Column>
                            </Grid>
                        )}
                        {epigeneticSignalPlot && (
                            <Grid>
                                <Grid.Row><Header as="h3">Epigenetic Signal around Motif Sites</Header></Grid.Row>
                                <Grid.Column width={5}>
                                    { (ct === "K562" || ct === "GM12878") && <MNasePlot name={motif.name} celltype={ct!} pwm={pwm} /> }
                                </Grid.Column>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Grid.Row>
    );
};

const Motifs: React.FC<{ eacc?: string; peaks_accession: string; methyl?: boolean, ct?: string }> = ({ peaks_accession, methyl, eacc, ct }) => {
    const { data, loading } = useMotifData(peaks_accession);
    const motifs = useMemo(
        () =>
            [...(data?.meme_motifs || [])].sort(
                (a, b) => b.flank_z_score + b.shuffled_z_score - a.flank_z_score - a.shuffled_z_score
            ),
        [data]
    );
    const tomtom = useMemo(
        () =>
            groupBy(
                data?.target_motifs || [],
                x => x.motifid,
                x => x
            ),
        [data]
    );

    if (loading) return <Loader active>Loading...</Loader>;

    return motifs === undefined || motifs.length === 0 ? (
        <div style={{ width: '100%' }}>
            <br />
            <br />
            <Message negative style={{ width: '100%' }}>
                <Message.Header style={{ display: 'flex', justifyContent: 'center' }}>
                    No data available yet.
                </Message.Header>
            </Message>
        </div>
    ) : (
        <Grid divided="vertically" style={{ overflowY: 'auto' }}>
            {motifs.map((m, i) => (
                <>
                    <Motif
                        key={i + peaks_accession}
                        motif={m}
                        peaks_accession={peaks_accession}
                        methyl={methyl}
                        tomtom={tomtom.get(m.id)}
                        eacc={eacc}
                        ct={ct}
                    />
                    <Divider />
                </>
            ))}
        </Grid>
    );
};
export default Motifs;
