/**
 * hooks.ts: hooks for data fetching and processing for the genome explorer.
 */

import React from 'react';
import { GenomicRange } from "components/types";
import { ImportanceTrackDataPoint } from 'bpnet-ui/dist/components/ImportanceTrack/ImportanceTrack';

const BIG_QUERY = `
query($requests: [BigRequest!]!) {
    bigRequests(requests: $requests) {
        data
    }
}
`;

const MOTIF_QUERY = `
query MemeMotifSearch($pwms: [[[Float!]]]!) {
    meme_motif_search(pwms: $pwms, assembly: "GRCh38", limit: 1, offset: 1) {
      results {
        motif {
          pwm
          tomtom_matches {
            target_id
            jaspar_name
            e_value
          }
        }
        reverseComplement
      }
    }
  }  
`;

const SEQUENCE_QUERY = `
query($requests: [BigRequest!]!) {
    bigRequests(requests: $requests) {
        data
    }
}
`;

type MotifResponse = {
    data: {
        meme_motif_search: {
            results: [{
                motif: MotifMatch;
                reverseComplement: boolean;
            }];
        }[];
    };
};

type SequenceResponse = {
    data: {
      bigRequests: {
        data: string;
      }[];
    };
  };

type MotifMatch = {
    pwm: number[][];
    tomtom_matches: {
        target_id: string;
        jaspar_name: string;
        e_value: number;
    }[];
};

export type TitledImportanceTrackHighlight = {
    coordinates: [number, number];
    sequence: string;
    motif: MotifMatch;
    reverseComplement?: boolean;
};

function seqToPWM(sequence: string[]): number[][] {
    const M = {
        A: [1, 0, 0, 0],
        C: [0, 1, 0, 0],
        G: [0, 0, 1, 0],
        T: [0, 0, 0, 1]
    };
    return sequence.map((x) => M[x]);
}

export function useBigData(urls: string[], domain: GenomicRange, preRenderedWidth?: number): any[] | null {
    const [ data, setData ] = React.useState<any[] | null>(null);
    React.useEffect( () => {
        fetch("https://ga.staging.wenglab.org/graphql", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({
                query: BIG_QUERY,
                variables: {
                    requests: urls.map(url => ({
                        url: url,
                        chr1: domain.chromosome,
                        chr2: domain.chromosome,
                        start: domain.start,
                        end: domain.end,
                        preRenderedWidth 
                    }))
                }
            })
        })
            .then((response) => response.json())
            .then((result) => result && result.data && result.data.bigRequests && setData(result.data.bigRequests));
    }, [ urls, domain ]);
    return data;
}

export function useImportanceSelectionEnd(
    highlights: TitledImportanceTrackHighlight[], setHighlights: (_: TitledImportanceTrackHighlight[]) => void
) {
    return React.useCallback(
        (coordinates: [number, number], values: ImportanceTrackDataPoint[]) => {
            const sequence = values.map((x) => x.base);
            fetch("https://ga.staging.wenglab.org/graphql", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    query: MOTIF_QUERY,
                    variables: { pwms: [seqToPWM(sequence)] },
                }),
            })
                .then((response) => response.json())
                .then((data) =>
                    setHighlights([
                        ...highlights,
                        {
                            sequence: sequence.join(""),
                            coordinates,
                            motif: (data as MotifResponse).data.meme_motif_search[0].results[0].motif,
                            reverseComplement: (data as MotifResponse).data.meme_motif_search[0].results[0].reverseComplement,
                        },
                    ])
                )
                .catch((error) => console.error(error));
        },
        [highlights]
    );
}

export function useImportantRegionClick(domain: GenomicRange, onSelectionEnd: ((coordinates: [number, number], values: ImportanceTrackDataPoint[]) => void)) {
    return React.useCallback((coordinates: [number, number]) => {
        console.log(coordinates, "!")
        fetch("https://ga.staging.wenglab.org/graphql", {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({
            query: SEQUENCE_QUERY,
            variables: {
                requests: {
                url: "gs://gcp.wenglab.org/hg38.2bit",
                chr1: domain.chromosome,
                chr2: domain.chromosome,
                start: coordinates[0],
                end: coordinates[1] + 1,
                },
            },
            }),
        })
            .then((response) => response.json())
            .then((data) =>
            onSelectionEnd(
                coordinates.map((x) => x - domain.start!) as [number, number],
                (data as SequenceResponse).data.bigRequests[0].data[0]
                .split("")
                .map((x) => ({ base: x, importance: 0 }))
            ));
    }, [onSelectionEnd, domain]);
};
