import * as React from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { Redirect } from 'react-router';
import { Switch, Route, Router, Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import { Navbar } from './components/navbar';
import { HomePage } from './components/homepage';
import Tf from './components/tf/tf';
import Ct from './components/celltype/ct';
import { MotifSearch } from './components/motifsearch';
import FactorPage from './components/tf/factorpage';
import ExperimentPage from './components/tf/experimentpage';
import { ApiContext } from 'apicontext';
import { FactorbookConfig } from './config';
import CellTypePage from 'components/celltype/celltypepage';
import snp from './images/snp.png';
import { PartitionedLDRegressionPage } from 'components/pldr';
import MemeMotifDetails from './components/tf/motifenrichment/mememotifsdetails';
import {
    tfRoute,
    cellTypeRoute,
    experimentRoute,
    searchByRegionRoute,
    motifSearchRoute,
    motifEnrichmentRoute,
    snpRoute,
    ldrRoute,
    motifBrowserRoute
} from './routing';
import SearchByRegion from 'components/searchbyregions/searchbyregions';

import MotifEnrichmentLanding from 'components/motifenrichment/landing';
import { SNPAnnotationPageRoot } from 'components/snpannotation';
import { Menu } from 'semantic-ui-react';
import MotifSiteCatalogPage from 'components/motif_instances/MotifSiteCatalog';
import Downloads from 'components/Downloads';
import GenomeExplorer from './biology-2023/GenomeExplorer/GenomeExplorer';
import FbBiologyGenomeExplorer from './biology-2023/GenomeExplorer/FbBiologyGenomeExplorer';

const h = createBrowserHistory();
h.listen((location, _) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

const App: React.FC<{ config: FactorbookConfig }> = ({ config }) => {
    const context = {
        client: new ApolloClient({
            cache: new InMemoryCache(),
            link: new BatchHttpLink({ uri: config.graphql.service }),
        }),
        restEndpoints: {
            streamPeaks: config.restapis.streampeaksService,
            streamMemeService: (config.restapis as any).streammemeService
        },
    };
    return (
        <ApiContext.Provider value={context}>
            <Router history={h}>
                <Switch>
                    <Route exact path="/">
                        <HomePage style={{ marginTop: '2em', width: '100%' }} />
                    </Route>
                    <Route path={tfRoute(':species', ':factor', ':factordetails?', ':dataset?')}>
                        <Navbar />
                        <FactorPage />
                    </Route>
                    <Route exact path={`/mediawiki/index.php/:factor`}>
                        {({ match }) => <Redirect to={`/tf/human/${match?.params.factor || ''}`} />}
                    </Route>
                    <Route path={tfRoute(':species')}>
                        <Navbar />
                        <Tf />
                    </Route>
                    <Route path={cellTypeRoute(':species', ':celltype', ':details?')}>
                        <Navbar />
                        <CellTypePage />
                    </Route>
                    <Route path={cellTypeRoute(':species')}>
                        <Navbar />
                        <Ct />
                    </Route>
                    <Route path={searchByRegionRoute(':species')}>
                        <Navbar />
                        <SearchByRegion marginTop="5em" />
                    </Route>
                    <Route path={experimentRoute(':accession', ':details?')}>
                        <Navbar />
                        <ExperimentPage />
                    </Route>
                    <Route path="/mediawiki">
                        <Redirect to="/" />
                    </Route>
                    <Route path="/motif-sites/">
                        <Navbar />
                        <MotifSiteCatalogPage />
                    </Route>
                    <Route path={motifSearchRoute(':species', 'regex', ':regex?', ':page(\\d+)?')}>
                        <Navbar />
                        <MotifSearch />
                    </Route>
                    <Route exact path={motifSearchRoute(':species/:subpage?')}>
                        <Navbar />
                        <MotifSearch />
                    </Route>
                    <Route path={motifEnrichmentRoute(`:species`, `:celltype?`)}>
                        <Navbar />
                        <MotifEnrichmentLanding />
                    </Route>
                    <Route path={motifBrowserRoute(`:species`,`:factor`, ':eacc', `:accession`,`:consensus_regex` ,`:detail?`)}>
                        <Navbar />
                        <MemeMotifDetails/>
                    </Route>
                    <Route exact path="/downloads">
                        <Navbar />
                        <Downloads />
                    </Route>
                    <Route exact path="/biology-2023">
                        <Navbar />
                        <GenomeExplorer
                            domain={{ chromosome: "chr1", start: 1000000, end: 1001000 }}
                            client={context.client}
                            width={1600}
                        />
                    </Route>
                    <Route exact path="/greg-biology-2023">
                        <Navbar />
                        <FbBiologyGenomeExplorer
                            domain={{ chromosome: "chr1", start: 1000000, end: 1001000 }}
                            client={context.client}
                            width={1600}
                        />
                    </Route>
                    <Route path={snpRoute(':assembly')}>
                        {({ match }) => (
                            <>
                                <Navbar>
                                    <Menu.Item as={Link} to={snpRoute(match?.params.assembly!)}>
                                        <img alt="" src={snp} />
                                        <span style={{ marginLeft: '1em' }}>SNP Annotation</span>
                                    </Menu.Item>
                                </Navbar>
                                <SNPAnnotationPageRoot />
                            </>
                        )}
                    </Route>
                    <Route path={ldrRoute(':assembly')}>
                        {({ match }) => (
                            <>
                                <Navbar>
                                    <Menu.Item as={Link} to={ldrRoute(match?.params.assembly!)}>
                                        <img alt="" src={snp} />
                                        <span style={{ marginLeft: '1em' }}>Partitioned LD Score Regression</span>
                                    </Menu.Item>
                                </Navbar>
                                <PartitionedLDRegressionPage />
                            </>
                        )}
                    </Route>
                </Switch>
            </Router>
        </ApiContext.Provider>
    );
};
export default App;
