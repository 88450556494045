import React, { useMemo } from 'react';
import { ApolloClient, gql, NormalizedCacheObject, useQuery } from '@apollo/client';
import { Cytobands } from 'umms-gb';
import { Grid } from 'semantic-ui-react';
import { MemeMotif } from '../types';
import { DNALogo } from 'logojs-react';

const CYTOBAND_QUERY = gql`
  query cytobands($assembly: String!, $chromosome: String) {
    cytoband(assembly: $assembly, chromosome: $chromosome) {
      stain
      coordinates {
        chromosome
        start
        end
      }
    }
  }
`;

export type CytobandQueryResponse = {
    cytoband: {
        stain: string;
        coordinates: {
            chromosome: string;
            start: number;
            end: number;
        };
    }[];
};

export type CytobandsProps = {
    assembly: string;
    chromosome: string;
    innerWidth: number;
    height: number;
    position?: { chromosome: string, start: number, end: number };
    client: ApolloClient<NormalizedCacheObject>;
    motif?: MemeMotif;
};

const CytobandView: React.FC<CytobandsProps> = props => {
    const { loading, data } = useQuery<CytobandQueryResponse>(CYTOBAND_QUERY, {
        variables: {
            assembly: props.assembly === "GRCh38" ? "hg38" : props.assembly,
            chromosome: props.chromosome
        },
        client: props.client
    });
    const domain = useMemo( () => data && ({
        start: 0,
        end: Math.max(...(data.cytoband.length === 0 ? [1] : data.cytoband.map(x => x.coordinates.end)))
    }), [ data ]);
    return loading || !data ? null : (
        <Grid>
            <Grid.Column width={2}>
                { props.motif && props.motif.pwm && <div style={{ marginTop: "-25px" }}><DNALogo ppm={props.motif.pwm} height="50px" /></div> }
            </Grid.Column>
            <Grid.Column width={12}>
                <svg width="100%" viewBox={`0 0 ${props.innerWidth} ${props.height}`}>
                    <Cytobands
                        highlight={props.position && { ...props.position, color: "#0000ff" }}
                        data={data.cytoband}
                        domain={domain!}
                        width={props.innerWidth}
                        height={props.height}
                        id="cytobands"
                    />
                </svg>
            </Grid.Column>
            <Grid.Column width={2}>
                <strong>{props.assembly}:{props.chromosome}</strong>
            </Grid.Column>
        </Grid>
    );
};
export default CytobandView;
