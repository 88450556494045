import * as React from 'react';

import humanIcon from '../../../images/human.png';
import { HeaderProps } from './speciesheader';

const HumanHeader: React.SFC<HeaderProps> = props => (
    <svg style={{ width: '100%' }} viewBox="0 0 1200 200">
        <image width={100} height={200} xlinkHref={humanIcon} opacity={0.7} />
        <line x1={108} x2={1200} y1={102} y2={102} strokeWidth={2.5} stroke="#888888" />
        <text style={{ fontSize: '60px' }} x={130} y={80}>
            Search Human TFs
        </text>
        {props.counts && (
            <text style={{ fontSize: '24px' }} x={130} y={140} fill="#666666">
                {props.counts!.total} experiments &#8231;&nbsp;
                {props.counts!.targets} transcription factors &#8231;&nbsp;
                {props.counts!.biosamples} cell types
            </text>
        )}
    </svg>
);
export default HumanHeader;
